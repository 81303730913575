import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CourseExport, CourseInterface, CourseListFilterFormInterface} from '../../interfaces/course.interface';
import {CourseTypesEnum} from '../../enums/course-types.enum';
import {FormTypesEnum} from '../../enums/form-types.enum';
import {Observable} from 'rxjs';
import {DictionaryAreaInterface, DictionaryCourseTemplateInterface} from '../../interfaces/dictionary.interface';
import {CoursesService} from '../../pages/courses/courses.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {DictionaryAreasService} from '../../pages/dictionary/dictionary-areas/dictionary-areas.service';
import {TrainerInterface} from '../../interfaces/trainer.interface';
import {TrainersService} from '../../pages/trainers/trainers.service';
import {HttpParams} from '@angular/common/http';
import {ListHandler} from '../../classes/list-handler.class';
import {DictionaryCourseTemplatesService} from '../../pages/dictionary/dictionary-course-templates/dictionary-course-templates.service';
import {UtilsService} from '../../services/utils.service';
import {AppLinksService} from '../../services/app-links.service';
import {ActivatedRoute} from '@angular/router';
import {CsvService} from '../../services/csv.service';
import {ExcelService} from '../../services/excel.service';
import {TranslateService} from '../../services/translate.service';
import {ToastService} from '../../services/toast.service';
import {CourseStatusesEnum} from '../../enums/course-statuses.enum';
import {ListType} from '../../enums/list-type.enum';

@Component({
    selector: 'app-courses-list-global',
    templateUrl: './courses-list.component.html',
    styleUrls: ['./courses-list.component.scss']
})
export class CoursesListGlobalComponent extends ListHandler<CoursesService, CourseInterface> implements OnInit {
    @Input() public type!: ListType;
    @Input() public initFilter: CourseListFilterFormInterface = <CourseListFilterFormInterface>{};
    @Input() public trainer!: TrainerInterface;
    @Input() public clickNavigation: boolean = true;
    @Input() public customerId?: string;
    @Output() public recordClicked: EventEmitter<CourseInterface>;

    public courseTypes = CourseTypesEnum;
    public formTypes = FormTypesEnum;
    public areas$: Observable<DictionaryAreaInterface[]>;
    private trainers: TrainerInterface[] = [];
    public courseTemplates: DictionaryCourseTemplateInterface[] = [];
    public courseStatuses = CourseStatusesEnum;

    constructor(private coursesService: CoursesService,
                private formBuilder: FormBuilder,
                public activatedRoute: ActivatedRoute,
                private dictionaryAreasService: DictionaryAreasService,
                private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
                private trainersService: TrainersService,
                private csvService: CsvService,
                private excelService: ExcelService,
                private utilsService: UtilsService,
                private translateService: TranslateService,
                private toastService: ToastService) {
        super(coursesService, AppLinksService.coursesDetail);
        this.areas$ = this.dictionaryAreasService.downloadList();
        this.courseTemplates = this.dictionaryCourseTemplatesService.data;
        this.recordClicked = new EventEmitter<CourseInterface>();
    }

    ngOnInit(): void {
        this.init();
    }

    private init(): void {
        this.createFilterForm();
        this.download();
        this.downloadTrainers();
    }

    public download(): void {
        this.downloadFilteredList(this.getParams()).then(() => {
            this.list.forEach((item: CourseInterface) => CoursesService.prepareCourse(item));
        });
        this.filterBoxFlag = false;
    }

    private downloadTrainers(): void {
        this.trainersService.downloadList().subscribe((trainers: TrainerInterface[]) => {
            this.trainers = trainers;
        });
    }

    public createFilterForm(): void {
        this.filterForm = this.formBuilder.group({
            page: new FormControl(0),
            type: new FormControl(this.initFilter.type),
            templateId: new FormControl(this.initFilter.templateId),
            courseAreaId: new FormControl(this.initFilter.courseAreaId),
            form: new FormControl(this.initFilter.form),
            localizations: new FormControl([]),
            dateFrom: new FormControl(this.initFilter.dateFrom),
            dateTo: new FormControl(this.initFilter.dateTo),
            localization: new FormControl(this.initFilter.localization),
            status: new FormControl(this.initFilter.status)
        });
    }

    public resetFilter(): void {
        this.filterForm.get('page')?.setValue(0);
        this.filterForm.get('templateId')?.reset();
        this.filterForm.get('courseAreaId')?.reset();
        this.filterForm.get('form')?.reset();
        this.filterForm.get('localizations')?.reset();
        this.filterForm.get('dateFrom')?.reset();
        this.filterForm.get('dateTo')?.reset();
        this.filterForm.get('localization')?.reset();
        this.filterForm.get('status')?.reset();
        this.download();
    }

    public sendCourses(): void {
        this.downloadFilteredListNoAction(this.getParams(true)).then((list: CourseInterface[]) => {
            list.forEach((item: CourseInterface) => CoursesService.prepareCourse(item));
            this.excelService.save<CourseExport>(this.coursesService.prepareListForExcel(list, this.trainers), ['Typ', 'Nazwa', 'Daty', 'Lokalizacje', 'Il. godz.', 'Forma', 'Placówka', 'Miejscowość placówki', 'Status'], ['excelType', 'name', 'excelDates', 'excelLocalizations', 'excelHourCount', 'excelForm', 'excelClient', 'excelClientCity', 'excelStatus'], 'Harmonogram dla trenera', 'Harmonogram dla trenera');
        });
    }

    public ifTypeOpen(courseType: number): boolean {
        return UtilsService.ifCourseOpen(courseType)
    }

    public ifTypeClosed(courseType: number): boolean {
        return UtilsService.ifCourseClosed(courseType)
    }

    public ifStatusRejected(status: CourseStatusesEnum): boolean {
        return CourseStatusesEnum.REJECTED === status;
    }

    public getTrainerInfo(trainerId: string): string {
        let trainer: TrainerInterface | undefined = this.trainers.find((trainerItem: TrainerInterface) => trainerItem.id === trainerId);
        return !!trainer ? `${trainer.firstName} ${trainer.lastName}` : '';
    }

    public ifGlobalListTrainer(): boolean {
        return UtilsService.ifGlobalListTrainer(this.type);
    }

    private getParams(all?: boolean): HttpParams {
        const filterForm: CourseListFilterFormInterface = this.filterForm.value;
        let params: HttpParams = new HttpParams().set('page', filterForm.page);
        if (!!filterForm.type || filterForm.type === 0) params = params.set('type', filterForm.type);
        if (!!filterForm.templateId) params = params.set('templateId', filterForm.templateId);
        if (!!filterForm.courseAreaId) params = params.set('courseAreaId', filterForm.courseAreaId);
        if (!!filterForm.form || filterForm.form === 0) params = params.set('form', filterForm.form);
        if (!!filterForm.localizations && filterForm.localizations.length > 0) params = params.set('localizations', filterForm.localizations.join(','));
        if (!!filterForm.dateFrom) params = params.set('dateFrom', String(filterForm.dateFrom));
        if (!!filterForm.dateTo) params = params.set('dateTo', String(filterForm.dateTo));
        if (!!filterForm.localization) params = params.set('localization', filterForm.localization);
        if (!!filterForm.status) params = params.set('status', filterForm.status);
        if (all) params = params.set('all', all);
        if (UtilsService.ifGlobalListCustomer(this.type)) params = params.set('customers[]', this.activatedRoute.snapshot.params['id']);
        if (UtilsService.ifGlobalListTrainer(this.type)) params = params.set('trainer', this.trainer.id);

        return params;
    }
}
