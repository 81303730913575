import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ToastType} from "../types/global.type";
import {ToastBasicInterface, ToastConfigInterface} from "../interfaces/toast.interface";
import {TranslateService} from "./translate.service";

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private toastr: ToastrService,
                private translateService: TranslateService) {
    }

    public translateText(type: ToastType, message: string, title?: string, toastConfig?: ToastConfigInterface) {
        if (!!message) message = this.translateService.getTranslated(message);
        if (!!title) title = this.translateService.getTranslated(title);
        this.toastr[type](message, title, toastConfig);
    }

    public fillAllFormData(): void {
        this.translateText('error', 'fillAllFormData');
    }

    public success: ToastBasicInterface = {
        save: () => this.toastr.success('Poprawnie zapisano dane.'),
        update: () => this.toastr.success('Poprawnie zaktualizowano dane.'),
        delete: () => this.toastr.success('Poprawnie usunięto dane.')
    };
}
