<div class="row basicList">
    <div class="col-12 basicList__filter listFilter mt-2">
        <form [formGroup]="filterForm" (submit)="download()" *ngIf="!!filterForm">
            <button type="button" class="basicButton basicButton__greenLight basicButton__icon basicButton__icon--filter" (click)="handleFilterBox()"></button>
            <button type="button" class="basicButton basicButton__greenLight ml-3" (click)="sendCourses()" *ngIf="ifGlobalListTrainer()">{{'button.downloadFilteredCourses' | translate}}</button>

            <ng-container *ngIf="filterBoxFlag">
                <div class="filterBox">
                    <div class="row">
                        <div class="col-12 filterBox__actions">
                            <img src="/assets/images/close.svg" alt="Close" (click)="handleFilterBox()">
                        </div>

                        <div class="col-12 filterBox__filters">
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'type' | translate}} <i class="required">*</i></label>
                                    <select formControlName="type">
                                        <option [ngValue]="null">{{'selectChoose.type' | translate}}</option>
                                        <ng-container *ngFor="let courseType of courseTypes | enumToArray">
                                            <option [ngValue]="courseTypes[courseType]">{{('enum.courseTypes.' + courseType) | translate}}</option>
                                        </ng-container>
                                    </select>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'course' | translate}} <i class="required">*</i></label>
                                    <select formControlName="templateId">
                                        <option [ngValue]="null">{{'selectChoose.course' | translate}}</option>
                                        <ng-container *ngFor="let course of courseTemplates">
                                            <option [ngValue]="course.id">{{course.name}}</option>
                                        </ng-container>
                                    </select>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'dictionary.area' | translate}} <i class="required">*</i></label>
                                    <select formControlName="courseAreaId">
                                        <option [ngValue]="null">{{'selectChoose.area' | translate}}</option>
                                        <ng-container *ngFor="let area of areas$ | async; let areaIdx = index;">
                                            <option [ngValue]="area.id">{{area.name}}</option>
                                        </ng-container>
                                    </select>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'courseForm' | translate}} <i class="required">*</i></label>
                                    <select formControlName="form">
                                        <option [ngValue]="null">{{'selectChoose.form' | translate}}</option>
                                        <ng-container *ngFor="let formType of formTypes | enumToArray;">
                                            <option [ngValue]="formTypes[formType]">{{('enum.formTypes.' + formType) | translate}}</option>
                                        </ng-container>
                                    </select>
                                </div>

                                <div class="col-12 col-md-4 col-xl-4 formGroup">
                                    <label>Data</label>
                                    <div class="formGroup__range">
                                        <span>od</span>
                                        <input type="date" formControlName="dateFrom"/>
                                        <span>do</span>
                                        <input type="date" formControlName="dateTo"/>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4 col-xl-4 formGroup">
                                    <label>{{'dictionary.location' | translate}}</label>
                                    <input formControlName="localization"/>
                                </div>

                                <div class="col-12 col-md-6 col-xl-4 formGroup">
                                    <label>{{'status' | translate}}</label>
                                    <select formControlName="status">
                                        <option [ngValue]="null">{{'selectChoose.status' | translate}}</option>
                                        <ng-container *ngFor="let courseStatus of courseStatuses | enumToArray:false;">
                                            <option [ngValue]="courseStatuses[courseStatus]">{{('enum.courseStatuses.' + courseStatus) | translate}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 buttonActions">
                            <button type="button" class="basicButton basicButton__greenLight" (click)="resetFilter()">{{'button.reset' | translate}}</button>
                            <button type="submit" class="basicButton basicButton__greenDark">{{'button.filter' | translate}}</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>

    <div class="col-12">
        <app-list-with-loader [downloadedList]="downloadedList" [listCounter]="list.length">
            <div class="row">
                <div class="col-12">
                    <table class="spacingTable">
                        <thead>
                        <tr>
                            <th>{{'name' | translate}}</th>
                            <th>{{'dates' | translate}}</th>
                            <th>{{'institution' | translate}}</th>
                            <th>{{'institutionCity' | translate}}</th>
                            <th>{{'courseForm' | translate}}</th>
                            <th>{{'trainers' | translate}}</th>
                            <th>{{'participantAmount' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let course of list; let customerIdx = index;">
                            <tr (click)="recordClicked.emit(course)" [ngClass]="{'blueLight': ifTypeClosed(course.type), 'newOne': ifTypeOpen(course.type), 'rejected': ifStatusRejected(course.courseStatus)}"
                                [routerLink]="clickNavigation ? [linkToDetail, course.id] : null" [queryParams]="{'customerId': customerId}" class="cursorPointer">
                                <td>{{course.name}}</td>
                                <td>
                                    <ng-container *ngFor="let date of course.dates;">
                                        {{date.date}} {{date.startHour}} - {{date.endHour}}
                                        <br>
                                    </ng-container>
                                </td>
                                <td>{{course.properties?.sourceRegistrationEntity?.addressData?.name}}</td>
                                <td>{{course.properties?.sourceRegistrationEntity?.addressData?.city}}</td>
                                <td>{{('enum.formTypes.' + (course.form | enumNameById:'formTypes')) | translate}}</td>
                                <td>
                                    <ng-container *ngFor="let date of course.dates;">
                                        <ng-container *ngFor="let trainerId of date.trainers; let ifLastTrainer = last;">
                                            {{getTrainerInfo(trainerId)}}{{!ifLastTrainer ? ', ' : ''}}
                                        </ng-container>
                                        <br>
                                    </ng-container>
                                </td>
                                <td>{{course.actualParticipantAmount}}</td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>

                <div class="col-12">
                    <app-paginator [currentPage]="filterForm.get('page')?.value" [rows]="20" [totalRecords]="count" (changePageEvent)="changePage($event); download();"></app-paginator>
                </div>
            </div>
        </app-list-with-loader>
    </div>
</div>
