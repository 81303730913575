<ng-container *ngIf="exportAll$ | async"></ng-container>

<div class="row basicList">
  <div class="col-12 basicList__header headerWithButton">
    <label class="basicList__headerText" *ngIf="ifGlobalListGlobal()">{{'courseRegistrationOpenList' | translate}}</label>
    <div class="basicForm__headerActions">
      <button class="basicList__headerAction basicButton basicButton__greenDark" (click)="exportList()">{{'button.export' | translate}}</button>
    </div>
  </div>

  <div class="col-12 basicList__filter listFilter">
    <form [formGroup]="filterForm" (submit)="download()" *ngIf="!!filterForm">
      <button type="button" class="basicButton basicButton__greenLight basicButton__icon basicButton__icon--filter" (click)="handleFilterBox()"></button>

      <div class="">
        <app-filter-chosen *ngIf="!!getFilterName('customerCompanyName')" label="institutionName" [value]="getFilterName('customerCompanyName')" (remove)="toResetOneValue('customerCompanyName')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('companyCity')" label="address.city" [value]="getFilterName('companyCity')" (remove)="toResetOneValue('companyCity')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('dateFrom')" label="dateFrom" [value]="getFilterName('dateFrom')" (remove)="toResetOneValue('dateFrom')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('dateTo')" label="dateTo" [value]="getFilterName('dateTo')" (remove)="toResetOneValue('dateTo')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('customerName')" label="firstName" [value]="getFilterName('customerName')" (remove)="toResetOneValue('customerName')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('customerSurname')" label="lastName" [value]="getFilterName('customerSurname')" (remove)="toResetOneValue('customerSurname')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterEnumName(customerTypes, 'customerType')" label="customerType" [value]="('enum.customerTypes.' + getFilterEnumName(customerTypes, 'customerType')) | translate" (remove)="toResetOneValue('customerType')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('courseName')" label="courseName" [value]="getFilterName('courseName')" (remove)="toResetOneValue('courseName')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('phone')" label="phone" [value]="getFilterName('phone')" (remove)="toResetOneValue('phone')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterBoolName('seen')" label="seen" [value]="getFilterBoolName('seen')" (remove)="toResetOneValue('seen')"></app-filter-chosen>
      </div>

      <ng-container *ngIf="filterBoxFlag">
        <div class="filterBox">
          <div class="row">
            <div class="col-12 filterBox__actions">
              <img src="/assets/images/close.svg" alt="Close" (click)="handleFilterBox()">
            </div>

            <div class="col-12 filterBox__filters">
              <div class="row">
                <div class="col-12 col-md-6 col-xl-3 formGroup">
                  <label>{{'institutionName' | translate}}</label>
                  <input formControlName="customerCompanyName"/>
                </div>

                <div class="col-12 col-md-6 col-xl-3 formGroup">
                  <label>{{'address.city' | translate}}</label>
                  <input formControlName="companyCity"/>
                </div>

                <div class="col-12 col-md-12 col-xl-6 formGroup">
                  <label>Data</label>
                  <div class="formGroup__range">
                    <span>od</span>
                    <input type="date" formControlName="dateFrom"/>
                    <span>do</span>
                    <input type="date" formControlName="dateTo"/>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-xl-3 formGroup">
                  <label>{{'firstName' | translate}}</label>
                  <input formControlName="customerName"/>
                </div>

                <div class="col-12 col-md-6 col-xl-3 formGroup">
                  <label>{{'lastName' | translate}}</label>
                  <input formControlName="customerSurname"/>
                </div>

                <div class="col-12 col-md-6 col-xl-3 formGroup">
                  <label>{{'customerType' | translate}}</label>
                  <select formControlName="customerType">
                    <option [ngValue]="null">{{'selectChoose.type' | translate}}</option>
                    <ng-container *ngFor="let customerType of customerTypes | enumToArray">
                      <option [ngValue]="customerTypes[customerType]">{{('enum.customerTypes.' + customerType) | translate}}</option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-12 col-md-6 col-xl-3 formGroup">
                  <label>{{'courseName' | translate}}</label>
                  <input formControlName="courseName"/>
                </div>

                <div class="col-12 col-md-6 col-xl-3 formGroup">
                  <label>{{'phone' | translate}}</label>
                  <input formControlName="phone"/>
                </div>

                <div class="col-12 col-md-6 col-xl-3 formGroup">
                  <label>{{'seen' | translate}}</label>
                  <select formControlName="seen">
                    <option [ngValue]="null">{{'selectChoose.global' | translate}}</option>
                    <option [ngValue]="true">{{'yes' | translate}}</option>
                    <option [ngValue]="false">{{'no' | translate}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-12 buttonActions">
              <button type="button" class="basicButton basicButton__greenLight" (click)="resetFilter()">{{'button.reset' | translate}}</button>
              <button type="submit" class="basicButton basicButton__greenDark">{{'button.filter' | translate}}</button>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="col-12">
    <app-list-with-loader [downloadedList]="downloadedList" [listCounter]="list.length">
      <div class="row">
        <div class="col-12">
          <table class="spacingTable">
            <thead>
            <tr>
              <th></th>
              <th class="cursorPointer" (click)="sortBy.columnClicked('customerLastName'); download();">{{'lastName' | translate}} {{sortBy.getArrow('customerLastName')}}</th>
              <th class="cursorPointer" (click)="sortBy.columnClicked('customerFirstName'); download();">{{'firstName' | translate}} {{sortBy.getArrow('customerFirstName')}}</th>
              <th>{{'institutionName' | translate}}</th>
              <th>{{'institutionCity' | translate}}</th>
              <th>{{'courseDates' | translate}}</th>
              <th>{{'courseName' | translate}}</th>
              <th class="cursorPointer" (click)="sortBy.columnClicked('dateSort'); download();">{{'createdAt' | translate}} {{sortBy.getArrow('dateSort')}}</th>
              <th>{{'seen' | translate}}</th>
              <th *ngIf="type === 'customer'"></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let courseRegistration of list">
              <tr class="cursorPointer" [ngClass]="{'newOne': courseRegistration.customer?.customerType === customerTypeIndividual, 'blueLight': courseRegistration.customer?.customerType === customerTypeEstablishment}"
                  [routerLink]="[linkToDetail, courseRegistration.courseId]" [queryParams]="{'customerId': courseRegistration.customerId || customerId}">
                <td class="openNewTab">
                  <button class="basicButton basicButton__transparent basicButton__icon basicButton__icon--openNewTab" (click)="openNewTab(courseRegistration.courseId, courseRegistration.customerId); $event.stopPropagation();"></button>
                </td>
                <td [routerLink]="[customerDetail, courseRegistration.customerId]" (click)="$event.stopPropagation();">{{courseRegistration.participantData?.lastName}}</td>
                <td [routerLink]="[customerDetail, courseRegistration.customerId]" (click)="$event.stopPropagation();">{{courseRegistration.participantData?.firstName}}</td>
                <td>{{courseRegistration.workPlace?.name}}</td>
                <td>{{courseRegistration.workPlace?.city}}</td>
                <td [innerHTML]="courseRegistration.courseDatesLabel"></td>
                <td>{{courseRegistration.courseName}}</td>
                <td>{{courseRegistration.createdAt | dateFormat:'DD.MM.YYYY HH:mm:ss'}}</td>
                <td>
                  {{courseRegistration.seen ? (courseRegistration.seen | yesNoLabel) : ''}}
                  <button *ngIf="!courseRegistration.seen" class="basicButton basicButton__greenDark basicButton--h32 mr-3" (click)="setAsSeen(courseRegistration.id); $event.stopPropagation();">{{'button.process' | translate}}</button>
                </td>
                <td *ngIf="type === 'customer'">
                  <button class="basicButton basicButton__greenDark basicButton--h32 mr-3" (click)="edit(courseRegistration); $event.stopPropagation();">{{'button.edit' | translate}}</button>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

        <div class="col-12">
          <app-paginator [currentPage]="filterForm.get('page')?.value" [rows]="20" [totalRecords]="count" (changePageEvent)="changePage($event); download();"></app-paginator>
        </div>
      </div>
    </app-list-with-loader>
  </div>
</div>

<app-courses-detail-edit-source-registration-open></app-courses-detail-edit-source-registration-open>
