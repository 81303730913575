import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '../services/translate.service';
import * as _ from 'underscore';

@Pipe({
  name: 'yesNoLabel'
})
export class YesNoLabelPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: boolean | string, defaultValue: string = ''): unknown {
    const yesLabel = this.translateService.getTranslated('yes');
    const noLabel = this.translateService.getTranslated('no');

    if (_.isBoolean(value)) return value ? yesLabel : noLabel;
    if (_.isString(value)) return value === 'true' ? yesLabel : (value === 'false' ? noLabel : defaultValue);

    return defaultValue;
  }
}
