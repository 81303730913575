<ng-container (window:resize)="onResize($event)">
    <div class="viewWrapper">
        <div class="viewWrapper__leftMenu" [ngClass]="{'viewWrapper__leftMenu--notShow': !menusStates.show}">
            <app-menu-left></app-menu-left>
        </div>

        <div class="viewWrapper__view" [ngClass]="{'viewWrapper__view--fullWidth': !menusStates.show}">
<!--        <div class="viewWrapper__view" [ngClass]="{'viewWrapper__view&#45;&#45;fullWidth': !menusStates.show, 'viewWrapper__view&#45;&#45;orangeBG': menusStates.show}">-->
            <div class="viewWrapper__topMenu" *ngIf="menusStates.show">
                <app-menu-top></app-menu-top>
            </div>

            <div class="container-fluid viewWrapper__routerOutlet" [ngClass]="{'viewWrapper__routerOutlet--maximize': !menusStates.show, 'viewWrapper__routerOutlet--whiteBG': ifAuthLinks}">
<!--            <div class="container-fluid viewWrapper__routerOutlet" [ngClass]="{'viewWrapper__routerOutlet&#45;&#45;maximize': !menusStates.show, 'viewWrapper__routerOutlet&#45;&#45;whiteBG': ifAuthLinks || !menusStates.show}">-->
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ng-container>

<app-modal-loader></app-modal-loader>
