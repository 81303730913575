import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpAuthInterceptor} from "./http-auth.interceptor";
import {AuthService} from "./services/auth.service";
import {LoggedUserService} from "./services/logged-user.service";
import {ToastService} from "./services/toast.service";
import {ToastrModule} from "ngx-toastr";
import {WindowService} from "./services/window.service";
import {Router, RouterModule} from "@angular/router";
import {UtilsService} from "./services/utils.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppInitService} from "./services/app-init.service";
import {SharedModule} from "./shared.module";
import {CommonModule} from "@angular/common";
import {LinkAvailableService} from "./services/link-available.service";
import {MenuPositionService} from "./services/menu-position.service";
import {RouteNotFoundComponent} from './pages/route-not-found/route-not-found.component';
import {LogoutService} from "./services/logout.service";
// Import all Froala Editor plugins.
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/pl.js';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';

export function initApp(appInitService: AppInitService) {
    return (): Promise<void> => {
        return appInitService.init();
    };
}

@NgModule({
    declarations: [
        AppComponent,
        RouteNotFoundComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        RouterModule,

        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),

        SharedModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right'
        })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpAuthInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AppInitService],
            multi: true
        },
        AuthService,
        LoggedUserService,
        ToastService,
        WindowService,
        UtilsService,
        LinkAvailableService,
        MenuPositionService,
        LogoutService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
