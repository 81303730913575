import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: '[appOnlyIntegers]'
})
export class OnlyIntegersDirective {
    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];

    constructor() {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) > -1 || ((event.metaKey || event.ctrlKey) && (event.key.toLowerCase() === 'a' || event.key.toLowerCase() === 'c' || event.key.toLowerCase() === 'v' || event.key.toLowerCase() === 'x'))) return;
        if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) return;

        event.preventDefault();
    }
}
