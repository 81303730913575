<ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="'normal'">
        <ng-content></ng-content>
    </ng-container>

    <ng-container *ngSwitchCase="'loading'">
        <div class="viewState viewState__loading">
            <app-loader></app-loader>
            <label>Trwa przetwarzanie Państwa żądania</label>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'success'">
        <div class="viewState viewState__success">
            <label *ngIf="!!header" class="viewState__header viewState__header--success">{{ header }}</label>
            <label class="viewState__text" *ngIf="!!text">{{ text }}</label>
        </div>
    </ng-container>
</ng-container>
