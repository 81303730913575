import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {NotificationInterface} from "../../interfaces/notification.interface";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient) {
    }

    public downloadList(): Observable<NotificationInterface[]> {
        return this.http.get<NotificationInterface[]>(environment.apiPath + 'notification_list');
    }
}
