import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    transform(date: string | Date | undefined | null, format: string = 'DD.MM.YYYY'): string {
        if (!!date) {
            const momentDate: Moment = moment(new Date(date));
            if (moment(date).isDST()) momentDate.subtract('hours', 1);
            return momentDate.format(format);
        }
        return '';
    }
}
