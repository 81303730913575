import {Injectable} from '@angular/core';
import {MenuLeftNotShowLink} from "../interfaces/menu.interface";
import {AppLinksService} from "./app-links.service";

@Injectable({
    providedIn: 'root'
})
export class LinkAvailableService {
    private static readonly notAvailableLinks: MenuLeftNotShowLink[] = [
        {link: AppLinksService.authLogin},
        {link: AppLinksService.authPasswordReset},
        {link: AppLinksService.authPasswordChange},
        {link: AppLinksService.applicationFormNotAuthOpen},
        {link: AppLinksService.applicationFormNotAuthClose},
        {link: AppLinksService.applicationFormNotAuthSubscription},
        {link: AppLinksService.unsubscribeMailer},
    ];

    constructor() {
    }

    public checkLinkIfAvailable(currentLink: string): boolean {
        return !LinkAvailableService.notAvailableLinks.find((link: MenuLeftNotShowLink) => !!currentLink.match(link.link));
    }
}
