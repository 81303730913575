<div class="menuTop">
    <div class="container-fluid menuTop__wrapper">
        <div class="menuTop__box">
            <div class="menuTop__mobileMenu hamburgerMenu d-lg-none" [ngClass]="{'open': showMobileMenuFlag}" (click)="showMobileMenu()">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

<!--            <ng-container *ngIf="showViewAction">-->
<!--                <div class="menuTop__viewAction" (click)="back()">-->
<!--                    <img alt="Back arrow" src="/assets/images/back-arrow.svg"/>-->
<!--                    <label class="d-none d-md-inline-block">{{textViewAction | translate}}</label>-->
<!--                </div>-->
<!--            </ng-container>-->
        </div>

        <div class="menuTop__box menuTop__loggedUser" id="loggedUserActions">
            <label>{{loggedUser?.firstName}} {{loggedUser?.lastName}}</label>
            <img alt="Dropdown" src="/assets/images/down-arrow.svg"/>

            <app-action-dropbox idParent="loggedUserActions">
                <div class="actionDropbox__elements--element actionDropbox__elements--whiteGreenDark" (click)="logout()">{{'button.logout' | translate}}</div>
            </app-action-dropbox>
        </div>
    </div>
</div>
