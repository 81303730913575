import {AbstractControl, FormGroup} from '@angular/forms';
import {AnyInterface} from "../interfaces/global.interface";

export function NipValidate(nipProp: string) {
    return (group: FormGroup): AnyInterface<boolean> | undefined => {
        let nipValid: boolean = NipValidateLogic(group.controls[nipProp].value);

        if (!nipValid) {
            return {
                nipNotValidate: true
            };
        }
    };
}

export function NipControlValidate(control: AbstractControl) {
    if (!!control.value && !NipValidateLogic(control.value)) return {notValidate: true};
    return null;
}

export function NipValidateLogic(nip: string): boolean {
    let nipValid: boolean = false;
    nip = !!nip ? String(nip).replace(/[\s-]/g, '') : '';

    if (nip.length === 10 && parseInt(nip, 10) > 0) {
        let sum: number = 0;
        let weights: number[] = [6, 5, 7, 2, 3, 4, 5, 6, 7];

        for (let i = 0; i < nip.length - 1; i++) sum += (+nip[i] * weights[i]);

        nipValid = (sum % 11) === +nip[9];
    }

    return nipValid;
}
