<ng-container *ngIf="exportAll$ | async"></ng-container>

<div class="row basicList">
    <div class="col-12 basicList__header headerWithButton">
        <ng-container *ngIf="ifGlobalListGlobal()">
            <label class="basicList__headerText">{{'courseRegistrationClosedList' | translate}}</label>
            <div class="basicForm__headerActions">
                <button class="basicList__headerAction basicButton basicButton__greenDark" (click)="exportList()">{{'button.export' | translate}}</button>
            </div>
        </ng-container>
    </div>

    <div class="col-12 basicList__filter listFilter">
        <form [formGroup]="filterForm" (submit)="download()" *ngIf="!!filterForm">
            <button type="button" class="basicButton basicButton__greenLight basicButton__icon basicButton__icon--filter" (click)="handleFilterBox()"></button>

            <div class="">
                <app-filter-chosen *ngIf="!!getFilterName('customerCompanyName')" label="institutionName" [value]="getFilterName('customerCompanyName')" (remove)="toResetOneValue('customerCompanyName')"></app-filter-chosen>
                <app-filter-chosen *ngIf="!!getFilterName('companyCity')" label="address.city" [value]="getFilterName('companyCity')" (remove)="toResetOneValue('companyCity')"></app-filter-chosen>
                <app-filter-chosen *ngIf="!!getFilterName('dateFrom')" label="dateFrom" [value]="getFilterName('dateFrom')" (remove)="toResetOneValue('dateFrom')"></app-filter-chosen>
                <app-filter-chosen *ngIf="!!getFilterName('dateTo')" label="dateTo" [value]="getFilterName('dateTo')" (remove)="toResetOneValue('dateTo')"></app-filter-chosen>
                <app-filter-chosen *ngIf="!!getFilterName('customerName')" label="firstName" [value]="getFilterName('customerName')" (remove)="toResetOneValue('customerName')"></app-filter-chosen>
                <app-filter-chosen *ngIf="!!getFilterName('customerSurname')" label="lastName" [value]="getFilterName('customerSurname')" (remove)="toResetOneValue('customerSurname')"></app-filter-chosen>
                <app-filter-chosen *ngIf="!!getFilterName('courseName')" label="courseName" [value]="getFilterName('courseName')" (remove)="toResetOneValue('courseName')"></app-filter-chosen>
                <app-filter-chosen *ngIf="!!getFilterBoolName('accepted')" label="accepted" [value]="getFilterBoolName('accepted')" (remove)="toResetOneValue('accepted')"></app-filter-chosen>
                <app-filter-chosen *ngIf="!!getFilterBoolName('rejected')" label="rejected" [value]="getFilterBoolName('rejected')" (remove)="toResetOneValue('rejected')"></app-filter-chosen>
            </div>

            <ng-container *ngIf="filterBoxFlag">
                <div class="filterBox">
                    <div class="row">
                        <div class="col-12 filterBox__actions">
                            <img src="/assets/images/close.svg" alt="Close" (click)="handleFilterBox()">
                        </div>

                        <div class="col-12 filterBox__filters">
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'institutionName' | translate}}</label>
                                    <input formControlName="customerCompanyName"/>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'address.city' | translate}}</label>
                                    <input formControlName="companyCity"/>
                                </div>

                                <div class="col-12 col-md-12 col-xl-6 formGroup">
                                    <label>Data</label>
                                    <div class="formGroup__range">
                                        <span>od</span>
                                        <input type="date" formControlName="dateFrom"/>
                                        <span>do</span>
                                        <input type="date" formControlName="dateTo"/>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'firstName' | translate}}</label>
                                    <input formControlName="customerName"/>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'lastName' | translate}}</label>
                                    <input formControlName="customerSurname"/>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'courseName' | translate}}</label>
                                    <input formControlName="courseName"/>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup"></div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'accepted' | translate}}</label>
                                    <select formControlName="accepted">
                                        <option [ngValue]="null">{{'selectChoose.global' | translate}}</option>
                                        <option [ngValue]="true">{{'yes' | translate}}</option>
                                        <option [ngValue]="false">{{'no' | translate}}</option>
                                    </select>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'rejected' | translate}}</label>
                                    <select formControlName="rejected">
                                        <option [ngValue]="null">{{'selectChoose.global' | translate}}</option>
                                        <option [ngValue]="true">{{'yes' | translate}}</option>
                                        <option [ngValue]="false">{{'no' | translate}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 buttonActions">
                            <button type="button" class="basicButton basicButton__greenLight" (click)="resetFilter()">{{'button.reset' | translate}}</button>
                            <button type="submit" class="basicButton basicButton__greenDark">{{'button.filter' | translate}}</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>

    <div class="col-12">
        <app-list-with-loader [downloadedList]="downloadedList" [listCounter]="list.length">
            <div class="row">
                <div class="col-12">
                    <table class="spacingTable">
                        <thead>
                        <tr>
                            <th></th>
                            <th>{{'institutionName' | translate}}</th>
                            <th>{{'institutionCity' | translate}}</th>
                            <th>{{'courseName' | translate}}</th>
                            <th class="cursorPointer" (click)="sortBy.changeOrder(); download();">{{'createdAt' | translate}} {{sortBy.column === 'createdAt' ? (sortBy.order === 'ASC' ? '↓' : '↑') : ''}}</th>
                            <th>{{'accepted' | translate}}</th>
                            <th>{{'rejected' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let courseRegistration of list">
                            <tr class="cursorPointer" [ngClass]="{'blueLight': !courseRegistration.accepted}" [routerLink]="[linkToDetail, courseRegistration.id]">
                                <td class="openNewTab">
                                    <button class="basicButton basicButton__transparent basicButton__icon basicButton__icon--openNewTab" (click)="openNewTab(courseRegistration.id); $event.stopPropagation();"></button>
                                </td>
                                <td [routerLink]="[customerDetail, courseRegistration.customerId]" (click)="$event.stopPropagation();">{{courseRegistration.addressData?.name}}</td>
                                <td>{{courseRegistration.addressData?.city}}</td>
                                <td>{{(courseRegistration.courseId | courseTemplatesById).name}}</td>
                                <td>{{courseRegistration.createdAt | dateFormat:'DD.MM.YYYY HH:mm:ss'}}</td>
                                <td>{{courseRegistration.accepted | yesNoLabel}}</td>
                                <td>{{courseRegistration.rejected | yesNoLabel}}</td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>

                <div class="col-12">
                    <app-paginator [currentPage]="filterForm.get('page')?.value" [rows]="20" [totalRecords]="count" (changePageEvent)="changePage($event); download();"></app-paginator>
                </div>
            </div>
        </app-list-with-loader>
    </div>
</div>
