import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictionaryEstablishmentTypeInterface} from "../../../interfaces/dictionary.interface";
import {environment} from "../../../../environments/environment";
import {AnyInterface, BasicServiceInterface, FilteredListInterface} from "../../../interfaces/global.interface";
import {Params} from '@angular/router';
import {ServiceDataHandler} from '../../../classes/service-data-handler.class';

@Injectable({
    providedIn: 'root'
})
export class DictionaryEstablishmentTypesService extends ServiceDataHandler<DictionaryEstablishmentTypeInterface> implements BasicServiceInterface<DictionaryEstablishmentTypeInterface> {

    constructor(private http: HttpClient) {
        super();
    }

    public downloadList(): Observable<DictionaryEstablishmentTypeInterface[]> {
        return this.http.get<DictionaryEstablishmentTypeInterface[]>(environment.apiPath + 'establishments');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<DictionaryEstablishmentTypeInterface>> {
        return this.http.get<FilteredListInterface<DictionaryEstablishmentTypeInterface>>(environment.apiPath + 'establishments', {params});
    }

    public downloadListNotAuthorized(): Observable<DictionaryEstablishmentTypeInterface[]> {
        return this.http.get<DictionaryEstablishmentTypeInterface[]>(environment.apiPath + 'course-registrations/dictionary/establishments');
    }

    public downloadOne(id: string): Observable<DictionaryEstablishmentTypeInterface> {
        return this.http.get<DictionaryEstablishmentTypeInterface>(environment.apiPath + 'establishments/' + id);
    }

    public saveOne(data: DictionaryEstablishmentTypeInterface): Observable<DictionaryEstablishmentTypeInterface> {
        return this.http.post<DictionaryEstablishmentTypeInterface>(environment.apiPath + 'establishments', data);
    }

    public updateOne(data: DictionaryEstablishmentTypeInterface, dataId: string): Observable<DictionaryEstablishmentTypeInterface> {
        return this.http.put<DictionaryEstablishmentTypeInterface>(environment.apiPath + 'establishments/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'establishments/' + dataId);
    }

    public updatePositions(data: AnyInterface<number>): Observable<void> {
        return this.http.post<void>(environment.apiPath + 'establishments/sort', data);
    }
}
