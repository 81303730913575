import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalComponent} from '../modal/modal.component';
import {ModalLoaderData, ModalLoaderService} from './modal-loader.service';
import {UtilsService} from '../../services/utils.service';
import {Subscription} from 'rxjs';

const BASIC_TITLE: string = 'Ładowanie...';

@Component({
  selector: 'app-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.scss']
})
export class ModalLoaderComponent implements OnDestroy {
  @ViewChild('modalLoaderModal', {static: true}) public modalLoaderModal!: ModalComponent;
  public title: string = BASIC_TITLE;
  public text: string = '';
  private subscriptions: Subscription[] = [];

  constructor(private modalLoaderService: ModalLoaderService) {
    this.showModalChanged();
    this.hideModalChanged();
  }

  private showModalChanged(): void {
    this.subscriptions.push(this.modalLoaderService.showModalChanged().subscribe((data: ModalLoaderData) => {
      this.title = !!data?.title ? data.title : BASIC_TITLE;
      this.text = data?.text;

      this.modalLoaderModal.show();
    }));
  }

  private hideModalChanged(): void {
    this.subscriptions.push(this.modalLoaderService.hideModalChanged().subscribe(() => this.modalLoaderModal.hide()));
  }

  ngOnDestroy() {
    UtilsService.unsubscribeAll(this.subscriptions);
  }
}
