import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppLinksService} from '../../services/app-links.service';

@Component({
    selector: 'app-route-not-found',
    templateUrl: './route-not-found.component.html',
    styleUrls: ['./route-not-found.component.scss']
})
export class RouteNotFoundComponent implements OnInit {

    constructor(private router: Router) {
        setTimeout(() => router.navigate([AppLinksService.dashboard]), 1000);
    }

    ngOnInit(): void {
    }
}
