import {Injectable} from '@angular/core';
import {CountryType} from "../types/global.type";

@Injectable({
    providedIn: 'root'
})
export class CountriesService {
    public static readonly keyPL: CountryType = 'PL';
    public static readonly valPL: number = 1;
    public static readonly countryMap: Map<CountryType, number> = new Map<CountryType, number>([
        [CountriesService.keyPL, CountriesService.valPL]
    ]);

    constructor() {
    }

    public static getValue(country: CountryType): number {
        return CountriesService.countryMap.get(country)!;
    }

    public static getKeyByValue(value: number): CountryType {
        for (let [key, val] of CountriesService.countryMap) if (val === value) return key;
        return CountriesService.keyPL;
    }
}
