<app-modal #coursesDetailEditSourceRegistrationOpen [title]="'button.editSourceRegistration' | translate">
    <div class="row">
        <div class="col-12 basicForm__form">
            <form [formGroup]="form" *ngIf="!!form">
                <div class="row basicForm__columns">
                    <div class="col-12 basicForm__firstColumn">
                        <div class="row">
                            <div class="col-12 mt-3">
                                <app-box-with-header [header]="'courseDetail' | translate">
                                    <ng-container formGroupName="course" *ngIf="!!form.get('course')">
                                        <div class="row">
                                            <div class="col-12 formGroup">
                                                <label>{{'courseName' | translate}}</label>
                                                <textarea formControlName="name"></textarea>
                                            </div>

                                            <div class="col-12 formGroup">
                                                <label>{{'dates' | translate}}</label>
                                                <input formControlName="dates"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'type' | translate}}</label>
                                                <input formControlName="type"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'dictionary.area' | translate}}</label>
                                                <input formControlName="area"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'courseForm' | translate}}</label>
                                                <input formControlName="form"/>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="row">
                                        <div class="col-12 col-md-6 formGroup">
                                            <label>{{'expectedPeopleAmount' | translate}}</label>
                                            <input type="number" formControlName="participantCount" />
                                        </div>
                                    </div>
                                </app-box-with-header>
                            </div>

                            <div class="col-12 mt-3">
                                <app-box-with-header [header]="(ifCustomerTypeIndividual()? 'courseParticipantData' : 'contactDataResponsibleCourse') | translate">
                                    <ng-container formGroupName="participantData">
                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('firstName')}">
                                                <label>{{'firstName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="firstName" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('lastName')}">
                                                <label>{{'lastName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="lastName" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('function')}">
                                                <label>{{'function' | translate}} <i class="required">*</i></label>
                                                <select formControlName="function">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let func of statics.functions">
                                                        <option [ngValue]="func.id">{{func.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('topic')}">
                                                <label>{{'subjectTaught' | translate}} <i class="required">*</i></label>
                                                <select formControlName="topic">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let subject of statics.subjects">
                                                        <option [ngValue]="subject.id">{{subject.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('email')}">
                                                <label>{{'email' | translate}} <i class="required">*</i></label>
                                                <input formControlName="email" trim="blur"/>
                                                <i>{{'error.email' | translate}}</i>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('phone')}">
                                                <label>{{'phone' | translate}} <i class="required">*</i></label>
                                                <input formControlName="phone" appOnlyIntegers trim="blur"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>

                            <div class="col-12 mt-3">
                                <app-box-with-header [header]="'placeOfEmployment' | translate">
                                    <ng-container formGroupName="workPlace">
                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'institutionHash' | translate}}</label>
                                                <input formControlName="hash" (blur)="downloadCustomerByData('placementHash', 'hash')"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('name')?.invalid && getWorkPlaceFG().get('name')?.touched}">
                                                <label>{{'institutionName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="name" (blur)="downloadCustomerByData('name', 'name')"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('type')?.invalid && getWorkPlaceFG().get('type')?.touched}">
                                                <label>{{'dictionary.establishmentType' | translate}} <i class="required">*</i></label>
                                                <select formControlName="type">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let establishmentType of statics.establishmentTypes; let establishmentTypeIdx = index;">
                                                        <option [ngValue]="establishmentType.id">{{establishmentType.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'emailEstablishment' | translate}} <i class="required">*</i></label>
                                                <input formControlName="email" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'phoneEstablishment' | translate}} <i class="required">*</i></label>
                                                <input formControlName="phone" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('state')?.invalid && getWorkPlaceFG().get('state')?.touched}">
                                                <label>{{'address.province' | translate}} <i class="required">*</i></label>
                                                <select formControlName="state">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let state of statics.states">
                                                        <option [ngValue]="state.id">{{state.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('district')?.invalid && getWorkPlaceFG().get('district')?.touched}">
                                                <label>{{'address.district' | translate}}</label>
                                                <input formControlName="district" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('commune')?.invalid && getWorkPlaceFG().get('commune')?.touched}">
                                                <label>{{'address.community' | translate}}</label>
                                                <input formControlName="commune"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('city')?.invalid && getWorkPlaceFG().get('city')?.touched}">
                                                <label>{{'address.city' | translate}} <i class="required">*</i></label>
                                                <input formControlName="city"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('postalCode')?.invalid && getWorkPlaceFG().get('postalCode')?.touched}">
                                                <label>{{'address.postalCode' | translate}} <i class="required">*</i></label>
                                                <input formControlName="postalCode" appPostalCodeMask/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('street')?.invalid && getWorkPlaceFG().get('street')?.touched}">
                                                <label>{{'address.street' | translate}} <i class="required">*</i></label>
                                                <input formControlName="street"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup" [ngClass]="{'error': getWorkPlaceFG().get('buildingNumber')?.invalid && getWorkPlaceFG().get('buildingNumber')?.touched}">
                                                <label>{{'address.houseNo' | translate}} <i class="required">*</i></label>
                                                <input formControlName="buildingNumber"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>

                            <div class="col-12 mt-3">
                                <app-box-with-header header="{{'invoiceData' | translate}} - {{'buyer' | translate}}">
                                    <ng-container formGroupName="invoiceBuyerData">
                                        <div class="row">
                                            <div class="col-12 formGroup" [ngClass]="{'error': getBuyerFG().get('nip')?.invalid && getBuyerFG().get('nip')?.touched}">
                                                <label>{{'nip' | translate}}</label>
                                                <input formControlName="nip" appOnlyIntegers/>
                                                <i>{{'error.nip' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 formGroup">
                                                <label>{{'name' | translate}}</label>
                                                <input formControlName="name"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup">
                                                <label>{{'address.city' | translate}}</label>
                                                <input formControlName="city"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup">
                                                <label>{{'address.postalCode' | translate}}</label>
                                                <input formControlName="postalCode" appPostalCodeMask/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'address.street' | translate}}</label>
                                                <input formControlName="street"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.houseNo' | translate}}</label>
                                                <input formControlName="buildingNumber"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>

                            <div class="col-12 mt-3">
                                <app-box-with-header header="{{'invoiceData' | translate}} - {{'recipient' | translate}}">
                                    <ng-container formGroupName="invoiceReceiverData">
                                        <div class="row">
                                            <div class="col-12 formGroup">
                                                <label>{{'name' | translate}}</label>
                                                <input formControlName="name"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup">
                                                <label>{{'address.city' | translate}}</label>
                                                <input formControlName="city"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup">
                                                <label>{{'address.postalCode' | translate}}</label>
                                                <input formControlName="postalCode" appPostalCodeMask/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'address.street' | translate}}</label>
                                                <input formControlName="street"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.houseNo' | translate}}</label>
                                                <input formControlName="buildingNumber"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber"/>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="row">
                                        <div class="col-12 formGroup" [ngClass]="{'error': form.get('email')?.invalid && form.get('email')?.touched}">
                                            <label>{{'emailToSendInvoice' | translate}}</label>
                                            <input formControlName="email"/>
                                            <i>{{'error.email' | translate}}</i>
                                        </div>
                                    </div>
                                </app-box-with-header>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row mt-3" *ngIf="!!sourceRegistration">
        <div class="col-12 formText mt-0">
            <label class="formText__label">{{'dateFilling' | translate}}</label>
            <label class="formText__text">{{sourceRegistration.createdAt | dateFormat:'DD.MM.YYYY HH:mm:ss'}}</label>
        </div>
    </div>

    <div class="row modalFooter">
        <div class="col-12">
            <button (click)="downloadXLSX()" class="basicButton basicButton__greenLight mr-3" type="button">{{'button.download' | translate}}</button>
            <button class="basicButton basicButton__greenDark" type="button" (click)="submit()">{{'button.save' | translate}}</button>
        </div>
    </div>
</app-modal>
