import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

    transform(data: Object, ifSlice: boolean = true): string[] {
        const keys: string[] = Object.keys(data);
        return ifSlice ? keys.slice(keys.length / 2) : keys;
    }
}
