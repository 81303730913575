import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LogoutService} from "../services/logout.service";

@Injectable({
    providedIn: 'root'
})
export class LogoutUserGuard implements CanActivate, CanActivateChild {
    constructor(private logoutService: LogoutService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // ToDo: odkomentować jak zacznie działać dla klientów
        // this.logoutService.submitWithoutRouter();
        return true;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // ToDo: odkomentować jak zacznie działać dla klientów
        // this.logoutService.submitWithoutRouter();
        return true;
    }
}
