import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApplicationFormSubscriptionInterface} from '../../../interfaces/application-form.interface';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApplicationCourseFormSubscriptionService {
    private _form!: FormGroup;
    private form$: Subject<void> = new Subject<void>();
    private resetForm$: Subject<void> = new Subject<void>();

    constructor(private http: HttpClient) {
    }

    public submit(data: ApplicationFormSubscriptionInterface): Observable<void> {
        return this.http.post<void>(environment.apiPath + 'subscriptions/register', data);
    }

    public set form(_form: FormGroup) {
        this._form = _form;
        this.form$.next();
    }

    public get form(): FormGroup {
        return this._form;
    }

    public formChanged(): Observable<void> {
        return this.form$.asObservable();
    }

    public resetForm(): void {
        this.resetForm$.next();
    }

    public resetFormChanged(): Observable<void> {
        return this.resetForm$.asObservable();
    }

    public static getBonusPlacesByValue(value: number): number {
        if (!!value) {
            if (value <= 4000) return 0;
            if (value > 4000 && value <= 5000) return 4;
            if (value > 5000 && value <= 6000) return 5;
            if (value > 6000 && value <= 7000) return 6;
            if (value > 7000 && value <= 8000) return 8;

            return 10;
        }

        return 0;
    }
}
