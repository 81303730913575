<div class="boxWithHeader">
    <div class="boxWithHeader__header" (click)="handleCollapsed()">
        <div class="boxWithHeader__headerText" [ngClass]="{'boxWithHeader__headerText--bigger': headerBigger, 'boxWithHeader__headerText--bolder': headerBolder, 'boxWithHeader__headerText--red': headerRed}">
            <label *ngIf="!!header">{{header}} <i class="required" *ngIf="headerRequired">*</i></label>
            <label *ngIf="!!headerHTML" [innerHtml]="headerHTML"></label>

            <ng-container *ngIf="counter > 0">
                <span>[{{counter}}]</span>
            </ng-container>
        </div>

        <div class="boxWithHeader__collapse" *ngIf="ifCollapse">
            <img [ngClass]="{'collapsed': collapsed}" src="/assets/images/down-arrow.svg" alt="Down arrow" />
        </div>
    </div>

    <div class="boxWithHeader__content" [ngClass]="contentStyleClass" *ngIf="collapsed">
        <ng-content></ng-content>
    </div>
</div>
