import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictionaryCourseTemplateInterface} from "../../../interfaces/dictionary.interface";
import {environment} from "../../../../environments/environment";
import {BasicServiceInterface, FilteredListInterface} from "../../../interfaces/global.interface";
import {Params} from '@angular/router';
import {ServiceDataHandler} from '../../../classes/service-data-handler.class';

@Injectable({
    providedIn: 'root'
})
export class DictionaryCourseTemplatesService extends ServiceDataHandler<DictionaryCourseTemplateInterface> implements BasicServiceInterface<DictionaryCourseTemplateInterface> {

    constructor(private http: HttpClient) {
        super();
    }

    public downloadList(): Observable<DictionaryCourseTemplateInterface[]> {
        return this.http.get<DictionaryCourseTemplateInterface[]>(environment.apiPath + 'courses');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<DictionaryCourseTemplateInterface>> {
        return this.http.get<FilteredListInterface<DictionaryCourseTemplateInterface>>(environment.apiPath + 'courses', {params});
    }

    public downloadListNotAuthorized(): Observable<DictionaryCourseTemplateInterface[]> {
        return this.http.get<DictionaryCourseTemplateInterface[]>(environment.apiPath + 'course-registrations/dictionary/courses');
    }

    public downloadOne(id: string): Observable<DictionaryCourseTemplateInterface> {
        return this.http.get<DictionaryCourseTemplateInterface>(environment.apiPath + 'courses/' + id);
    }

    public saveOne(data: DictionaryCourseTemplateInterface): Observable<DictionaryCourseTemplateInterface> {
        return this.http.post<DictionaryCourseTemplateInterface>(environment.apiPath + 'courses', data);
    }

    public updateOne(data: DictionaryCourseTemplateInterface, dataId: string): Observable<DictionaryCourseTemplateInterface> {
        return this.http.put<DictionaryCourseTemplateInterface>(environment.apiPath + 'courses/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'courses/' + dataId);
    }

    public duplicateOne(dataId: string): Observable<DictionaryCourseTemplateInterface> {
        return this.http.post<DictionaryCourseTemplateInterface>(environment.apiPath + `courses/${dataId}/duplicate`, {});
    }
}
