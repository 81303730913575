import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ApplicationCourseFormType} from '../../../types/global.type';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ApplicationCourseFormSubscriptionService} from './application-course-form-subscription.service';
import {UtilsService} from '../../../services/utils.service';
import {CustomersService} from '../../../pages/customers/customers.service';
import {DictionaryCourseTemplateInterface, DictionaryEstablishmentTypeInterface, DictionaryFunctionInterface} from '../../../interfaces/dictionary.interface';
import {CountryStateInterface} from '../../../interfaces/global.interface';
import {DictionaryEstablishmentTypesService} from '../../../pages/dictionary/dictionary-establishment-types/dictionary-establishment-types.service';
import {DictionaryFunctionsService} from '../../../pages/dictionary/dictionary-functions/dictionary-functions.service';
import {DictionaryCourseTemplatesService} from '../../../pages/dictionary/dictionary-course-templates/dictionary-course-templates.service';
import {CountryStatesService} from '../../../services/country-states.service';
import {CountriesService} from '../../../services/countries.service';
import {EmailControlValidate, emailValidateLogic} from '../../../form-validators/email.validator';
import {NipControlValidate} from '../../../form-validators/nip.validator';
import {SubscriptionPaymentSchedulesEnum} from '../../../enums/subscription-payment-schedules.enum';
import {AtLeastOneValidator} from '../../../form-validators/at-least-one.validator';
import {CourseTypesEnum} from '../../../enums/course-types.enum';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {TranslateService} from '../../../services/translate.service';
import * as _ from 'underscore';
import {PostalCodeControlValidate} from '../../../form-validators/postal-code.validator';
import {HttpParams} from '@angular/common/http';
import {CustomerTypesEnum} from '../../../enums/customer-types.enum';
import {ToastService} from '../../../services/toast.service';

@Component({
  selector: 'app-application-course-form-subscription',
  templateUrl: './application-course-form-subscription.component.html',
  styleUrls: ['./application-course-form-subscription.component.scss']
})
export class ApplicationCourseFormSubscriptionComponent implements OnInit, OnDestroy {
  @Input() public formType: ApplicationCourseFormType = 'customer';
  @Output() public blockSavingButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  public form!: FormGroup;
  private subscriptions: Subscription[] = [];
  public statics: ApplicationCourseFormSubscriptionStatic;
  public courseOpen: ApplicationCourseFormSubscriptionCourseTemplates;
  public courseClosed: ApplicationCourseFormSubscriptionCourseTemplates;
  public bonusPlaces: string;

  constructor(private applicationCourseFormSubscriptionService: ApplicationCourseFormSubscriptionService,
              private customersService: CustomersService,
              private formBuilder: FormBuilder,
              private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
              private dictionaryFunctionsService: DictionaryFunctionsService,
              private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
              private translateService: TranslateService,
              private toastService: ToastService) {
    this.statics = new ApplicationCourseFormSubscriptionStatic(formBuilder, dictionaryEstablishmentTypesService, dictionaryFunctionsService);
    this.courseOpen = new ApplicationCourseFormSubscriptionCourseTemplates(dictionaryCourseTemplatesService, CourseTypesEnum.OPEN);
    this.courseClosed = new ApplicationCourseFormSubscriptionCourseTemplates(dictionaryCourseTemplatesService, CourseTypesEnum.CLOSED);
    this.bonusPlaces = translateService.getTranslated('lack');
    this.createForm();
    this.resetFormChanged();
  }

  private resetFormChanged(): void {
    this.applicationCourseFormSubscriptionService.resetFormChanged().subscribe(() => {
      if (!!this.form) this.form.reset();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    UtilsService.unsubscribeAll(this.subscriptions);
  }

  private createForm(): void {
    this.form = this.statics.createForm();
    this.formChanged();
  }

  public formChanged(): void {
    this.setForm();
    this.subscriptions.push(this.form.valueChanges.subscribe(() => {
      this.setForm();
    }));
  }

  private setForm(): void {
    this.setFormPrepare();
    this.applicationCourseFormSubscriptionService.form = this.form;
  }

  private setFormPrepare(): void {
    this.setFormPrepareCourses();
    this.setFormPrepareBonusPlaces();
  }

  private setFormPrepareBonusPlaces(): void {
    const bonusPlaces: number = ApplicationCourseFormSubscriptionService.getBonusPlacesByValue(this.form.value['value']);

    (<FormControl>this.form.get('bonusPlaces')).patchValue(bonusPlaces, {emitEvent: false});
    this.bonusPlaces = !!bonusPlaces ? String(bonusPlaces) : this.translateService.getTranslated('lack');
  }

  private setFormPrepareCourses(): void {
    let coursesFA: FormArray = <FormArray>this.form.get('courses');
    UtilsService.clearFA(coursesFA);
    for (let id of [...this.courseOpen.getChosenIds(), ...this.courseClosed.getChosenIds()]) coursesFA.push(new FormControl(id), {emitEvent: false});
  }

  public getContactDataFG(): FormGroup {
    return this.form.get('contactData') as FormGroup;
  }

  public getAddressDataFG(): FormGroup {
    return this.form.get('addressData') as FormGroup;
  }

  public getRecipientFG(): FormGroup {
    return this.form.get('invoiceReceiverData') as FormGroup;
  }

  public getBuyerFG(): FormGroup {
    return this.form.get('invoiceBuyerData') as FormGroup;
  }

  public ifError(name: string): boolean {
    return UtilsService.ifFGError(<FormGroup>this.form.get(name));
  }

  public ifContactDataError(name: string): boolean {
    return UtilsService.ifFGError(<FormGroup>this.getContactDataFG().get(name));
  }

  public ifAddressDataError(name: string): boolean {
    return UtilsService.ifFGError(<FormGroup>this.getAddressDataFG().get(name));
  }

  public ifBuyerDataError(name: string): boolean {
    return UtilsService.ifFGError(<FormGroup>this.getBuyerFG().get(name));
  }

  public ifRecipientDataError(name: string): boolean {
    return UtilsService.ifFGError(<FormGroup>this.getRecipientFG().get(name));
  }

  public downloadCustomerByData(propertyName: string, formPropertyName: string): void {
    // const value: string = this.getAddressDataFG().get(formPropertyName)?.value;
    // if (!!value) this.customersService.toDownloadCustomerByData(propertyName, value, this.getAddressDataFG(), this.getBuyerFG(), this.getRecipientFG(), this.getContactDataFG()).then((customer: CustomerInterface) => {
    //     this.getContactDataFG().patchValue({
    //         workPhone: customer.contactData.workPhone,
    //         privatePhone: customer.contactData.privatePhone
    //     });
    //     this.downloadCustomerByDataRewriteAddressToInvoiceReceiver();
    // });
  }

  public checkIfEmailEstablishment(): void {
    const email = this.getAddressDataFG().get('email')?.value;
    if (!!email && emailValidateLogic(email)) {
      const params: HttpParams = new HttpParams().set('propertyName', 'email').set('value', email);
      this.customersService.downloadByData(params).subscribe(customer => {
        this.blockSavingButton.emit(false);
        if (customer.customerType === CustomerTypesEnum.INDIVIDUAL) {
          this.blockSavingButton.emit(true);
          this.toastService.translateText('warning', 'warning.existingParticipantIsEstablishmentType');
        }
      }, () => this.blockSavingButton.emit(false));
    }
  }

  private downloadCustomerByDataRewriteAddressToInvoiceReceiver(): void {
    this.rewriteAddressToInvoiceReceiver('name');
    this.rewriteAddressToInvoiceReceiver('city');
    this.rewriteAddressToInvoiceReceiver('postalCode');
    this.rewriteAddressToInvoiceReceiver('street');
    this.rewriteAddressToInvoiceReceiver('buildingNumber');
    this.rewriteAddressToInvoiceReceiver('localNumber');
  }

  public rewriteAddressToInvoiceReceiver(propName: string): void {
    this.getRecipientFG().get(propName)!.patchValue(this.getAddressDataFG().get(propName)?.value);
  }
}

export class ApplicationCourseFormSubscriptionCourseTemplates {
  public available: DictionaryCourseTemplateInterface[] = [];
  public chosen: DictionaryCourseTemplateInterface[] = [];

  constructor(private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
              private type: CourseTypesEnum.OPEN | CourseTypesEnum.CLOSED) {
    this.available = UtilsService.copyWithoutReference<DictionaryCourseTemplateInterface[]>(this.dictionaryCourseTemplatesService.data).filter((course: DictionaryCourseTemplateInterface) => course.type === type);
    this.sort();
  }

  public drop(event: CdkDragDrop<DictionaryCourseTemplateInterface[]>): void {
    if (event.previousContainer === event.container) moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    else transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    this.sort();
  }

  public getChosenIds(): string[] {
    return this.chosen.map((chosen: DictionaryCourseTemplateInterface) => chosen.id);
  }

  private sort(): void {
    this.available = _.sortBy(this.available, (course: DictionaryCourseTemplateInterface) => course.name.toLowerCase());
    this.chosen = _.sortBy(this.chosen, (course: DictionaryCourseTemplateInterface) => course.name.toLowerCase());
  }
}

export class ApplicationCourseFormSubscriptionStatic {
  public establishmentTypes: DictionaryEstablishmentTypeInterface[] = [];
  public states: CountryStateInterface[] = [];
  public functions: DictionaryFunctionInterface[] = [];
  public paymentScheduleFull: string = SubscriptionPaymentSchedulesEnum.FULL;
  public paymentScheduleAdvanced: string = SubscriptionPaymentSchedulesEnum.ADVANCED;
  public paymentScheduleOther: string = SubscriptionPaymentSchedulesEnum.OTHER;

  constructor(private formBuilder: FormBuilder,
              private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
              private dictionaryFunctionsService: DictionaryFunctionsService) {
    this.getData();
  }

  private getData(): void {
    this.establishmentTypes = this.dictionaryEstablishmentTypesService.data;
    this.functions = this.dictionaryFunctionsService.data;
    this.states = CountryStatesService.getStatesByCountry(CountriesService.valPL);
  }

  public createForm(): FormGroup {
    return this.formBuilder.group({
      customerId: new FormControl(null),
      name: new FormControl(null),
      value: new FormControl(null, Validators.required),
      courses: this.formBuilder.array([]),
      openCourses: new FormControl(null),
      closedCourses: new FormControl(null),
      endDate: new FormControl(null),
      bonusPlaces: new FormControl(null, Validators.required),
      paymentSchedule: new FormControl(SubscriptionPaymentSchedulesEnum.FULL, Validators.required),
      paymentScheduleOtherText: new FormControl(null),
      invoiceTitle: new FormControl(null, Validators.required),
      addressData: this.createFormAddressData(),
      contactData: this.createFormContactData(),
      invoiceBuyerData: this.createFormInvoiceDataBuyer(),
      invoiceReceiverData: this.createFormInvoiceDataRecipient()
    });
  }

  private createFormAddressData(): FormGroup {
    return this.formBuilder.group({
      hash: new FormControl(null),
      type: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      commune: new FormControl(null),
      city: new FormControl(null, Validators.required),
      district: new FormControl(null),
      postalCode: new FormControl(null, [Validators.required, PostalCodeControlValidate]),
      street: new FormControl(null, Validators.required),
      buildingNumber: new FormControl(null, Validators.required),
      localNumber: new FormControl(null)
    });
  }

  private createFormContactData(): FormGroup {
    return this.formBuilder.group({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      function: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, EmailControlValidate]),
      workPhone: new FormControl(null),
      privatePhone: new FormControl(null)
    }, {
      validators: [AtLeastOneValidator(Validators.required, ['workPhone', 'privatePhone'], 'Phone')]
    });
  }

  private createFormInvoiceDataBuyer(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      nip: new FormControl(null, [NipControlValidate, Validators.required]),
      city: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, [Validators.required, PostalCodeControlValidate]),
      street: new FormControl(null, Validators.required),
      buildingNumber: new FormControl(null, Validators.required),
      localNumber: new FormControl(null)
    });
  }

  private createFormInvoiceDataRecipient(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      postalCode: new FormControl(null, [Validators.required, PostalCodeControlValidate]),
      street: new FormControl(null, Validators.required),
      buildingNumber: new FormControl(null, Validators.required),
      localNumber: new FormControl(null)
    });
  }
}
