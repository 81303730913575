<app-modal #dictionaryLocationsAddNewModal [title]="'addNew.newLocationDB' | translate">
    <div class="row">
        <div class="col-12 basicForm__form">
            <app-dictionary-locations-detail-add-new-form [location]="location" (formChanged)="formChanged($event)"></app-dictionary-locations-detail-add-new-form>
        </div>
    </div>

    <div class="row modalFooter">
        <div class="col-12">
            <button class="basicButton basicButton__greenDark" type="button" (click)="submit()">{{'button.save' | translate}}</button>
        </div>
    </div>
</app-modal>
