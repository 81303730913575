import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictionarySubjectInterface} from "../../../interfaces/dictionary.interface";
import {environment} from "../../../../environments/environment";
import {AnyInterface, BasicServiceInterface, FilteredListInterface} from "../../../interfaces/global.interface";
import {Params} from '@angular/router';
import {ServiceDataHandler} from '../../../classes/service-data-handler.class';

@Injectable({
    providedIn: 'root'
})
export class DictionarySubjectsService extends ServiceDataHandler<DictionarySubjectInterface> implements BasicServiceInterface<DictionarySubjectInterface> {

    constructor(private http: HttpClient) {
        super();
    }

    public downloadList(): Observable<DictionarySubjectInterface[]> {
        return this.http.get<DictionarySubjectInterface[]>(environment.apiPath + 'subjects');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<DictionarySubjectInterface>> {
        return this.http.get<FilteredListInterface<DictionarySubjectInterface>>(environment.apiPath + 'subjects', {params});
    }

    public downloadListNotAuthorized(): Observable<DictionarySubjectInterface[]> {
        return this.http.get<DictionarySubjectInterface[]>(environment.apiPath + 'course-registrations/dictionary/subjects');
    }

    public downloadOne(id: string): Observable<DictionarySubjectInterface> {
        return this.http.get<DictionarySubjectInterface>(environment.apiPath + 'subjects/' + id);
    }

    public saveOne(data: DictionarySubjectInterface): Observable<DictionarySubjectInterface> {
        return this.http.post<DictionarySubjectInterface>(environment.apiPath + 'subjects', data);
    }

    public updateOne(data: DictionarySubjectInterface, dataId: string): Observable<DictionarySubjectInterface> {
        return this.http.put<DictionarySubjectInterface>(environment.apiPath + 'subjects/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'subjects/' + dataId);
    }

    public updatePositions(data: AnyInterface<number>): Observable<void> {
        return this.http.post<void>(environment.apiPath + 'subjects/sort', data);
    }
}
