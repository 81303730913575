import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AppLinksService} from "../../../services/app-links.service";
import {CoursesService} from "../courses.service";
import {CourseExport, CourseInterface, CourseListFilterFormInterface} from "../../../interfaces/course.interface";
import {UtilsService} from '../../../services/utils.service';
import {HttpParams} from '@angular/common/http';
import {FormBuilder, FormControl} from '@angular/forms';
import {CourseTypesEnum} from '../../../enums/course-types.enum';
import {DictionaryAreaInterface, DictionaryCourseTemplateInterface} from '../../../interfaces/dictionary.interface';
import {DictionaryCourseTemplatesService} from '../../dictionary/dictionary-course-templates/dictionary-course-templates.service';
import {DictionaryAreasService} from '../../dictionary/dictionary-areas/dictionary-areas.service';
import {map, Subscription, tap} from 'rxjs';
import {FormTypesEnum} from '../../../enums/form-types.enum';
import {TrainersService} from '../../trainers/trainers.service';
import {TrainerInterface} from '../../../interfaces/trainer.interface';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {CourseStatusesEnum} from '../../../enums/course-statuses.enum';
import {ExcelService} from '../../../services/excel.service';
import {HandleLastFilters} from '../../../classes/handle-last-filters.class';
import {ListType} from '../../../enums/list-type.enum';
import {CustomersDetailExportService} from "../../customers/customers-detail-export.service";

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent extends HandleLastFilters<CoursesService, CourseInterface, CourseListFilterFormInterface> implements OnChanges, OnInit, OnDestroy {
  @Input() public type: ListType = ListType.GLOBAL;
  @Input() public trainerInput!: string;
  @Input() public statusInput!: CourseStatusesEnum;
  @Input() public customerIdInput!: string;

  public courseTypes = CourseTypesEnum;
  public formTypes = FormTypesEnum;
  public courseStatuses = CourseStatusesEnum;
  public courseTemplates: DictionaryCourseTemplateInterface[] = [];
  public areas: DictionaryAreaInterface[] = [];
  public trainers: TrainerInterface[] = [];
  private subscriptions: Subscription[] = [];
  public linkType?: 'open' | 'closed';

  public exportAll$ = this.customersDetailExportService.exportAll$.pipe(tap(() => this.exportList()));

  constructor(private coursesService: CoursesService,
              private formBuilder: FormBuilder,
              private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
              private dictionaryAreasService: DictionaryAreasService,
              private trainersService: TrainersService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private excelService: ExcelService,
              private customersDetailExportService: CustomersDetailExportService) {
    super(coursesService, 'courseFilters', 'courseSortOrder', 'startDate', AppLinksService.coursesDetail);
    this.courseTemplates = this.dictionaryCourseTemplatesService.data;
    this.routerChanged();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return;
    if (changes.hasOwnProperty('type')) this.filterLS = `courseFilters${this.getLSSuffix()}`;
  }

  private getLSSuffix(): string {
    if (UtilsService.ifGlobalListTrainer(this.type)) return 'Trainer';
    if (UtilsService.ifGlobalListCustomer(this.type)) return 'Customer';
    return '';
  }

  public get ifGlobalList(): boolean {
    return UtilsService.ifGlobalListGlobal(this.type);
  }

  private routerChanged(): void {
    this.subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (!!this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params.hasOwnProperty('type')) {
        this.linkType = this.activatedRoute.snapshot.params['type'];
        this.init();
      }
    }));
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.createFilterForm();
    this.download();
    this.downloadTrainers();
    this.downloadAreas();
  }

  ngOnDestroy() {
    UtilsService.unsubscribeAll(this.subscriptions);
  }

  public download(ifReset: boolean = true): void {
    if (ifReset) this.resetPagination();
    this.downloadFilteredList(this.getParams()).then(() => {
      this.list.forEach((item: CourseInterface) => {
        item = CoursesService.prepareCourse(item);
        item.name = item.name.match(/.{1,50}/g)!.join("<br/>");
        if (!!item.properties?.sourceRegistrationEntity?.addressData?.name) item.properties.sourceRegistrationEntity.addressData.name = item.properties.sourceRegistrationEntity.addressData.name.match(/.{1,50}/g)!.join("<br/>");
      });
    });
    this.filterBoxFlag = false;
  }

  private downloadTrainers(): void {
    this.trainersService.downloadListMapped().subscribe((trainers: TrainerInterface[]) => this.trainers = trainers);
  }

  private downloadAreas(): void {
    this.dictionaryAreasService.downloadList().subscribe((areas: DictionaryAreaInterface[]) => this.areas = areas);
  }

  public toResetOneValue(prop: string): void {
    this.resetOneValue(prop);
    this.download();
  }

  public createFilterForm(): void {
    const filter: CourseListFilterFormInterface = this.getFilter();
    this.filterForm = this.formBuilder.group({
      page: new FormControl(filter.page | 0),
      type: new FormControl(UtilsService.ifIdx(filter.type) ? filter.type : (this.linkType === 'open' ? CourseTypesEnum.OPEN : (this.linkType === 'closed' ? CourseTypesEnum.CLOSED : null))),
      templateId: new FormControl(filter.templateId),
      courseAreaId: new FormControl(filter.courseAreaId),
      form: new FormControl(filter.form),
      localizations: new FormControl([]),
      dateFrom: new FormControl(filter.dateFrom),
      dateTo: new FormControl(filter.dateTo),
      localization: new FormControl(filter.localization),
      name: new FormControl(filter.name),
      customerName: new FormControl(filter.customerName),
      customerSurname: new FormControl(filter.customerSurname),
      customers: new FormControl(!!this.customerIdInput ? [this.customerIdInput] : []),
      trainer: new FormControl(this.trainerInput || filter.trainer),
      status: new FormControl(this.statusInput || (!!this.trainerInput ? filter.status : (filter.status || CourseStatusesEnum.PLANNED)))
    });
  }

  public resetFilter(): void {
    this.filterForm.get('page')?.setValue(0);
    this.filterForm.get('templateId')?.reset();
    this.filterForm.get('courseAreaId')?.reset();
    this.filterForm.get('form')?.reset();
    this.filterForm.get('localizations')?.reset();
    this.filterForm.get('dateFrom')?.reset();
    this.filterForm.get('dateTo')?.reset();
    this.filterForm.get('localization')?.reset();
    this.filterForm.get('name')?.reset();
    this.filterForm.get('customerName')?.reset();
    this.filterForm.get('customerSurname')?.reset();
    if (!this.trainerInput) this.filterForm.get('trainer')?.reset();
    if (!this.statusInput) this.filterForm.get('status')?.reset();
    this.download();
  }

  private getParams(): HttpParams {
    const filterForm: CourseListFilterFormInterface = this.filterForm.value;
    let params: HttpParams = new HttpParams().set('page', filterForm.page);
    if (!!filterForm.type || filterForm.type === 0) params = params.set('type', filterForm.type);
    if (!!filterForm.templateId) params = params.set('templateId', filterForm.templateId);
    if (!!filterForm.courseAreaId) params = params.set('courseAreaId', filterForm.courseAreaId);
    if (!!filterForm.form || filterForm.form === 0) params = params.set('form', filterForm.form);
    if (!!filterForm.localizations && filterForm.localizations.length > 0) params = params.set('localizations', filterForm.localizations.join(','));
    if (!!filterForm.dateFrom) params = params.set('dateFrom', String(filterForm.dateFrom));
    if (!!filterForm.dateTo) params = params.set('dateTo', String(filterForm.dateTo));
    if (!!filterForm.localization) params = params.set('localization', filterForm.localization);
    if (!!filterForm.name) params = params.set('name', filterForm.name);
    if (!!filterForm.customerName) params = params.set('customerName', filterForm.customerName);
    if (!!filterForm.customerSurname) params = params.set('customerSurname', filterForm.customerSurname);
    if (!!filterForm.trainer) params = params.set('trainer', filterForm.trainer);
    if (!!filterForm.status) params = params.set('status', filterForm.status);
    if (!!filterForm.customers && filterForm.customers.length > 0) filterForm.customers.forEach(customer => params = params.set('customers[]', customer));
    params = params.set('sortBy', this.sortBy.column);
    params = params.set('sortType', this.sortBy.order);
    this.setFilters();

    return params;
  }

  public ifTypeOpen(courseType: number): boolean {
    return UtilsService.ifCourseOpen(courseType);
  }

  public ifTypeClosed(courseType: number): boolean {
    return UtilsService.ifCourseClosed(courseType);
  }

  public ifStatusRejected(status: CourseStatusesEnum): boolean {
    return CourseStatusesEnum.REJECTED === status;
  }

  public getTrainerInfo(trainerId: string): string {
    let trainer: TrainerInterface | undefined = this.trainers.find((trainerItem: TrainerInterface) => trainerItem.id === trainerId);
    return !!trainer ? `${trainer.firstName} ${trainer.lastName}` : '';
  }

  public exportList(): void {
    this.downloadFilteredListForExport(this.getParams()).then((list: CourseInterface[]) => {
      this.excelService.save<CourseExport>(this.coursesService.prepareListForExcel(CoursesService.prepareCourses(list), this.trainers), ['Typ', 'Nazwa', 'Daty', 'Trenerzy', 'Lokalizacje', 'Il. godz.', 'Forma', 'Placówka', 'Miejscowość placówki', 'Il. ucz.', 'Il. zap. plac.', 'Uczestnicy - plac.', 'Uczestnicy - wszyscy', 'Status'], ['excelType', 'name', 'excelDates', 'excelTrainers', 'excelLocalizations', 'excelHourCount', 'excelForm', 'excelClient', 'excelClientCity', 'excelActualParticipantAmount', 'excelActualPlacementAmount', 'excelActualPlacementPredictedParticipantAmount', 'excelActualAllPredictedParticipantAmount', 'excelStatus'], 'Szkolenia', 'Lista szkoleń');
    });
  }

  public openNewTab(id: string): void {
    UtilsService.openNewTab(`${this.linkToDetail}/${id}`);
  }
}
