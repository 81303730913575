import {Observable, Subject} from 'rxjs';

export abstract class ModalHandler<T, L> {
    private showModal$: Subject<T> = new Subject<T>();
    private hideModal$: Subject<L> = new Subject<L>();

    public showModal(data: T): void {
        this.showModal$.next(data);
    }

    public showModalChanged(): Observable<T> {
        return this.showModal$.asObservable();
    }

    public hideModal(data: L): void {
        this.hideModal$.next(data);
    }

    public hideModalChanged(): Observable<L> {
        return this.hideModal$.asObservable();
    }
}
