import {Injectable} from '@angular/core';
import {BasicServiceInterface, FilteredListInterface} from "../../interfaces/global.interface";
import {TrainerContractInterface, TrainerInterface} from "../../interfaces/trainer.interface";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Params} from '@angular/router';
import * as _ from "underscore";

@Injectable({
    providedIn: 'root'
})
export class TrainersService implements BasicServiceInterface<TrainerInterface> {

    constructor(private http: HttpClient) {
    }

    public downloadList(): Observable<TrainerInterface[]> {
        return this.http.get<TrainerInterface[]>(environment.apiPath + 'trainers');
    }

    public downloadListMapped(): Observable<TrainerInterface[]> {
        return this.downloadList().pipe(
            map(trainers => _.sortBy(trainers, 'lastName')),
            map(trainers => {
                return trainers.map((trainer: TrainerInterface) => {
                    trainer.name = `${trainer.lastName} ${trainer.firstName}`;
                    return trainer;
                })
            })
        );
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<TrainerInterface>> {
        return this.http.get<FilteredListInterface<TrainerInterface>>(environment.apiPath + 'trainers', {params});
    }

    public downloadOne(id: string): Observable<TrainerInterface> {
        return this.http.get<TrainerInterface>(environment.apiPath + 'trainers/' + id);
    }

    public saveOne(data: TrainerInterface): Observable<TrainerInterface> {
        return this.http.post<TrainerInterface>(environment.apiPath + 'trainers', data);
    }

    public updateOne(data: TrainerInterface, dataId: string): Observable<TrainerInterface> {
        return this.http.put<TrainerInterface>(environment.apiPath + 'trainers/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'trainers/' + dataId);
    }

    public downloadContractList(trainerId: string): Observable<TrainerContractInterface[]> {
        return this.http.get<TrainerContractInterface[]>(environment.apiPath + `trainers/${trainerId}/contracts`);
    }

    public downloadContractOne(trainerId: string, contractId: string): Observable<TrainerContractInterface> {
        return this.http.get<TrainerContractInterface>(environment.apiPath + `trainers/${trainerId}/contracts/${contractId}`);
    }

    public saveContractOne(trainerId: string, contract: TrainerContractInterface): Observable<TrainerContractInterface> {
        return this.http.post<TrainerContractInterface>(environment.apiPath + `trainers/${trainerId}/contracts`, contract);
    }

    public updateContractOne(trainerId: string, contract: TrainerContractInterface): Observable<TrainerContractInterface> {
        return this.http.put<TrainerContractInterface>(environment.apiPath + `trainers/${trainerId}/contracts/${contract.id}`, contract);
    }

    public deleteContractOne(trainerId: string, contractId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + `trainers/${trainerId}/contracts/${contractId}`);
    }
}
