export enum CourseTypesEnum {
    CLOSED = 0,
    OPEN = 1,
    // MIXED = 2
}

export const AllCourseTypes = [
    CourseTypesEnum.CLOSED,
    CourseTypesEnum.OPEN,
    // CourseTypesEnum.MIXED
]
