import {Injectable} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn} from "@angular/forms";
import {CourseTypesEnum} from '../enums/course-types.enum';
import {CourseCustomerCustomerInterface, CourseCustomerInterface, CourseInterface} from '../interfaces/course.interface';
import {EveryModelInterface} from '../interfaces/global.interface';
import {ApplicationCourseFormType} from '../types/global.type';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {toHTML, Toolbar} from 'ngx-editor';
import {Moment} from 'moment';
import * as moment from 'moment';
import {FooterStaticService} from './footer-static.service';
import {ListType} from '../enums/list-type.enum';
import {CustomerTypesEnum} from '../enums/customer-types.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public static toolbarOptions: Toolbar = [
    ["bold", "italic"],
    ["underline", "strike"],
    ["ordered_list", "bullet_list"],
    [{heading: ["h1", "h2", "h3", "h4", "h5", "h6"]}],
    ["link", "image"],
    ["text_color", "background_color"],
    ["align_left", "align_center", "align_right", "align_justify"]
  ];
  public static readonly dashboardLastDate: string = 'dashboardLastDate';

  constructor(private http: HttpClient) {
  }

  public sendEmail(data: FormData): Observable<void> {
    return this.http.post<void>(environment.apiPath + 'custom-actions/send-custom-email', data);
  }

  public static unsubscribeAll(subscriptions: Subscription[]): void {
    subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public static copyWithoutReference<T>(data: T): T {
    if (!data) return data;
    return JSON.parse(JSON.stringify(data));
  }

  public static parseACToFC(programFC: AbstractControl): FormControl {
    return programFC as FormControl;
  }

  public static parseACToFG(programFC: AbstractControl): FormGroup {
    return programFC as FormGroup;
  }

  public static ifIdx(idx: number): boolean {
    return (!!idx || idx === 0) && idx > -1;
  }

  public static ifCourseOpen(courseType: number): boolean {
    return courseType === CourseTypesEnum.OPEN;
  }

  public static ifCourseClosed(courseType: number): boolean {
    return courseType === CourseTypesEnum.CLOSED;
  }

  public static ifFGError(fg: FormGroup): boolean {
    return fg.invalid && fg.touched;
  }

  public static getCourseCustomerReceivers(customers: CourseCustomerInterface[]): string[] {
    return UtilsService.copyWithoutReference<CourseCustomerInterface[]>(customers).filter((customer: CourseCustomerInterface) => customer.selected).map((customer: CourseCustomerInterface) => customer.id);
  }

  public static getUniqueByProp<T>(items: T[], propName: string): T[] {
    return items.filter((thing: T, index: number, self: T[]) => index === self.findIndex((t: T) => t[propName] === thing[propName]));
  }

  public static moveObjInArr<T>(arr: (T | undefined)[], oldIdx: number, newIdx: number): (T | undefined)[] {
    if (newIdx >= arr.length) {
      let k = newIdx - arr.length + 1;
      while (k--) arr.push(undefined);
    }
    arr.splice(newIdx, 0, arr.splice(oldIdx, 1)[0]);
    return arr;
  }

  public static pushToArrById<T extends EveryModelInterface>(arr: T[], obj: T): void {
    const objIdx: number = arr.findIndex((arrObj: T) => arrObj.id === obj.id);
    if (objIdx === -1) arr.push(obj);
    else arr[objIdx] = obj;
  }

  public static ifGlobalListCustomer(type: ListType): boolean {
    return type === ListType.CUSTOMER;
  }

  public static ifGlobalListGlobal(type: ListType): boolean {
    return type === ListType.GLOBAL;
  }

  public static ifGlobalListTrainer(type: ListType): boolean {
    return type === ListType.TRAINER;
  }

  public static ifApplicationFormTypeAdministration(type: ApplicationCourseFormType): boolean {
    return type === 'administration';
  }

  public static ifApplicationFormTypeCustomer(type: ApplicationCourseFormType): boolean {
    return type === 'customer';
  }

  public static clearFA(fa: FormArray): void {
    while (fa.length !== 0) fa.removeAt(0, {emitEvent: false});
  }

  public static toFirstUpperCase(word: string): string {
    return this ? (word.charAt(0).toUpperCase() + word.slice(1)) : this;
  }

  public static ngxEditorToHtml(record: string | Record<string, any>): string {
    if (!!record && typeof record !== 'string') record = toHTML(<Record<string, any>>record);
    return record;
  }

  public static updateFGValidators(fg: FormGroup, validators: ValidatorFn[], value: any): void {
    fg?.clearValidators();
    fg?.setValidators(validators);
    fg?.setValue(value, {emitEvent: false});
    fg?.updateValueAndValidity({emitEvent: false});
  }

  public static getMonthsBetweenDates(date1: Date, date2: Date): string[] {
    let dateFrom: Moment = moment(date1);
    let dateTo: Moment = moment(date2);
    let dates: string[] = [];

    while (dateTo > dateFrom || dateFrom.format('M') === dateTo.format('M')) {
      dates.push(dateFrom.format('YYYY-MM'));
      dateFrom.add(1, 'month');
    }

    return dates;
  }

  public static getFooterToCourse(type: CourseTypesEnum): string {
    return FooterStaticService.generalFooter;
    // if (type === CourseTypesEnum.CLOSED) return FooterStaticService.closedCourseFooter;
    // return FooterStaticService.openCourseFooter;
  }

  public static openNewTab(link: string): void {
    window.open(link, '_blank');
  }

  public static prepareLinkToOpenForm(course: CourseInterface, customerType: CustomerTypesEnum): string {
    return `https://biuro.empiria.edu.pl/application-form/open?courseTemplateId=${course.templateId}&activeCourseId=${course.id}&customerType=${customerType}`;
  }

  public static detectSex(customer: CourseCustomerCustomerInterface): string {
    if (!customer) return '';
    customer.name = customer.name.trim();
    if (customer.name.endsWith('a') || customer.name.endsWith('A')) return 'Pani';
    return 'Pan';
  }

  public static sleep(seconds: number): Promise<number> {
    return new Promise(res => setTimeout(res, seconds * 1000));
  }

  public static compareObjects = (x: any, y: any) => {
    try {
      return JSON.stringify(x) === JSON.stringify(y);
    } catch (e) {
      const mappedX: number | number[] = Array.isArray(x) ? x?.map(r => r.id) : x?.id;
      const mappedY: number | number[] = Array.isArray(y) ? y?.map(r => r.id) : y?.id;
      return JSON.stringify(mappedX) === JSON.stringify(mappedY);
    }
  };

  public static copyTextToClipboard(text: string): void {
    let fakeTA: any = document.createElement('textarea');
    document.body.appendChild(fakeTA);
    fakeTA.value = text;
    fakeTA.select();
    document.execCommand('copy');
    document.body.removeChild(fakeTA);
  }

  public static findDuplicateObjects<T>(arr: T[], propName: string): T[] {
    const uniqueEmails = new Set();
    const duplicateObjects: T[] = [];

    for (const obj of arr) {
      if (uniqueEmails.has(obj[propName])) {
        duplicateObjects.push(obj);
      } else {
        uniqueEmails.add(obj[propName]);
      }
    }

    return duplicateObjects;
  }
}
