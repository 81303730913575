import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../../../services/utils.service';
import {DictionaryLocationInterface} from '../../../../../interfaces/dictionary.interface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CountriesService} from '../../../../../services/countries.service';
import {CountryStatesService} from '../../../../../services/country-states.service';
import {CountryStateInterface} from '../../../../../interfaces/global.interface';

@Component({
    selector: 'app-dictionary-locations-detail-add-new-form',
    templateUrl: './dictionary-locations-detail-add-new-form.component.html',
    styleUrls: ['./dictionary-locations-detail-add-new-form.component.scss']
})
export class DictionaryLocationsDetailAddNewFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public location!: DictionaryLocationInterface;
    @Output() private formChanged: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    private subscriptions: Subscription[] = [];
    public form!: FormGroup;
    public states: CountryStateInterface[] = [];

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes && changes.hasOwnProperty('location') && !!changes['location'].currentValue) this.createForm();
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    private createForm(): void {
        this.form = this.formBuilder.group({
            'name': new FormControl(this.location.name, Validators.required),
            'city': new FormControl(this.location.city, Validators.required),
            'street': new FormControl(this.location.street),
            'buildingNumber': new FormControl(this.location.buildingNumber),
            'localNumber': new FormControl(this.location.localNumber || ''),
            'postalCode': new FormControl(this.location.postalCode),
            'state': new FormControl(this.location.state, Validators.required),
            'country': new FormControl(this.location.country || CountriesService.valPL, Validators.required)
        });
        if (!!this.location.id) this.form.addControl('id', new FormControl(this.location.id, Validators.required));
        this.handleFormChanges();
        this.getStates();
    }

    private handleFormChanges(): void {
        setTimeout(() => this.emitForm());
        this.subscriptions.push(this.form.valueChanges.subscribe(() => {
            this.emitForm();
        }));
    }

    private emitForm(): void {
        this.formChanged.emit(this.form);
    }

    private getStates(): void {
        this.states = CountryStatesService.getStatesByCountry(this.form.get('country')?.value);
    }
}
