import {Injectable} from '@angular/core';
import {Alignment, Borders, Cell, CellValue, Column, Fill, Font, Row, Workbook, Worksheet} from 'exceljs';
import * as fs from 'file-saver';

export type BorderType = 'top' | 'left' | 'right' | 'bottom';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor() {
    }

    public static getFontStyle(size: number, bold: boolean): Partial<Font> {
        return {
            bold: bold,
            size: size
        }
    }

    public static getAlignmentStyle(horizontal: 'left' | 'center' | 'right' | 'fill' | 'justify' | 'centerContinuous' | 'distributed', vertical?: 'top' | 'middle' | 'bottom' | 'distributed' | 'justify', wrapText?: boolean): Partial<Alignment> {
        return {
            horizontal,
            vertical,
            wrapText
        }
    }

    public static getBordersStyle(borders: BorderType[]): Partial<Borders> {
        let borderObj = {};
        for (let border of borders) borderObj[border] = {style: 'thin'};
        return borderObj;
    }

    public static getFontFill(bgColor: string, fgColor: string): Fill {
        return {
            type: 'pattern',
            pattern: 'darkHorizontal',
            fgColor: {argb: fgColor},
            bgColor: {argb: bgColor}
        }
    }

    private static generateWorkbookPrepareHeaders(worksheet: Worksheet, headerNames: string[]): void {
        const headerRow: Row = worksheet.addRow({});
        headerNames.forEach((header: string, idx: number) => {
            const headerCell: Cell = headerRow.getCell(idx + 1);
            headerCell.value = header;
            headerCell.style.font = ExcelService.getFontStyle(12, true);
            headerCell.style.fill = ExcelService.getFontFill('92D050', '92D050');
        });
    }

    private static generateWorkbookPrepareData<T>(worksheet: Worksheet, list: T[], propNames: string[]): void {
        list.forEach((record: T, recordIdx: number) => {
            const row: Row = worksheet.addRow(record);
            propNames.forEach((header: string, idx: number) => {
                const cell: Cell = row.getCell(idx + 1);
                cell.value = record[header];
                cell.style.font = ExcelService.getFontStyle(11, false);
                if (recordIdx % 2 === 0) cell.style.fill = ExcelService.getFontFill('EBF1DE', 'EBF1DE');
            });
        });
    }

    private static generateWorkbookAdjustColumnWidth(worksheet: Worksheet): void {
        worksheet.columns.forEach((column: Partial<Column>) => column.width = Math.max(...column.values!.map((v: CellValue) => !!v ? (v.toString().length + 5) : 0).filter(v => typeof v === 'number')));
    }

    public generateWorkbook<T>(list: T[], headerNames: string[], propNames: string[] = [], sheetName: string): Workbook {
        const workbook: Workbook = new Workbook();
        const worksheet: Worksheet = workbook.addWorksheet(sheetName);

        ExcelService.generateWorkbookPrepareHeaders(worksheet, headerNames);
        ExcelService.generateWorkbookPrepareData<T>(worksheet, list, propNames);
        ExcelService.generateWorkbookAdjustColumnWidth(worksheet);

        return workbook;
    }

    public save<T>(list: T[], headerNames: string[], propNames: string[] = [], sheetName: string, fileName: string): void {
        const workbook: Workbook = this.generateWorkbook(list, headerNames, propNames, sheetName);
        this.saveWorkbook(workbook, fileName);
    }

    public saveWorkbook(workbook: Workbook, fileName: string): void {
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            fs.saveAs(blob, fileName + '.xlsx');
        });
    }

    public getBlob<T>(list: T[], headerNames: string[], propNames: string[] = [], sheetName: string): Promise<Blob> {
        return new Promise<Blob>((resolve, reject) => {
            const workbook: Workbook = this.generateWorkbook(list, headerNames, propNames, sheetName);
            workbook.xlsx.writeBuffer().then((data) => {
                resolve(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
            });
        });
    }
}
