import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApplicationFormCloseCourseRegistrationInterface} from '../../../interfaces/application-form.interface';
import {CourseInterface} from '../../../interfaces/course.interface';
import {Params} from '@angular/router';
import {FilteredListInterface} from '../../../interfaces/global.interface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DictionaryCourseTemplateInterface} from '../../../interfaces/dictionary.interface';
import {CourseTypesEnum} from '../../../enums/course-types.enum';
import * as moment from 'moment';
import {EmailControlValidate} from '../../../form-validators/email.validator';

@Injectable({
    providedIn: 'root'
})
export class CoursesRegistrationClosedService {

    constructor(private http: HttpClient,
                private formBuilder: FormBuilder) {
    }

    public downloadList(): Observable<ApplicationFormCloseCourseRegistrationInterface[]> {
        return this.http.get<ApplicationFormCloseCourseRegistrationInterface[]>(environment.apiPath + 'course-registrations/closed');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<ApplicationFormCloseCourseRegistrationInterface>> {
        return this.http.get<FilteredListInterface<ApplicationFormCloseCourseRegistrationInterface>>(environment.apiPath + 'course-registrations/closed', {params});
    }

    public downloadPureFilteredList(params: Params): Observable<ApplicationFormCloseCourseRegistrationInterface[]> {
        return this.http.get<ApplicationFormCloseCourseRegistrationInterface[]>(environment.apiPath + 'course-registrations/closed', {params});
    }

    public downloadOne(id: string): Observable<ApplicationFormCloseCourseRegistrationInterface> {
        return this.http.get<ApplicationFormCloseCourseRegistrationInterface>(environment.apiPath + 'course-registrations/closed/' + id);
    }

    public accept(id: string): Observable<CourseInterface> {
        return this.http.put<CourseInterface>(environment.apiPath + `course-registrations/closed/${id}/activate`, {});
    }

    public reject(id: string): Observable<CourseInterface> {
        return this.http.put<CourseInterface>(environment.apiPath + `course-registrations/closed/${id}/reject`, {});
    }

    public createForm(sourceRegistration: ApplicationFormCloseCourseRegistrationInterface, courseArea: string): FormGroup {
        if (!sourceRegistration.addressData.email) sourceRegistration.addressData.email = '';
        if (!sourceRegistration.addressData.phone) sourceRegistration.addressData.phone = '';
        if (!sourceRegistration.contactData.privatePhone) sourceRegistration.contactData.privatePhone = '';

        return this.formBuilder.group({
            customerId: new FormControl(sourceRegistration.customerId, Validators.required),
            note: new FormControl({disabled: true, value: sourceRegistration.note}),
            organizerNote: new FormControl(sourceRegistration.organizerNote),
            predictedParticipantCount: new FormControl(sourceRegistration.predictedParticipantCount, Validators.required),
            predictedTimeOfCourseFrom: new FormControl(CoursesRegistrationClosedService.getFormPredictedDate(sourceRegistration.predictedTimeOfCourseFrom!)),
            predictedTimeOfCourseTo: new FormControl(CoursesRegistrationClosedService.getFormPredictedDate(sourceRegistration.predictedTimeOfCourseTo!)),

            courseId: new FormControl(sourceRegistration.courseId, Validators.required),
            courseArea: new FormControl(courseArea),
            courseType: new FormControl(CourseTypesEnum.CLOSED),
            form: new FormControl(sourceRegistration.form, Validators.required),
            email: new FormControl(sourceRegistration.email, EmailControlValidate),

            addressData: this.formBuilder.group(sourceRegistration.addressData),
            contactData: this.formBuilder.group(sourceRegistration.contactData),
            invoiceBuyerData: this.formBuilder.group(sourceRegistration.invoiceBuyerData),
            invoiceReceiverData: this.formBuilder.group(sourceRegistration.invoiceReceiverData),

            subscription: new FormControl(sourceRegistration.subscription),
            bonus: new FormControl(sourceRegistration.bonus),
            requiredAgreement: new FormControl(sourceRegistration.requiredAgreement),
            optionalAgreement: new FormControl(sourceRegistration.optionalAgreement),
        });
    }

    private static getFormPredictedDate(date: Date): string | null {
        return new Date(date).getTime() === 0 ? null : moment(date).format('YYYY-MM-DD');
    }

    public static getUpdatePayload(data: ApplicationFormCloseCourseRegistrationInterface): ApplicationFormCloseCourseRegistrationInterface {
        delete data.courseArea;
        delete data.courseType;

        if (!data.predictedTimeOfCourseFrom) data.predictedTimeOfCourseFrom = null;
        if (!data.predictedTimeOfCourseTo) data.predictedTimeOfCourseTo = null;
        if (!data.email) data.email = null;

        return data;
    }
}
