import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import {CourseDateInterface, CourseInterface, CourseTaskInterface} from '../../interfaces/course.interface';
import {UtilsService} from '../../services/utils.service';
import {CourseCalendarEventExtendedPropsInterface, CourseCalendarEventInterface} from '../../interfaces/global.interface';
import {AppLinksService} from '../../services/app-links.service';
import {Router} from '@angular/router';
import {Calendar} from '@fullcalendar/core';
import * as moment from 'moment';
import {CourseStatusesEnum} from '../../enums/course-statuses.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import {FormTypesEnum} from '../../enums/form-types.enum';
import {Moment} from 'moment';

const CLOSED_COURSE_COLOR: string = '#71B9ED';
const OPEN_COURSE_COLOR: string = '#6BC969';

@Component({
    selector: 'app-course-calendar',
    templateUrl: './course-calendar.component.html',
    styleUrls: ['./course-calendar.component.scss']
})
export class CourseCalendarComponent implements OnChanges {
    @ViewChild('calendarComponent') calendarComponent!: FullCalendarComponent;
    @Input() public courses: CourseInterface[] = [];
    @Input() public loading: boolean = false;
    @Output() private dateEmit: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() public exportEmit: EventEmitter<void> = new EventEmitter<void>();
    public calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        height: '100%',
        locale: 'pl',
        firstDay: 1,
        dayHeaderFormat: {weekday: 'long'},
        events: [],
        eventClick: this.handleEventClick.bind(this),
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit'
        },
        customButtons: {
            next: {
                click: this.nextMonth.bind(this),
            },
            prev: {
                click: this.prevMonth.bind(this),
            }
        }
    };

    public calendarDate!: string;

    constructor(private router: Router,
                private spinner: NgxSpinnerService) {
        this.setInitialDate();
    }

    private setInitialDate(): void {
        let date: Moment = !!localStorage.getItem(UtilsService.dashboardLastDate) ? moment(localStorage.getItem(UtilsService.dashboardLastDate)) : moment();
        this.calendarOptions.initialDate = date.toDate();
        this.calendarDate = date.format('YYYY-MM-DD');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('courses') && !!changes['courses'].currentValue) this.prepareEvents();
        if (changes.hasOwnProperty('loading')) this.handleLoading(changes['loading'].currentValue);
    }

    private handleLoading = (flag: boolean) => flag ? this.spinner.show() : this.spinner.hide();

    private prepareEvents(): void {
        let events: CourseCalendarEventInterface[] = [];
        this.courses.forEach((course: CourseInterface) => events.push(...CourseCalendarComponent.prepareOneCourse(course)));
        this.calendarOptions.events = events;
    }

    private static prepareOneCourse(course: CourseInterface): CourseCalendarEventInterface[] {
        let events: CourseCalendarEventInterface[] = [];
        if (!!course.dates && course.dates.length > 0) course.dates.forEach((courseDate: CourseDateInterface, courseDateIdx: number) => events.push(CourseCalendarComponent.getEventDate(course, courseDate, courseDateIdx)));
        if (!!course.tasks && course.tasks.length > 0) course.tasks.filter((courseTask: CourseTaskInterface) => !courseTask.done).forEach((courseTask: CourseTaskInterface) => events.push(CourseCalendarComponent.getEventTask(course, courseTask)));
        return events;
    }

    private static getEventDate(course: CourseInterface, courseDate: CourseDateInterface, courseDateIdx: number): CourseCalendarEventInterface {
        return {
            title: CourseCalendarComponent.getEventDateTitle(course, courseDateIdx),
            date: courseDate.date,
            backgroundColor: CourseCalendarComponent.getColor(course.type),
            className: CourseCalendarComponent.getEventDateClassName(course, courseDate),
            allDay: true,
            extendedProps: {
                type: 'date',
                courseDate,
                course
            }
        }
    }

    private static getEventDateClassName(course: CourseInterface, courseDate: CourseDateInterface): string {
        const className: string[] = [];
        if (moment(new Date(`${courseDate.date} ${courseDate.startHour}`)).isBefore(new Date())) className.push('opacity05');
        if (course.courseStatus === CourseStatusesEnum.REJECTED) className.push('lineThroughCalendar');
        if (course.form === FormTypesEnum.STATIONARY) className.push('stationaryCourseInDashboard');
        if (course.courseStatus === CourseStatusesEnum.IN_PROGRESS) className.push('inProgressCourseInDashboard');
        return className.join(' ');
    }

    private static getEventDateTitle(course: CourseInterface, courseDateIdx: number): string {
        if (UtilsService.ifCourseClosed(course.type)) return `${course.properties.sourceRegistrationEntity?.addressData.name}. ${course.name.slice(0, 15)} ${CourseCalendarComponent.getEventDateTitleDay(course, courseDateIdx)}`;
        else return `${course.name} ${CourseCalendarComponent.getEventDateTitleDay(course, courseDateIdx)}`;
    }

    private static getEventDateTitleDay(course: CourseInterface, courseDateIdx: number): string {
        return `${course.dates.length > 1 ? ('- Dzień ' + (courseDateIdx + 1)) : ''}`;
    }

    private static getEventTask(course: CourseInterface, courseTask: CourseTaskInterface): CourseCalendarEventInterface {
        let startHour = courseTask.startHour.split(':').map(Number);
        return {
            title: courseTask.name,
            date: new Date(new Date(courseTask.date).setHours(startHour[0], startHour[1])),
            backgroundColor: CourseCalendarComponent.getColor(course.type),
            allDay: false,
            extendedProps: {
                type: 'task',
                courseTask,
                course
            },
            className: courseTask.done ? 'lineThrough' : ''
        }
    }

    private static getColor(type: number): string {
        return UtilsService.ifCourseClosed(type) ? CLOSED_COURSE_COLOR : OPEN_COURSE_COLOR;
    }

    public handleEventClick(data): void {
        const extendedProps: CourseCalendarEventExtendedPropsInterface = data?.event?.extendedProps;
        if (!!extendedProps && !!extendedProps.course && !!extendedProps.course.id) {
            const url: string = this.router.serializeUrl(this.router.createUrlTree([AppLinksService.coursesDetail, extendedProps.course.id]));
            window.open(url, '_blank');
        }
    }

    public nextMonth(): void {
        this.getCalendarAPI().next();
        this.emitDateFromCalendarAPI();
    }

    public prevMonth(): void {
        this.getCalendarAPI().prev();
        this.emitDateFromCalendarAPI();
    }

    public calendarDateChanged(): void {
        this.getCalendarAPI().gotoDate(new Date(this.calendarDate));
        this.emitDateFromCalendarAPI();
    }

    private getCalendarAPI(): Calendar {
        return this.calendarComponent.getApi();
    }

    private emitDateFromCalendarAPI(): void {
        this.dateEmit.emit(this.getCalendarAPI().getDate());
    }
}
