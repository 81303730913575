<div class="boxWithBackground boxWithBackground--{{background}}">
    <div class="boxWithBackground__header">
        <div class="boxWithBackground__actions">
            <img src="/assets/images/delete.svg" alt="Delete" (click)="binClick()" />
            <img *ngIf="ifCollapse" [ngClass]="{'collapsed': collapsed}" src="/assets/images/down-arrow.svg" alt="Down arrow" (click)="handleCollapsed()" />
        </div>
    </div>

    <div class="boxWithBackground__content" *ngIf="collapsed">
        <ng-content></ng-content>
    </div>
</div>
