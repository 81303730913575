import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CoursesDetailEditSourceRegistrationOpenService} from './courses-detail-edit-source-registration-open.service';
import {Subscription} from 'rxjs';
import {ApplicationFormOpenCourseRegistrationInterface} from '../../../../interfaces/application-form.interface';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ModalComponent} from '../../../../parts/modal/modal.component';
import {CoursesService} from '../../courses.service';
import {ToastService} from '../../../../services/toast.service';
import {CustomersService} from '../../../customers/customers.service';
import {DictionaryEstablishmentTypesService} from '../../../dictionary/dictionary-establishment-types/dictionary-establishment-types.service';
import {DictionaryAreaInterface, DictionaryEstablishmentTypeInterface, DictionaryFunctionInterface, DictionarySubjectInterface} from '../../../../interfaces/dictionary.interface';
import {DictionarySubjectsService} from '../../../dictionary/dictionary-subjects/dictionary-subjects.service';
import {DictionaryFunctionsService} from 'src/app/pages/dictionary/dictionary-functions/dictionary-functions.service';
import {CourseRegistrationXlsx} from '../../../../classes/course-registration-xlsx.class';
import {ExcelService} from '../../../../services/excel.service';
import {CourseDateInterface, CourseInterface} from '../../../../interfaces/course.interface';
import {TranslatePipe} from '../../../../pipes/translate.pipe';
import {EnumNameByIdPipe} from '../../../../pipes/enum-name-by-id.pipe';
import {TranslateService} from '../../../../services/translate.service';
import {DictionaryAreasService} from '../../../dictionary/dictionary-areas/dictionary-areas.service';
import {CustomerTypesEnum} from '../../../../enums/customer-types.enum';
import {UtilsService} from '../../../../services/utils.service';
import {CountryStatesService} from '../../../../services/country-states.service';
import {CountriesService} from '../../../../services/countries.service';
import {CountryStateInterface} from '../../../../interfaces/global.interface';
import * as moment from 'moment';

@Component({
    selector: 'app-courses-detail-edit-source-registration-open',
    templateUrl: './courses-detail-edit-source-registration-open.component.html',
    styleUrls: ['./courses-detail-edit-source-registration-open.component.scss']
})
export class CoursesDetailEditSourceRegistrationOpenComponent implements OnInit, OnDestroy {
    @ViewChild('coursesDetailEditSourceRegistrationOpen', {static: true}) public coursesDetailEditSourceRegistrationOpen!: ModalComponent;
    private subscriptions: Subscription[] = [];
    public form!: FormGroup;
    public statics: CoursesDetailEditSourceRegistrationOpenStatics;
    public sourceRegistration!: ApplicationFormOpenCourseRegistrationInterface;
    private courseRegistrationXlsx!: CourseRegistrationXlsx;
    public course: CourseInterface = <CourseInterface>{};
    private translatePipe: TranslatePipe;
    private enumNameById: EnumNameByIdPipe;

    constructor(private coursesDetailEditSourceRegistrationOpenService: CoursesDetailEditSourceRegistrationOpenService,
                private formBuilder: FormBuilder,
                private coursesService: CoursesService,
                private toastService: ToastService,
                private customersService: CustomersService,
                private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
                private dictionarySubjectsService: DictionarySubjectsService,
                private dictionaryFunctionsService: DictionaryFunctionsService,
                private excelService: ExcelService,
                private dictionaryAreasService: DictionaryAreasService,
                private translateService: TranslateService) {
        this.statics = new CoursesDetailEditSourceRegistrationOpenStatics(dictionaryEstablishmentTypesService, dictionarySubjectsService, dictionaryFunctionsService);
        this.showModalChanged();
        this.translatePipe = new TranslatePipe(translateService);
        this.enumNameById = new EnumNameByIdPipe();
    }

    private showModalChanged(): void {
        this.subscriptions.push(this.coursesDetailEditSourceRegistrationOpenService.showModalChanged().subscribe((sourceRegistration: ApplicationFormOpenCourseRegistrationInterface) => {
            this.course = this.coursesDetailEditSourceRegistrationOpenService.course;
            this.createForm(sourceRegistration);
            this.courseRegistrationXlsx = new CourseRegistrationXlsx(this.excelService, this.dictionaryFunctionsService, this.dictionarySubjectsService, this.dictionaryEstablishmentTypesService, this.xlsxSubHeaderParticipantDataName, this.xlsxSubHeaderWorkPlaceName);
            this.coursesDetailEditSourceRegistrationOpen.show();
        }));
    }

    private get xlsxSubHeaderParticipantDataName(): string {
        return this.sourceRegistration.customerType === CustomerTypesEnum.INDIVIDUAL ? 'DANE UCZESTNIKA SZKOLENIA' : 'DANE KONTAKTOWE OSOBY ODPOWIEDZIALNEJ ZA SZKOLENIE/KONSULTACJE';
    }

    private get xlsxSubHeaderWorkPlaceName(): string {
        return this.sourceRegistration.customerType === CustomerTypesEnum.INDIVIDUAL ? 'MIEJSCE ZATRUDNIENIA' : 'DANE ADRESOWE';
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    private createForm(sourceRegistration: ApplicationFormOpenCourseRegistrationInterface): void {
        this.sourceRegistration = sourceRegistration;
        this.form = this.coursesDetailEditSourceRegistrationOpenService.getSourceRegistrationDTO(sourceRegistration);
        this.form.addControl('course', this.formBuilder.group({
            type: new FormControl({disabled: true, value: this.translatePipe.transform('enum.courseTypes.' + (this.course.type === 0 ? 'CLOSED' : 'OPEN'))}),
            area: new FormControl({disabled: true, value: this.dictionaryAreasService.data.find((area: DictionaryAreaInterface) => area.id === this.course.courseAreaId)?.name}),
            name: new FormControl({disabled: true, value: this.course.name}),
            form: new FormControl({disabled: true, value: this.translatePipe.transform('enum.formTypes.' + this.enumNameById.transform(this.course.form, 'formTypes'))}),
            dates: new FormControl({disabled: true, value: `${this.course.dates.map((date: CourseDateInterface) => (!!date.date && !!date.startHour && !!date.endHour) ? `${moment(date.date).format('DD.MM.YYYY')} ${date.startHour} - ${date.endHour}` : '').join(', ')}`}),
            price: new FormControl(this.course.price),
            group10Price: new FormControl(this.course.group10Price),
            groupPrice: new FormControl(this.course.groupPrice),
            additionalPrice: new FormControl(this.course.additionalPrice),
            maxCustomersWithoutPayment: new FormControl(this.course.maxCustomersWithoutPayment)
        }));
    }

    public ifError(name: string): boolean {
        return UtilsService.ifFGError(<FormGroup>this.form.get(name));
    }

    public ifParticipantDataError(name: string): boolean {
        return UtilsService.ifFGError(<FormGroup>this.getParticipantDataFG().get(name));
    }

    public getParticipantDataFG(): FormGroup {
        return this.form.get('participantData') as FormGroup;
    }

    public getWorkPlaceFG(): FormGroup {
        return this.form.get('workPlace') as FormGroup;
    }

    public getRecipientFG(): FormGroup {
        return this.form.get('invoiceReceiverData') as FormGroup;
    }

    public getBuyerFG(): FormGroup {
        return this.form.get('invoiceBuyerData') as FormGroup;
    }

    public downloadCustomerByData(propertyName: string, formPropertyName: string): void {
        const value: string = this.getWorkPlaceFG().get(formPropertyName)?.value;
        if (!!value) this.customersService.toDownloadCustomerByData(propertyName, value, this.getWorkPlaceFG(), this.getBuyerFG(), this.getRecipientFG());
    }

    public ifCustomerTypeIndividual(): boolean {
        return this.sourceRegistration.customerType === CustomerTypesEnum.INDIVIDUAL;
    }

    public submit(): void {
        if (this.form.valid) {
            this.coursesService.updateRegistrationOpen(this.sourceRegistration.id, this.prepareSubmitDTO()).subscribe((sourceRegistration: ApplicationFormOpenCourseRegistrationInterface) => {
                this.toastService.success.update();
                this.close(sourceRegistration);
            });
        }
        else this.toastService.fillAllFormData();
    }

    private prepareSubmitDTO(): ApplicationFormOpenCourseRegistrationInterface {
        const value: ApplicationFormOpenCourseRegistrationInterface = this.form.value;
        delete value.course;

        return value;
    }

    private close(sourceRegistration: ApplicationFormOpenCourseRegistrationInterface): void {
        this.coursesDetailEditSourceRegistrationOpenService.hideModal(sourceRegistration);
        this.coursesDetailEditSourceRegistrationOpen.hide();
    }

    public downloadXLSX(): void {
        this.courseRegistrationXlsx.call(this.sourceRegistration, (this.form.get('course') as FormGroup)?.getRawValue());
    }
}

class CoursesDetailEditSourceRegistrationOpenStatics {
    public establishmentTypes: DictionaryEstablishmentTypeInterface[] = [];
    public states: CountryStateInterface[] = [];
    public subjects: DictionarySubjectInterface[] = [];
    public functions: DictionaryFunctionInterface[] = [];

    constructor(private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
                private dictionarySubjectsService: DictionarySubjectsService,
                private dictionaryFunctionsService: DictionaryFunctionsService) {
        this.getData();
        this.getStates();
    }

    private getData(): void {
        this.subjects = this.dictionarySubjectsService.data;
        this.establishmentTypes = this.dictionaryEstablishmentTypesService.data;
        this.functions = this.dictionaryFunctionsService.data;
    }

    private getStates(): void {
        this.states = CountryStatesService.getStatesByCountry(CountriesService.valPL);
    }
}
