import {Injectable} from '@angular/core';
import {AnyInterface, TranslateInterface} from "../interfaces/global.interface";
import * as _ from 'underscore';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TranslateService {
    private _translations!: TranslateInterface;

    constructor(private http: HttpClient) {
    }

    public download(): Observable<TranslateInterface> {
        return this.http.get<TranslateInterface>('/assets/translations/pl.json');
    }

    public set translations(_translations: TranslateInterface) {
        this._translations = _translations;
    }

    public get translations(): TranslateInterface {
        return this._translations;
    }

    public getTranslated(word: string): string {
        return !!word ? (<string>_.get(this._translations, word.split('.')) || word) : word;
    }
}
