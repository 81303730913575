import {Injectable} from '@angular/core';
import {CountryStateInterface} from "../interfaces/global.interface";
import {CountriesService} from "./countries.service";
import {UtilsService} from "./utils.service";

@Injectable({
    providedIn: 'root'
})
export class CountryStatesService {
    public static readonly statesPL: CountryStateInterface[] = [
        {id: 1, name: 'dolnośląskie'},
        {id: 2, name: 'kujawsko-pomorskie'},
        {id: 3, name: 'lubelskie'},
        {id: 4, name: 'lubuskie'},
        {id: 5, name: 'łódzkie'},
        {id: 6, name: 'małopolskie'},
        {id: 7, name: 'mazowieckie'},
        {id: 8, name: 'opolskie'},
        {id: 9, name: 'podkarpackie'},
        {id: 10, name: 'podlaskie'},
        {id: 11, name: 'pomorskie'},
        {id: 12, name: 'śląskie'},
        {id: 13, name: 'świętokrzyskie'},
        {id: 14, name: 'warmińsko-mazurskie'},
        {id: 15, name: 'wielkopolskie'},
        {id: 16, name: 'zachodniopomorskie'}
    ];
    public static readonly statesShortPL: CountryStateInterface[] = [
        {id: 1, name: 'DŚ'},
        {id: 2, name: 'KP'},
        {id: 3, name: 'LB'},
        {id: 4, name: 'LS'},
        {id: 5, name: 'ŁD'},
        {id: 6, name: 'MP'},
        {id: 7, name: 'MZ'},
        {id: 8, name: 'OP'},
        {id: 9, name: 'PK'},
        {id: 10, name: 'PL'},
        {id: 11, name: 'PM'},
        {id: 12, name: 'ŚL'},
        {id: 13, name: 'ŚK'},
        {id: 14, name: 'WM'},
        {id: 15, name: 'WP'},
        {id: 16, name: 'ZP'}
    ];

    constructor() {
    }

    public static getStatesByCountry(country: number): CountryStateInterface[] {
        return UtilsService.ifIdx(country) ? CountryStatesService['states' + CountriesService.getKeyByValue(country)] : [];
    }

    public static getStateById(id: number): CountryStateInterface {
        return CountryStatesService.statesPL.find((state: CountryStateInterface) => state.id === id)!;
    }

    public static getStateShortById(id: number): CountryStateInterface {
        return CountryStatesService.statesShortPL.find((state: CountryStateInterface) => state.id === id)!;
    }
}
