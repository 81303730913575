import {Injectable} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Observable, Subject} from "rxjs";
import {ApplicationFormOpenInterface} from "../../../interfaces/application-form.interface";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CustomerTypesEnum} from '../../../enums/customer-types.enum';

@Injectable({
    providedIn: 'root'
})
export class ApplicationCourseFormOpenService {
    private _form!: FormGroup;
    private form$: Subject<void> = new Subject<void>();
    private resetForm$: Subject<void> = new Subject<void>();

    constructor(private http: HttpClient) {
    }

    public submit(data: ApplicationFormOpenInterface): Observable<any> {
        return this.http.post(environment.apiPath + 'course-registrations/open', data);
    }

    public checkIfAssignAsParticipant(activeCourseId: string, email: string): Observable<void> {
        return this.http.get<void>(environment.apiPath + `course-registrations/active-course/${activeCourseId}/customer/${email}`);
    }

    public checkIfEstablishmentParticipant(email: string): Observable<CustomerTypesEnum> {
        return this.http.post<CustomerTypesEnum>(environment.apiPath + `course-registrations/validate-customer`, {email});
    }

    public set form(_form: FormGroup) {
        this._form = _form;
        this.form$.next();
    }

    public get form(): FormGroup {
        return this._form;
    }

    public formChanged(): Observable<void> {
        return this.form$.asObservable();
    }

    public resetForm(): void {
        this.resetForm$.next();
    }

    public resetFormChanged(): Observable<void> {
        return this.resetForm$.asObservable();
    }
}
