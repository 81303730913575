import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {forkJoin, Observable, of} from "rxjs";
import {TranslateService} from "./translate.service";
import {AuthService} from "./auth.service";
import {TranslateInterface} from '../interfaces/global.interface';
import {DictionaryAddresseesService} from '../pages/dictionary/dictionary-addressees/dictionary-addressees.service';
import {DictionaryCourseTemplatesService} from '../pages/dictionary/dictionary-course-templates/dictionary-course-templates.service';
import {DictionaryEstablishmentTypesService} from '../pages/dictionary/dictionary-establishment-types/dictionary-establishment-types.service';
import {DictionarySubjectsService} from '../pages/dictionary/dictionary-subjects/dictionary-subjects.service';
import {DictionaryAddresseeInterface, DictionaryAreaInterface, DictionaryCourseTemplateInterface, DictionaryEstablishmentTypeInterface, DictionaryFunctionInterface, DictionarySubjectInterface} from '../interfaces/dictionary.interface';
import {DictionaryAreasService} from '../pages/dictionary/dictionary-areas/dictionary-areas.service';
import {DictionaryFunctionsService} from '../pages/dictionary/dictionary-functions/dictionary-functions.service';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    constructor(private http: HttpClient,
                private translateService: TranslateService,
                private authService: AuthService,
                private dictionaryAddresseesService: DictionaryAddresseesService,
                private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
                private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
                private dictionarySubjectsService: DictionarySubjectsService,
                private dictionaryAreasService: DictionaryAreasService,
                private dictionaryFunctionsService: DictionaryFunctionsService) {
    }

    public init(): Promise<void> {
        this.setLoggedUserDataFromToken();
        return new Promise<void>((resolve, reject) => {
            forkJoin(this.getApiCalls()).subscribe(([translations, addressees, courseTemplates, establishmentTypes, subjects, areas, functions]: any) => {
                this.translateService.translations = translations;
                this.dictionaryAddresseesService.data = addressees;
                this.dictionaryCourseTemplatesService.data = courseTemplates;
                this.dictionaryEstablishmentTypesService.data = establishmentTypes;
                this.dictionarySubjectsService.data = subjects;
                this.dictionaryAreasService.data = areas;
                this.dictionaryFunctionsService.data = functions;

                resolve();
            }, error => {
                reject();
            });
        });
    }

    private getApiCalls(): [Observable<TranslateInterface>, Observable<DictionaryAddresseeInterface[]>, Observable<DictionaryCourseTemplateInterface[]>, Observable<DictionaryEstablishmentTypeInterface[]>, Observable<DictionarySubjectInterface[]>, Observable<DictionaryAreaInterface[]>, Observable<DictionaryFunctionInterface[]>] {
        return [
            this.translateService.download(),
            this.dictionaryAddresseesService.downloadListNotAuthorized(),
            this.dictionaryCourseTemplatesService.downloadListNotAuthorized(),
            this.dictionaryEstablishmentTypesService.downloadListNotAuthorized(),
            this.dictionarySubjectsService.downloadListNotAuthorized(),
            this.dictionaryAreasService.downloadListNotAuthorized(),
            this.dictionaryFunctionsService.downloadListNotAuthorized()
        ];
    }

    private setLoggedUserDataFromToken(): void {
        this.authService.setLoggedUserDataFromToken();
    }
}
