import {OrderType} from '../types/global.type';

export class SortBy {

    constructor(public order: OrderType = 'ASC',
                public column: string = 'createdAt') {
    }

    public columnClicked(columnName: string): void {
        if (this.column === columnName) this.changeOrder();
        else this.changeColumn(columnName);
    }

    public changeOrder(): void {
        if (this.order === 'ASC') this.order = 'DESC';
        else this.order = 'ASC';
    }

    public changeColumn(columnName: string): void {
        this.column = columnName;
        this.order = 'ASC';
    }

    public getArrow(columnName: string): string {
        if (this.column === columnName) return this.order === 'ASC' ? '↓' : '↑';
        return '';
    }
}
