import {Injectable} from '@angular/core';
import {ModalHandler} from '../../../../classes/modal-handler.class';
import {ApplicationFormOpenCourseRegistrationInterface} from '../../../../interfaces/application-form.interface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CourseInterface} from '../../../../interfaces/course.interface';

@Injectable({
    providedIn: 'root'
})
export class CoursesDetailEditSourceRegistrationOpenService extends ModalHandler<ApplicationFormOpenCourseRegistrationInterface, ApplicationFormOpenCourseRegistrationInterface> {
    private _course: CourseInterface = <CourseInterface>{};

    constructor(private formBuilder: FormBuilder) {
        super();
    }

    get course(): CourseInterface {
        return this._course;
    }

    set course(value: CourseInterface) {
        this._course = value;
    }

    public getSourceRegistrationDTO(sourceRegistration: ApplicationFormOpenCourseRegistrationInterface): FormGroup {
        return this.formBuilder.group({
            email: new FormControl(sourceRegistration.email),
            optionalAgreement: new FormControl(sourceRegistration.optionalAgreement),
            requiredAgreement: new FormControl(sourceRegistration.requiredAgreement),
            bonus: new FormControl(sourceRegistration.bonus),
            subscription: new FormControl(sourceRegistration.subscription),
            participantCount: new FormControl(sourceRegistration.participantCount),
            courseOrganizer: new FormControl(sourceRegistration.courseOrganizer),

            organizationInfoSent: new FormControl(sourceRegistration.organizationInfoSent),
            resignation: new FormControl(sourceRegistration.resignation),
            invoiceSent: new FormControl(sourceRegistration.invoiceSent),
            note: new FormControl(sourceRegistration.note),

            participantData: this.formBuilder.group(sourceRegistration.participantData),
            workPlace: this.formBuilder.group({
                hash: new FormControl(sourceRegistration.workPlace.hash),
                name: new FormControl(sourceRegistration.workPlace.name, Validators.required),
                email: new FormControl(sourceRegistration.workPlace.email),
                phone: new FormControl(sourceRegistration.workPlace.phone),
                type: new FormControl(sourceRegistration.workPlace.type, Validators.required),
                state: new FormControl(sourceRegistration.workPlace.state, Validators.required),
                district: new FormControl(sourceRegistration.workPlace.district),
                commune: new FormControl(sourceRegistration.workPlace.commune),
                city: new FormControl(sourceRegistration.workPlace.city, Validators.required),
                postalCode: new FormControl(sourceRegistration.workPlace.postalCode, Validators.required),
                street: new FormControl(sourceRegistration.workPlace.street, Validators.required),
                buildingNumber: new FormControl(sourceRegistration.workPlace.buildingNumber, Validators.required),
                localNumber: new FormControl(sourceRegistration.workPlace.localNumber)
            }),
            invoiceBuyerData: this.formBuilder.group(sourceRegistration.invoiceBuyerData),
            invoiceReceiverData: this.formBuilder.group(sourceRegistration.invoiceReceiverData)
        });
    }
}
