import {ExcelService} from '../services/excel.service';
import {Worksheet} from 'exceljs';
import {TableXLSXRowData} from '../interfaces/global.interface';

export class TableXlsxStatics {

    public static prepareRowsColumns(worksheet: Worksheet, size: number): void {
        worksheet.getRow(1).height = 44.5;
        for (let i = 3; i <= size + 3; i++) worksheet.getRow(i).height = 22.5;

        worksheet.getColumn(1).width = 5;
        worksheet.getColumn(2).width = 22;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 10;
        worksheet.getColumn(5).width = 33;
    }

    public static prepareHeader(worksheet: Worksheet, header: string): void {
        worksheet.mergeCells('A1:E2');
        worksheet.getCell('A1').value = header;
        worksheet.getCell('A1').style.font = ExcelService.getFontStyle(26, true);
        worksheet.getCell('A1').style.alignment = ExcelService.getAlignmentStyle('center', 'middle');
        worksheet.getCell('A1').style.border = ExcelService.getBordersStyle(['top', 'bottom', 'left', 'right']);
    }

    public static prepareSubHeader(worksheet: Worksheet, startRow: number, subHeader: string): void {
        worksheet.mergeCells(`A${startRow}:E${startRow}`);
        worksheet.getCell(`A${startRow}`).value = subHeader;
        worksheet.getCell(`A${startRow}`).style.font = ExcelService.getFontStyle(12, true);
        worksheet.getCell(`A${startRow}`).style.alignment = ExcelService.getAlignmentStyle('center', 'middle');
        worksheet.getCell(`A${startRow}`).style.border = ExcelService.getBordersStyle(['top', 'bottom', 'left', 'right']);
        worksheet.getCell(`A${startRow}`).style.fill = ExcelService.getFontFill('C8E1C8', 'C8E1C8');
    }

    public static prepareData(worksheet: Worksheet, data: TableXLSXRowData[], startRow: number): void {
        for (let i = 0; i < data.length; i++) {
            worksheet.mergeCells(`A${startRow + i}:B${startRow + i}`);
            worksheet.getCell(`A${startRow + i}`).value = data[i].label;
            worksheet.getCell(`A${startRow + i}`).style.font = ExcelService.getFontStyle(12, true);
            worksheet.getCell(`A${startRow + i}`).style.alignment = ExcelService.getAlignmentStyle('center', 'middle');
            worksheet.getCell(`A${startRow + i}`).style.border = ExcelService.getBordersStyle(['top', 'bottom', 'left', 'right']);
            worksheet.getCell(`A${startRow + i}`).style.fill = ExcelService.getFontFill('C8E1C8', 'C8E1C8');

            worksheet.mergeCells(`C${startRow + i}:E${startRow + i}`);
            worksheet.getCell(`C${startRow + i}`).value = data[i].value;
            worksheet.getCell(`C${startRow + i}`).style.font = ExcelService.getFontStyle(12, true);
            worksheet.getCell(`C${startRow + i}`).style.alignment = ExcelService.getAlignmentStyle('center', 'middle', true);
            worksheet.getCell(`C${startRow + i}`).style.border = ExcelService.getBordersStyle(['top', 'bottom', 'left', 'right']);
        }
    }
}
