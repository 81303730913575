import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaginatorService {
    private _currentPage: number = 0;
    private _maxPage: number = 0;
    private reset$: Subject<void> = new Subject<void>();

    constructor() {
    }

    public set currentPage(_currentPage: number) {
        this._currentPage = _currentPage;
    }

    public get currentPage(): number {
        return this._currentPage;
    }

    public set maxPage(_maxPage: number) {
        this._maxPage = _maxPage;
    }

    public get maxPage(): number {
        return this._maxPage;
    }

    public resetValues() {
        this.currentPage = 0;
        this.maxPage = 0;

        this.reset$.next();
    }

    public resetChanged(): Observable<void> {
        return this.reset$.asObservable();
    }
}
