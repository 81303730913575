<ng-container *ngIf="exportAll$ | async"></ng-container>

<div class="row basicList">
  <div class="col-12 basicList__header headerWithButton">
    <label class="basicList__headerText">{{(ifGlobalList ? 'courses' : '') | translate}}</label>
    <div class="basicForm__headerActions">
      <button class="basicList__headerAction basicButton basicButton__greenDark" (click)="exportList()">{{'button.export' | translate}}</button>
      <button class="basicList__headerAction basicButton basicButton__greenDark" [routerLink]="[linkToDetail]">{{'addNew.course' | translate}}</button>
    </div>
  </div>

  <div class="col-12 basicList__filter listFilter">
    <form [formGroup]="filterForm" (submit)="download()" *ngIf="!!filterForm">
      <button type="button" class="basicButton basicButton__greenLight basicButton__icon basicButton__icon--filter" (click)="handleFilterBox()"></button>

      <div class="">
        <app-filter-chosen *ngIf="!!getFilterDictName(courseTemplates, 'templateId')" label="courseDictionary" [value]="getFilterDictName(courseTemplates, 'templateId')" (remove)="toResetOneValue('templateId')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('customerName')" label="customerName" [value]="getFilterName('customerName')" (remove)="toResetOneValue('customerName')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('customerSurname')" label="customerSurname" [value]="getFilterName('customerSurname')" (remove)="toResetOneValue('customerSurname')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('name')" label="name" [value]="getFilterName('name')" (remove)="toResetOneValue('name')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('dateFrom')" label="dateFrom" [value]="getFilterName('dateFrom')" (remove)="toResetOneValue('dateFrom')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('dateTo')" label="dateTo" [value]="getFilterName('dateTo')" (remove)="toResetOneValue('dateTo')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterDictName(trainers, 'trainer') && !trainerInput" label="trainer" [value]="getFilterDictName(trainers, 'trainer')" (remove)="toResetOneValue('trainer')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterEnumName(courseTypes, 'type')" label="type" [value]="('enum.courseTypes.' + getFilterEnumName(courseTypes, 'type')) | translate" (remove)="toResetOneValue('type')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterDictName(areas, 'courseAreaId')" label="dictionary.area" [value]="getFilterEnumName(areas, 'courseAreaId')" (remove)="toResetOneValue('courseAreaId')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterEnumName(formTypes, 'form')" label="courseForm" [value]="('enum.formTypes.' + getFilterEnumName(formTypes, 'form')) | translate" (remove)="toResetOneValue('form')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterName('localization')" label="dictionary.location" [value]="getFilterName('localization')" (remove)="toResetOneValue('localization')"></app-filter-chosen>
        <app-filter-chosen *ngIf="!!getFilterEnumName(courseStatuses, 'status') && !statusInput" label="status" [value]="('enum.courseStatuses.' + getFilterEnumName(courseStatuses, 'status')?.toUpperCase()) | translate" (remove)="toResetOneValue('status')"></app-filter-chosen>
      </div>

      <ng-container *ngIf="filterBoxFlag">
        <div class="filterBox">
          <div class="row">
            <div class="col-12 filterBox__actions">
              <img src="/assets/images/close.svg" alt="Close" (click)="handleFilterBox()">
            </div>

            <div class="col-12 filterBox__filters">
              <div class="row">
                <div class="col-12 col-md-6 col-xl-4 formGroup">
                  <label>{{'courseDictionary' | translate}}</label>
                  <ng-select formControlName="templateId">
                    <ng-container *ngFor="let course of courseTemplates">
                      <ng-container *ngIf="!filterForm.get('courseAreaId')?.value ? true : filterForm.get('courseAreaId')?.value === course.courseAreaId">
                        <ng-option [value]="course.id">{{course.name}}</ng-option>
                      </ng-container>
                    </ng-container>
                  </ng-select>
                </div>

                <div class="col-12 col-md-4 col-xl-4 formGroup">
                  <label>{{'name' | translate}}</label>
                  <input formControlName="name"/>
                </div>

                <div class="col-12 col-md-4 col-xl-4 formGroup">
                  <label>Data</label>
                  <div class="formGroup__range">
                    <span>od</span>
                    <input type="date" formControlName="dateFrom"/>
                    <span>do</span>
                    <input type="date" formControlName="dateTo"/>
                  </div>
                </div>

                <div class="col-12 col-md-4 col-xl-4 formGroup" *ngIf="!trainerInput">
                  <label>{{'trainer' | translate}}</label>
                  <ng-select formControlName="trainer">
                    <ng-container *ngFor="let trainer of trainers">
                      <ng-option [value]="trainer.id">{{trainer.name}}</ng-option>
                    </ng-container>
                  </ng-select>
                </div>

                <div class="col-12 col-md-6 col-xl-4 formGroup">
                  <label>{{'type' | translate}}</label>
                  <select formControlName="type">
                    <option [ngValue]="null">{{'selectChoose.type' | translate}}</option>
                    <ng-container *ngFor="let courseType of courseTypes | enumToArray">
                      <option [ngValue]="courseTypes[courseType]">{{('enum.courseTypes.' + courseType) | translate}}</option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-12 col-md-6 col-xl-4 formGroup">
                  <label>{{'dictionary.area' | translate}}</label>
                  <select formControlName="courseAreaId">
                    <option [ngValue]="null">{{'selectChoose.area' | translate}}</option>
                    <ng-container *ngFor="let area of areas; let areaIdx = index;">
                      <option [ngValue]="area.id">{{area.name}}</option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-12 col-md-6 col-xl-4 formGroup">
                  <label>{{'courseForm' | translate}}</label>
                  <select formControlName="form">
                    <option [ngValue]="null">{{'selectChoose.form' | translate}}</option>
                    <ng-container *ngFor="let formType of formTypes | enumToArray;">
                      <option [ngValue]="formTypes[formType]">{{('enum.formTypes.' + formType) | translate}}</option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-12 col-md-4 col-xl-4 formGroup">
                  <label>{{'dictionary.location' | translate}}</label>
                  <input formControlName="localization"/>
                </div>

                <div class="col-12 col-md-6 col-xl-4 formGroup" *ngIf="!statusInput">
                  <label>{{'status' | translate}}</label>
                  <select formControlName="status">
                    <option [ngValue]="null">{{'selectChoose.status' | translate}}</option>
                    <ng-container *ngFor="let courseStatus of courseStatuses | enumToArray:false;">
                      <option [ngValue]="courseStatuses[courseStatus]">{{('enum.courseStatuses.' + courseStatus) | translate}}</option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-12 col-md-6 formGroup">
                  <label>{{'customerName' | translate}}</label>
                  <input formControlName="customerName"/>
                </div>

                <div class="col-12 col-md-6 formGroup">
                  <label>{{'customerSurname' | translate}}</label>
                  <input formControlName="customerSurname"/>
                </div>
              </div>
            </div>

            <div class="col-12 buttonActions">
              <button type="button" class="basicButton basicButton__greenLight" (click)="resetFilter()">{{'button.reset' | translate}}</button>
              <button type="submit" class="basicButton basicButton__greenDark">{{'button.filter' | translate}}</button>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="col-12">
    <app-list-with-loader [downloadedList]="downloadedList" [listCounter]="list.length">
      <div class="row">
        <div class="col-12">
          <table class="spacingTable">
            <thead>
            <tr>
              <th></th>
              <th class="cursorPointer" (click)="sortBy.columnClicked('name'); download();">{{'name' | translate}} {{sortBy.column === 'name' ? (sortBy.order === 'ASC' ? '↑' : '↓') : ''}}</th>
              <th>{{'courseForm' | translate}}</th>
              <th>{{'customer' | translate}}</th>
              <th>{{'address.city' | translate}}</th>
              <th class="cursorPointer" (click)="sortBy.columnClicked('startDate'); download();">{{'dates' | translate}} {{sortBy.column === 'startDate' ? (sortBy.order === 'ASC' ? '↑' : '↓') : ''}}</th>
              <th>{{'trainers' | translate}}</th>
              <th>{{'individualParticipantAmount' | translate}}</th>
              <th>{{'placementAmount' | translate}}</th>
              <th>{{'placementPredictedParticipantsAmount' | translate}}</th>
              <th>{{'allPredictedParticipantsAmount' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let course of list">
              <!--                            <tr class="cursorPointer" [ngClass]="{'courseClosed': ifTypeClosed(course.type), 'courseOpen': ifTypeOpen(course.type)}" [routerLink]="[linkToDetail, course.id]">-->
              <tr class="cursorPointer" [ngClass]="{'blueLight': ifTypeClosed(course.type), 'newOne': ifTypeOpen(course.type), 'rejected': ifStatusRejected(course.courseStatus)}" [routerLink]="[linkToDetail, course.id]">
                <td class="openNewTab">
                  <button class="basicButton basicButton__transparent basicButton__icon basicButton__icon--openNewTab" (click)="openNewTab(course.id); $event.stopPropagation();"></button>
                </td>
                <td [innerHTML]="course.name"></td>
                <td>{{('enum.formTypes.' + (course.form | enumNameById:'formTypes')) | translate}}</td>
                <td [innerHTML]="course.properties?.sourceRegistrationEntity?.addressData?.name"></td>
                <td>{{course.properties?.sourceRegistrationEntity?.addressData?.city}}</td>
                <td>
                  <ng-container *ngFor="let date of course.dates;">
                    {{date.date}} {{date.startHour}} - {{date.endHour}}
                    <br>
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngFor="let date of course.dates;">
                    <ng-container *ngFor="let trainerId of date.trainers; let ifLastTrainer = last;">
                      {{getTrainerInfo(trainerId)}}{{!ifLastTrainer ? ', ' : ''}}
                    </ng-container>
                    <br>
                  </ng-container>
                </td>
                <td>{{course.actualIndividualParticipantsAmount}}</td>
                <td>{{course.actualPlacementAmount}}</td>
                <td>{{course.actualIndividualFromPlacementParticipantsAmount}}</td>
                <td>{{course.actualParticipantAmount}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

        <div class="col-12">
          <app-paginator [currentPage]="filterForm.get('page')?.value" [rows]="20" [totalRecords]="count" (changePageEvent)="changePage($event); download(false);"></app-paginator>
        </div>
      </div>
    </app-list-with-loader>
  </div>
</div>
