import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {UserInterface} from "../interfaces/user.interface";
import {SuperUserPrivilege} from "../enums/permissions.enum";

@Injectable({
    providedIn: 'root'
})
export class LoggedUserService {
    private _data!: UserInterface;
    private data$: Subject<void> = new Subject<void>();

    constructor() {
    }

    public set data(_data: UserInterface) {
        this._data = _data;
        this.data$.next();
    }

    public get data(): UserInterface {
        return this._data || {privileges: []};
    }

    public dataChanged(): Observable<void> {
        return this.data$.asObservable();
    }

    public ifSuperUser(): boolean {
        return !!this.data.privileges.find((privilege: string) => privilege === SuperUserPrivilege);
    }

    public ifUserHasPrivileges(privileges: string[]): boolean {
        if (this.ifSuperUser()) return true;
        for (let privilege of privileges) if (!this.ifUserHasPrivilege(privilege)) return false;
        return true;
    }

    public ifUserHasPrivilege(privilege: string): boolean {
        if (this.ifSuperUser()) return true;
        return !!this.data.privileges.find((userPrivilege: string) => userPrivilege === privilege);
    }
}
