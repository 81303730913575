<app-modal #chooseCustomerModal [title]="'chooseCustomer' | translate">
    <div class="row">
        <div class="col-12 basicForm__form">
            <div class="row basicForm__columns">
                <div class="col-12 basicForm__firstColumn">
                    <div class="row">
                        <div class="col-12 col-md-6 formGroup">
                            <label>{{'firstName' | translate}}</label>
                            <input [(ngModel)]="search.name" (ngModelChange)="filter()" />
                        </div>

                        <div class="col-12 col-md-6 formGroup">
                            <label>{{'lastName' | translate}}</label>
                            <input [(ngModel)]="search.surname" (ngModelChange)="filter()" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <table class="spacingTable spacingTable--smallerRow">
                                <thead>
                                <tr>
                                    <th>{{'firstName' | translate}} / {{'name' | translate}}</th>
                                    <th>{{'lastName' | translate}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let item of list">
                                    <tr class="cursorPointer" (click)="chooseItem(item)" [ngClass]="{'newOne': chosen?.id === item.id}">
                                        <td>{{item.name}}</td>
                                        <td>{{item.surname}}</td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row modalFooter">
        <div class="col-12">
            <button class="basicButton basicButton__greenDark" type="button" (click)="close()">{{('button.' + (!!chosen ? 'choose' : 'cancel')) | translate}}</button>
        </div>
    </div>
</app-modal>
