import {Component, Input, OnInit} from '@angular/core';
import {CoursesRegistrationClosedService} from '../../pages/courses/courses-registration-closed/courses-registration-closed.service';
import {ApplicationFormCloseCourseRegistrationExport, ApplicationFormCloseCourseRegistrationInterface} from '../../interfaces/application-form.interface';
import {FormBuilder, FormControl} from '@angular/forms';
import {AppLinksService} from '../../services/app-links.service';
import {HttpParams} from '@angular/common/http';
import {CourseRegistrationListFilterFormInterface} from '../../interfaces/course.interface';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../services/utils.service';
import {ExcelService} from '../../services/excel.service';
import {CourseTemplatesByIdPipe} from '../../pipes/course-templates-by-id.pipe';
import {DictionaryCourseTemplatesService} from '../../pages/dictionary/dictionary-course-templates/dictionary-course-templates.service';
import * as moment from 'moment';
import {HandleLastFilters} from '../../classes/handle-last-filters.class';
import {TranslatePipe} from '../../pipes/translate.pipe';
import {TranslateService} from '../../services/translate.service';
import {EnumNameByIdPipe} from '../../pipes/enum-name-by-id.pipe';
import {ListType} from '../../enums/list-type.enum';
import * as _ from 'underscore';
import {tap} from "rxjs";
import {CustomersDetailExportService} from "../../pages/customers/customers-detail-export.service";

@Component({
  selector: 'app-courses-registration-closed-list',
  templateUrl: './courses-registration-closed-list.component.html',
  styleUrls: ['./courses-registration-closed-list.component.scss']
})
export class CoursesRegistrationClosedListComponent extends HandleLastFilters<CoursesRegistrationClosedService, ApplicationFormCloseCourseRegistrationInterface, CourseRegistrationListFilterFormInterface> implements OnInit {
  @Input() public type!: ListType;

  public courseTemplateById: CourseTemplatesByIdPipe;
  public enumNameById: EnumNameByIdPipe;
  public translatePipe: TranslatePipe;
  public customerDetail: string = AppLinksService.customersDetail;

  public exportAll$ = this.customersDetailExportService.exportAll$.pipe(tap(() => this.exportList()));

  constructor(private coursesRegistrationClosedService: CoursesRegistrationClosedService,
              private formBuilder: FormBuilder,
              public activatedRoute: ActivatedRoute,
              private excelService: ExcelService,
              private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
              private translateService: TranslateService,
              private customersDetailExportService: CustomersDetailExportService) {
    super(coursesRegistrationClosedService, 'courseRegistrationClosedFilters', 'courseRegistrationClosedSortOrder', 'createdAt', AppLinksService.coursesRegistrationClosedDetail);
    this.courseTemplateById = new CourseTemplatesByIdPipe(dictionaryCourseTemplatesService);
    this.translatePipe = new TranslatePipe(translateService);
    this.enumNameById = new EnumNameByIdPipe();
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.createFilterForm();
    this.download();
  }

  public download(): void {
    this.downloadFilteredList(this.getParams());
    this.filterBoxFlag = false;
  }

  public toResetOneValue(prop: string): void {
    this.resetOneValue(prop);
    this.download();
  }

  public createFilterForm(): void {
    const filter: CourseRegistrationListFilterFormInterface = this.getFilter();
    this.filterForm = this.formBuilder.group({
      page: new FormControl(filter.page | 0),
      customerName: new FormControl(filter.customerName),
      customerSurname: new FormControl(filter.customerSurname),
      customerCompanyName: new FormControl(filter.customerCompanyName),
      companyCity: new FormControl(filter.companyCity),
      dateFrom: new FormControl(filter.dateFrom),
      dateTo: new FormControl(filter.dateTo),
      courseName: new FormControl(filter.courseName),
      accepted: new FormControl(this.type === ListType.GLOBAL ? (filter.accepted || false) : null),
      rejected: new FormControl(this.type === ListType.GLOBAL ? (filter.rejected || false) : null),
    });
  }

  public resetFilter(): void {
    this.filterForm.get('page')?.setValue(0);
    this.filterForm.get('customerName')?.reset();
    this.filterForm.get('customerSurname')?.reset();
    this.filterForm.get('customerCompanyName')?.reset();
    this.filterForm.get('companyCity')?.reset();
    this.filterForm.get('dateFrom')?.reset();
    this.filterForm.get('dateTo')?.reset();
    this.filterForm.get('courseName')?.reset();
    this.filterForm.get('accepted')?.reset();
    if (this.type === ListType.GLOBAL) this.filterForm.get('accepted')?.setValue(false);
    this.filterForm.get('rejected')?.reset();
    if (this.type === ListType.GLOBAL) this.filterForm.get('rejected')?.setValue(false);
    this.download();
  }

  private getParams(): HttpParams {
    const filterForm: CourseRegistrationListFilterFormInterface = this.filterForm.value;
    let params: HttpParams = new HttpParams().set('page', filterForm.page);
    if (_.isBoolean(filterForm.accepted)) params = params.set('accepted', filterForm.accepted);
    if (_.isBoolean(filterForm.rejected)) params = params.set('rejected', filterForm.rejected);
    if (!!filterForm.customerName) params = params.set('customerName', filterForm.customerName);
    if (!!filterForm.customerSurname) params = params.set('customerSurname', filterForm.customerSurname);
    if (!!filterForm.customerCompanyName) params = params.set('customerCompanyName', filterForm.customerCompanyName);
    if (!!filterForm.companyCity) params = params.set('companyCity', filterForm.companyCity);
    if (!!filterForm.dateFrom) params = params.set('dateFrom', String(filterForm.dateFrom));
    if (!!filterForm.dateTo) params = params.set('dateTo', String(filterForm.dateTo));
    if (!!filterForm.courseName) params = params.set('courseName', filterForm.courseName);
    if (UtilsService.ifGlobalListCustomer(this.type)) params = params.set('customerId', this.activatedRoute.snapshot.params['id']);
    params = params.set('dateSort', this.sortBy.order);
    this.setFilters();

    return params;
  }

  public ifGlobalListGlobal(): boolean {
    return UtilsService.ifGlobalListGlobal(this.type);
  }

  public exportList(): void {
    this.downloadFilteredListForExport(this.getParams()).then((list: ApplicationFormCloseCourseRegistrationInterface[]) => {
      let exportList: ApplicationFormCloseCourseRegistrationExport[] = list.map((registration: ApplicationFormCloseCourseRegistrationInterface) => {
        return {
          exportCourseName: this.courseTemplateById.transform(registration.courseId)?.name,
          exportCustomerName: registration.addressData?.name,
          exportCustomerCity: registration.addressData?.city,
          exportPredictedDates: (!!registration.predictedTimeOfCourseFrom && !!registration.predictedTimeOfCourseTo) ? `${registration.predictedTimeOfCourseFrom} - ${registration.predictedTimeOfCourseTo}` : '',
          exportPredictedForm: (!!registration.form || registration.form === 0) ? this.translatePipe.transform('enum.formTypes.' + this.enumNameById.transform(registration.form, 'formTypes')) : '',
          exportCreatedAt: moment(registration.createdAt).format('DD.MM.YYYY HH:mm:ss'),
          note: registration.note
        }
      });
      this.excelService.save<ApplicationFormCloseCourseRegistrationExport>(exportList, ['Klient', 'Miejscowość', 'Nazwa szkolenia', 'Notatka', 'Preferowana forma szkolenia', 'Preferowany zakres dat', 'Trener', 'Utworzono'], ['exportCustomerName', 'exportCustomerCity', 'exportCourseName', 'note', 'exportPredictedForm', 'exportPredictedDates', '', 'exportCreatedAt'], 'Zgłoszenia na szkolenia zamknięte', 'Lista zgłoszeń na szkolenia zamknięte');
    });
  }

  public openNewTab(courseId: string): void {
    UtilsService.openNewTab(`${this.linkToDetail}/${courseId}`);
  }
}
