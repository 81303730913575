import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {AnyInterface} from "../interfaces/global.interface";

const emailRegexp: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function EmailValidate(emailProp: string) {
    return (group: FormGroup): AnyInterface<boolean> | undefined => {
        if (!emailValidateLogic(group.controls[emailProp].value)) {
            return {
                emailNotValidate: true
            };
        }
    };
}

export function EmailControlValidate(email: AbstractControl) {
    if (!!email.value && !emailValidateLogic(email.value)) return {notValidate: true};
    return null;
}

export function emailValidateLogic(email: string): boolean {
    return emailRegexp.test(String(email).toLowerCase());
}
