import {Component, Input, OnInit} from '@angular/core';
import {SubscriptionsService} from '../../pages/subscriptions/subscriptions.service';
import {SubscriptionExport, SubscriptionFilterForm, SubscriptionInterface} from '../../interfaces/subscription.interface';
import {AppLinksService} from '../../services/app-links.service';
import {HttpParams} from '@angular/common/http';
import {SortBy} from '../../classes/sort-by.class';
import {FormBuilder, FormControl} from '@angular/forms';
import {SubscriptionStatusesEnum} from '../../enums/subscription-statuses.enum';
import {FilteredListInterface} from '../../interfaces/global.interface';
import {ExcelService} from '../../services/excel.service';
import {HandleLastFilters} from '../../classes/handle-last-filters.class';
import {CustomersDetailExportService} from "../../pages/customers/customers-detail-export.service";
import {tap} from "rxjs";

@Component({
  selector: 'app-subscriptions-registration-list-model',
  templateUrl: './subscriptions-registration-list-model.component.html',
  styleUrls: ['./subscriptions-registration-list-model.component.scss']
})
export class SubscriptionsRegistrationListModelComponent extends HandleLastFilters<SubscriptionsService, SubscriptionInterface, SubscriptionFilterForm> implements OnInit {
  @Input() public initFilterData: SubscriptionFilterForm = <SubscriptionFilterForm>{};
  @Input() public ifMainList: boolean = false;
  @Input() public type!: 'accepted' | 'registrations';

  public sortBy: SortBy;
  public subscriptionStatuses = SubscriptionStatusesEnum;

  public exportAll$ = this.customersDetailExportService.exportAll$.pipe(tap(() => this.exportList()));

  constructor(private subscriptionsService: SubscriptionsService,
              private formBuilder: FormBuilder,
              private excelService: ExcelService,
              private customersDetailExportService: CustomersDetailExportService) {
    super(subscriptionsService, 'subscriptionFilters', 'subscriptionSortOrder', 'createdAt', AppLinksService.subscriptionRegistrationDetail);
    this.sortBy = new SortBy('DESC');
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.createFilterForm();
    this.download();
  }

  public download(): void {
    this.downloadFilteredList(this.getParams());
    this.filterBoxFlag = false;
  }

  private getParams(): HttpParams {
    const filterForm: SubscriptionFilterForm = this.filterForm.value;
    let params: HttpParams = new HttpParams().set('page', filterForm.page).set('accepted', filterForm.accepted).set('rejected', filterForm.rejected);
    if (!!filterForm.customerId) params = params.set('customerId', filterForm.customerId);
    if (!!filterForm.name) params = params.set('name', filterForm.name);
    if (!!filterForm.placementName) params = params.set('placementName', filterForm.placementName);
    if (!!filterForm.placementCity) params = params.set('placementCity', filterForm.placementCity);
    if (!!filterForm.createdAtFrom) params = params.set('createdAtFrom', String(filterForm.createdAtFrom));
    if (!!filterForm.createdAtTo) params = params.set('createdAtTo', String(filterForm.createdAtTo));
    if (!!filterForm.endDateFrom) params = params.set('endDateFrom', String(filterForm.endDateFrom));
    if (!!filterForm.endDateTo) params = params.set('endDateTo', String(filterForm.endDateTo));
    if (!!filterForm.subscriptionStatus) params = params.set('subscriptionStatus', String(filterForm.subscriptionStatus));
    params = params.set('sortBy', this.sortBy.column);
    params = params.set('sortType', this.sortBy.order);
    if (this.ifMainList) this.setFilters();

    return params;
  }

  public createFilterForm(): void {
    if (this.ifMainList) this.initFilterData = {...this.getFilter()};
    this.filterForm = this.formBuilder.group({
      page: new FormControl(0),
      customerId: new FormControl(this.initFilterData.customerId),
      accepted: new FormControl(this.initFilterData.accepted || (this.type === 'accepted')),
      rejected: new FormControl(this.initFilterData.rejected || false),
      all: new FormControl(this.initFilterData.all),
      name: new FormControl(this.initFilterData.name),
      placementName: new FormControl(this.initFilterData.placementName),
      placementCity: new FormControl(this.initFilterData.placementCity),
      createdAtFrom: new FormControl(this.initFilterData.createdAtFrom),
      createdAtTo: new FormControl(this.initFilterData.createdAtTo),
      endDateFrom: new FormControl(this.initFilterData.endDateFrom),
      endDateTo: new FormControl(this.initFilterData.endDateTo),
      subscriptionStatus: new FormControl(this.initFilterData.subscriptionStatus)
    });
  }

  public resetFilter(): void {
    this.filterForm.reset();
    this.filterForm.get('accepted')?.setValue(this.initFilterData.accepted);
    this.filterForm.get('rejected')?.setValue(this.initFilterData.rejected);
    this.filterForm.get('customerId')?.setValue(this.initFilterData.customerId);
    this.filterForm.get('page')?.setValue(0);
  }

  public ifAccepted(): boolean {
    return this.type === 'accepted';
  }

  public ifRegistrations(): boolean {
    return this.type === 'registrations';
  }

  public exportList(): void {
    this.subscriptionsService.downloadFilteredList(this.getParams().set('all', String(true))).subscribe((list: FilteredListInterface<SubscriptionInterface>) => {
      this.excelService.save<SubscriptionExport>(this.subscriptionsService.prepareSubscriptionsToExport(list.items), ['Nazwa', 'Status', 'Nazwa placówki', 'Miejscowość', 'Nr faktury', 'Wartość faktury', 'Wartość', 'Wykorzystane środki', 'Pozostałe środki', 'Pozostałe bonusy', 'Data zakończenia', 'Dane kontaktowe', 'Notatka', 'Planowane szkolenia otwarte', 'Planowane szkolenia zamknięte'], ['name', 'status', 'placementName', 'placementCity', 'invoiceNumber', 'invoiceValue', 'value', 'usedValue', 'currentValue', 'leftBonusPlaces', 'endDate', 'contactData', 'note', 'openCourses', 'closedCourses'], 'Abonamenty', 'Abonamenty');
    });
  }
}
