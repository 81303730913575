export enum CourseStatusesEnum {
    PLANNED = 'planned',
    IN_PROGRESS = 'in_progress',
    REALISED = 'realised',
    REJECTED = 'rejected'
}

export const AllCourseStatuses = [
    CourseStatusesEnum.PLANNED,
    CourseStatusesEnum.IN_PROGRESS,
    CourseStatusesEnum.REALISED,
    CourseStatusesEnum.REJECTED
]
