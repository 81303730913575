import {Pipe, PipeTransform} from '@angular/core';
import {DictionaryFunctionInterface} from '../interfaces/dictionary.interface';
import {DictionaryFunctionsService} from '../pages/dictionary/dictionary-functions/dictionary-functions.service';

@Pipe({
    name: 'functionById'
})
export class FunctionByIdPipe implements PipeTransform {

    constructor(private dictionaryFunctionsService: DictionaryFunctionsService) {
    }

    transform(id: string | undefined): DictionaryFunctionInterface {
        return this.dictionaryFunctionsService.data.find((func: DictionaryFunctionInterface) => func.id === id) || <DictionaryFunctionInterface>{};
    }

}
