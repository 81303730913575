import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

type BackgroundType = 'greenLight';

@Component({
    selector: 'app-box-with-background',
    templateUrl: './box-with-background.component.html',
    styleUrls: ['./box-with-background.component.scss']
})
export class BoxWithBackgroundComponent implements OnInit {
    @Input() public background: BackgroundType = 'greenLight';
    @Input() public ifCollapse: boolean = false;
    @Input() public collapsed: boolean = true;
    @Output() private binClicked: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit(): void {
    }

    public handleCollapsed(): void {
        this.collapsed = !this.collapsed;
    }

    public binClick(): void {
        this.binClicked.next();
    }
}
