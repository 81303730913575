import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictionaryLocationInterface} from "../../../interfaces/dictionary.interface";
import {environment} from "../../../../environments/environment";
import {BasicServiceInterface, FilteredListInterface} from "../../../interfaces/global.interface";
import {Params} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DictionaryLocationsService implements BasicServiceInterface<DictionaryLocationInterface> {


    constructor(private http: HttpClient) {
    }

    public downloadList(): Observable<DictionaryLocationInterface[]> {
        return this.http.get<DictionaryLocationInterface[]>(environment.apiPath + 'localizations');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<DictionaryLocationInterface>> {
        return this.http.get<FilteredListInterface<DictionaryLocationInterface>>(environment.apiPath + 'localizations', {params});
    }

    public downloadOne(id: string): Observable<DictionaryLocationInterface> {
        return this.http.get<DictionaryLocationInterface>(environment.apiPath + 'localizations/' + id);
    }

    public saveOne(data: DictionaryLocationInterface): Observable<DictionaryLocationInterface> {
        return this.http.post<DictionaryLocationInterface>(environment.apiPath + 'localizations', data);
    }

    public updateOne(data: DictionaryLocationInterface, dataId: string): Observable<DictionaryLocationInterface> {
        return this.http.put<DictionaryLocationInterface>(environment.apiPath + 'localizations/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'localizations/' + dataId);
    }

    public static prepareDTO(data: DictionaryLocationInterface): DictionaryLocationInterface {
        data.state = +data.state;

        return data;
    }
}
