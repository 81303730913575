import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CoursesRegistrationOpenService} from '../../pages/courses/courses-registration-open/courses-registration-open.service';
import {ApplicationFormOpenCourseRegistrationExport, ApplicationFormOpenCourseRegistrationInterface} from '../../interfaces/application-form.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl} from '@angular/forms';
import {AppLinksService} from '../../services/app-links.service';
import {HttpParams} from '@angular/common/http';
import {CourseDateInterface, CourseRegistrationListFilterFormInterface} from '../../interfaces/course.interface';
import {UtilsService} from '../../services/utils.service';
import {CustomerTypesEnum} from '../../enums/customer-types.enum';
import {ToastService} from '../../services/toast.service';
import {CoursesDetailEditSourceRegistrationOpenService} from 'src/app/pages/courses/courses-detail/courses-detail-edit-source-registration-open/courses-detail-edit-source-registration-open.service';
import {Subscription, tap} from 'rxjs';
import {HandleLastFilters} from '../../classes/handle-last-filters.class';
import * as moment from 'moment';
import {ExcelService} from '../../services/excel.service';
import {ListType} from '../../enums/list-type.enum';
import * as _ from 'underscore';
import {CustomersDetailExportService} from "../../pages/customers/customers-detail-export.service";

@Component({
  selector: 'app-courses-registration-open-list',
  templateUrl: './courses-registration-open-list.component.html',
  styleUrls: ['./courses-registration-open-list.component.scss']
})
export class CoursesRegistrationOpenListComponent extends HandleLastFilters<CoursesRegistrationOpenService, ApplicationFormOpenCourseRegistrationInterface, CourseRegistrationListFilterFormInterface> implements OnInit, OnDestroy {
  @Input() public type!: ListType;
  @Input() public customerId?: string;

  public customerTypeIndividual: number = CustomerTypesEnum.INDIVIDUAL;
  public customerTypeEstablishment: number = CustomerTypesEnum.ESTABLISHMENT;
  public customerTypes = CustomerTypesEnum;
  private subscriptions: Subscription[] = [];
  public customerDetail: string = AppLinksService.customersDetail;

  public exportAll$ = this.customersDetailExportService.exportAll$.pipe(tap(() => this.exportList()));

  constructor(private coursesRegistrationOpenService: CoursesRegistrationOpenService,
              private router: Router,
              private formBuilder: FormBuilder,
              public activatedRoute: ActivatedRoute,
              private toastService: ToastService,
              private coursesDetailEditSourceRegistrationOpenService: CoursesDetailEditSourceRegistrationOpenService,
              private excelService: ExcelService,
              private customersDetailExportService: CustomersDetailExportService) {
    super(coursesRegistrationOpenService, 'courseRegistrationOpenFilters', 'courseRegistrationOpenSortOrder', 'dateSort', AppLinksService.coursesDetail);
    this.coursesDetailEditSourceRegistrationOpenChanged();
  }

  private coursesDetailEditSourceRegistrationOpenChanged(): void {
    this.subscriptions.push(this.coursesDetailEditSourceRegistrationOpenService.hideModalChanged().subscribe((courseRegistration: ApplicationFormOpenCourseRegistrationInterface) => {
      if (!!courseRegistration) this.download();
    }));
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    UtilsService.unsubscribeAll(this.subscriptions);
  }

  private init(): void {
    this.createFilterForm();
    this.download();
  }

  public download(): void {
    this.downloadFilteredList(this.getParams()).then(() => this.list.forEach((record: ApplicationFormOpenCourseRegistrationInterface) => record.courseDatesLabel = CoursesRegistrationOpenListComponent.getCourseDateLabel(record.courseDates, '<br>')));
    this.filterBoxFlag = false;
  }

  private static getCourseDateLabel(dates: CourseDateInterface[], join: string): string {
    return `${(dates || []).map((date: CourseDateInterface) => (!!date.date && !!date.startHour && !!date.endHour) ? `${moment(date.date).format('DD.MM.YYYY')} ${date.startHour} - ${date.endHour}` : '').join(join)}`;
  }

  public toResetOneValue(prop: string): void {
    this.resetOneValue(prop);
    this.download();
  }

  public createFilterForm(): void {
    const filter: CourseRegistrationListFilterFormInterface = this.getFilter();
    this.filterForm = this.formBuilder.group({
      page: new FormControl(filter.page | 0),
      customerName: new FormControl(filter.customerName),
      customerSurname: new FormControl(filter.customerSurname),
      customerCompanyName: new FormControl(filter.customerCompanyName),
      companyCity: new FormControl(filter.companyCity),
      dateFrom: new FormControl(filter.dateFrom),
      dateTo: new FormControl(filter.dateTo),
      customerType: new FormControl(filter.customerType),
      courseName: new FormControl(filter.courseName),
      phone: new FormControl(filter.phone),
      seen: new FormControl(this.type === ListType.GLOBAL ? (filter.seen || false) : null),
    });
  }

  public resetFilter(): void {
    this.filterForm.get('page')?.setValue(0);
    this.filterForm.get('customerName')?.reset();
    this.filterForm.get('customerSurname')?.reset();
    this.filterForm.get('customerCompanyName')?.reset();
    this.filterForm.get('companyCity')?.reset();
    this.filterForm.get('dateFrom')?.reset();
    this.filterForm.get('dateTo')?.reset();
    this.filterForm.get('customerType')?.reset();
    this.filterForm.get('courseName')?.reset();
    this.filterForm.get('phone')?.reset();
    this.filterForm.get('seen')?.reset();
    if (this.type === ListType.GLOBAL) this.filterForm.get('seen')?.setValue(false);
    this.download();
  }

  private getParams(): HttpParams {
    const filterForm: CourseRegistrationListFilterFormInterface = this.filterForm.value;
    let params: HttpParams = new HttpParams().set('page', filterForm.page);
    if (_.isBoolean(filterForm.seen)) params = params.set('seen', filterForm.seen);
    if (!!filterForm.customerName) params = params.set('customerName', filterForm.customerName);
    if (!!filterForm.customerSurname) params = params.set('customerSurname', filterForm.customerSurname);
    if (!!filterForm.customerCompanyName) params = params.set('customerCompanyName', filterForm.customerCompanyName);
    if (!!filterForm.companyCity) params = params.set('companyCity', filterForm.companyCity);
    if (!!filterForm.dateFrom) params = params.set('dateFrom', String(filterForm.dateFrom));
    if (!!filterForm.dateTo) params = params.set('dateTo', String(filterForm.dateTo));
    if (!!filterForm.courseName) params = params.set('courseName', filterForm.courseName);
    if (!!filterForm.phone) params = params.set('phone', filterForm.phone);
    if (filterForm.customerType === CustomerTypesEnum.INDIVIDUAL || filterForm.customerType === CustomerTypesEnum.ESTABLISHMENT) params = params.set('customerType', String(filterForm.customerType));
    if (UtilsService.ifGlobalListCustomer(this.type)) params = params.set('customerId', this.activatedRoute.snapshot.params['id']);
    params = params.set(this.sortBy.column, this.sortBy.order);
    this.setFilters();

    return params;
  }

  public setAsSeen(id: string): void {
    this.coursesRegistrationOpenService.seen(id).subscribe(() => {
      this.toastService.translateText('success', 'success.seenCourseRegistrationOpen');
      this.download();
    });
  }

  public edit(courseRegistration: ApplicationFormOpenCourseRegistrationInterface): void {
    if (!courseRegistration.customerType) courseRegistration.customerType = CustomerTypesEnum.INDIVIDUAL;
    this.coursesDetailEditSourceRegistrationOpenService.showModal(courseRegistration);
  }

  public ifGlobalListGlobal(): boolean {
    return UtilsService.ifGlobalListGlobal(this.type);
  }

  public openNewTab(courseId: string, customerId: string): void {
    UtilsService.openNewTab(`${this.linkToDetail}/${courseId}?customerId=${customerId}`);
  }

  public exportList(): void {
    this.downloadFilteredListForExport(this.getParams()).then((list: ApplicationFormOpenCourseRegistrationInterface[]) => {
      let exportList: ApplicationFormOpenCourseRegistrationExport[] = list.map((registration: ApplicationFormOpenCourseRegistrationInterface) => {
        return {
          type: 'Zgłoszenie otwarte',
          surname: registration.participantData.lastName,
          name: registration.participantData.firstName,
          placementName: registration.workPlace.name,
          placementCity: registration.workPlace.city,
          courseDate: CoursesRegistrationOpenListComponent.getCourseDateLabel(registration.courseDates, ', '),
          courseName: registration.courseName,
          createdAt: moment(registration.createdAt).format('DD.MM.YYYY HH:mm:ss')
        }
      });
      this.excelService.save<ApplicationFormOpenCourseRegistrationExport>(exportList, ['Typ', 'Nazwisko', 'Imię', 'Nazwa placówki', 'Miejscowość placówki', 'Data szkolenia', 'Nazwa szkolenia', 'Data zgłoszenia'], ['type', 'surname', 'name', 'placementName', 'placementCity', 'courseDate', 'courseName', 'createdAt'], 'Zgłoszenia na szkolenia otwarte', 'Lista zgłoszeń na szkolenia otwarte');
    });
  }
}
