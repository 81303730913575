<app-loader-wrapper *ngIf="!!form" [ifLoaded]="!!form">
    <div class="row">
        <div class="col-12 basicForm__form">
            <form [formGroup]="form">
                <div class="row basicForm__columns">
                    <div class="col-12 col-xl-5 basicForm__firstColumn">
                        <div class="row">
                            <div class="col-12 order-0">
                                <div class="row">
                                    <div class="col-12 formGroup formGroup--green" [ngClass]="{'error': ifError('courseTemplateId')}" *ngIf="ifApplicationFormTypeCustomers()">
                                        <label class="formGroup--bold">{{'courseName' | translate}} <i class="required">*</i></label>
                                        <select formControlName="courseTemplateId" (change)="courseTemplateChanged()">
                                            <option [ngValue]="null" disabled>{{'selectChoose.course' | translate}}</option>
                                            <ng-container *ngFor="let course of statics.courses">
                                                <option [ngValue]="course.templateId">{{course.name}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 formGroup formGroup--green" [ngClass]="{'error': ifError('activeCourseId')}" *ngIf="ifApplicationFormTypeCustomers()">
                                        <label class="formGroup--bold">{{'date&locationRealization' | translate}} <i class="required">*</i></label>
                                        <select formControlName="activeCourseId" (change)="checkIfAssignAsParticipant(); prepareFilesToDownload();">
                                            <option [ngValue]="null" disabled>{{'selectChoose.date&locationRealization' | translate}}</option>
                                            <ng-container *ngFor="let realization of getChosenCourse()?.realizations">
                                                <option [ngValue]="realization.id">{{realization.displayLabel}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>

                                <div class="row mt-1 mb-2" *ngIf="!!activeCourseRealization && !activeCourseRealization.showInCalendar">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="applicationCourseFormOpenCourseOrganizer" formControlName="courseOrganizer">
                                            <label class="custom-control-label" for="applicationCourseFormOpenCourseOrganizer">{{'courseOrganizerCheckbox' | translate}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="!form.get('courseOrganizer')?.value">
                                    <div class="col-12 formGroup formGroup--green" [ngClass]="{'error': ifError('customerType')}">
                                        <label class="formGroup--bold">
                                            {{'applicationCourseOpenCustomerType' | translate}} <i class="required">*</i>
                                            <span class="formGroup--subText">(indywidualnie lub rada pedagogiczna)</span>
                                        </label>
                                        <select formControlName="customerType" (change)="checkIfAssignAsParticipant()">
                                            <option [ngValue]="null" disabled>{{'selectChoose.applicationCourseOpenCustomerType' | translate}}</option>
                                            <option [ngValue]="statics.individualCustomer">{{'button.submitIndividualCustomer' | translate}}</option>
                                            <option [ngValue]="statics.establishmentCustomer">{{'button.submitPedagogicalCouncil' | translate}}</option>
                                        </select>
                                    </div>

                                    <div class="col-12 col-md-6 formGroup formGroup--green" *ngIf="ifCustomerTypeEstablishment()">
                                        <label class="formGroup--bold">{{'expectedPeopleAmount' | translate}}</label>
                                        <input type="number" formControlName="participantCount"/>
                                    </div>
                                </div>

                                <div class="row mt-1">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="applicationCourseFormOpenSubscription" formControlName="subscription">
                                            <label class="custom-control-label" for="applicationCourseFormOpenSubscription">{{'subscriptionCheckbox' | translate}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="applicationCourseFormOpenBonus" formControlName="bonus">
                                            <label class="custom-control-label" for="applicationCourseFormOpenBonus">{{'bonusCheckbox' | translate}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-3" [ngClass]="ifCustomerTypeEstablishment() ? 'order-2' : 'order-1'">
                                <app-box-with-header [header]="(ifCustomerTypeEstablishment()? 'contactDataResponsibleCourse' : 'courseParticipantData') | translate" [headerBolder]="true">
                                    <ng-container formGroupName="participantData">
                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('firstName')}">
                                                <label>{{'firstName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="firstName" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('lastName')}">
                                                <label>{{'lastName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="lastName" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('function')}">
                                                <label>{{'function' | translate}} <i class="required">*</i></label>
                                                <select formControlName="function">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let func of statics.functions">
                                                        <option [ngValue]="func.id">{{func.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('topic')}">
                                                <label>{{'subjectTaught' | translate}} <i class="required">*</i></label>
                                                <select formControlName="topic">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let subject of statics.subjects">
                                                        <option [ngValue]="subject.id">{{subject.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('email')}">
                                                <label>{{'email' | translate}} <i class="required">*</i></label>
                                                <input formControlName="email" trim="blur" (blur)="checkIfAssignAsParticipant()"/>
                                                <i>{{'error.email' | translate}}</i>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifParticipantDataError('phone')}">
                                                <label>{{'phoneParticipant' | translate}} <i class="required">*</i></label>
                                                <input formControlName="phone" appOnlyIntegers trim="blur"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>

                            <div class="col-12 mt-3" [ngClass]="ifCustomerTypeEstablishment() ? 'order-1' : 'order-2'">
                                <app-box-with-header [header]="(ifCustomerTypeEstablishment()? 'addressData' : 'placeOfEmployment') | translate" [headerBolder]="true">
                                    <ng-container formGroupName="workPlace">
                                        <!--                                        <div class="row">-->
                                        <!--                                            <div class="col-12 col-md-6 formGroup">-->
                                        <!--                                                <label>{{'institutionHash' | translate}}</label>-->
                                        <!--                                                <input formControlName="hash" (blur)="downloadCustomerByData('placementHash', 'hash')" trim="blur"/>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': ifWorkPlaceError('name')}">
                                                <label>{{'institutionName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="name" (blur)="downloadCustomerByData('name', 'name')" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifWorkPlaceError('type')}">
                                                <label>{{'dictionary.establishmentType' | translate}} <i class="required">*</i></label>
                                                <select formControlName="type">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let establishmentType of statics.establishmentTypes; let establishmentTypeIdx = index;">
                                                        <option [ngValue]="establishmentType.id">{{establishmentType.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifWorkPlaceError('email')}">
                                                <label>{{'emailEstablishment' | translate}} <i class="required">*</i></label>
                                                <input formControlName="email" trim="blur"/>
                                                <i>{{'error.email' | translate}}</i>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'phoneEstablishment' | translate}} <i class="required">*</i></label>
                                                <input formControlName="phone" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifWorkPlaceError('state')}">
                                                <label>{{'address.province' | translate}} <i class="required">*</i></label>
                                                <select formControlName="state">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let state of statics.states">
                                                        <option [ngValue]="state.id">{{state.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifWorkPlaceError('district')}">
                                                <label>{{'address.district' | translate}}</label>
                                                <input formControlName="district" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifWorkPlaceError('commune')}">
                                                <label>{{'address.community' | translate}}</label>
                                                <input formControlName="commune" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': ifWorkPlaceError('city')}">
                                                <label>{{'address.city' | translate}} <i class="required">*</i></label>
                                                <input formControlName="city" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifWorkPlaceError('postalCode')}">
                                                <label>{{'address.postalCode' | translate}} <i class="required">*</i></label>
                                                <input formControlName="postalCode" appPostalCodeMask trim="blur"/>
                                                <i class="error">{{'error.postalCode' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifWorkPlaceError('street')}">
                                                <label>{{'address.street' | translate}} <i class="required">*</i></label>
                                                <input formControlName="street" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup" [ngClass]="{'error': ifWorkPlaceError('buildingNumber')}">
                                                <label>{{'address.houseNo' | translate}} <i class="required">*</i></label>
                                                <input formControlName="buildingNumber" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber" trim="blur"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-5 basicForm__secondColumn">
                        <div class="row">
                            <div class="col-12">
                                <app-box-with-header header="{{'invoiceData' | translate}} - {{'buyer' | translate}}" [headerBolder]="true">
                                    <ng-container formGroupName="invoiceBuyerData">
                                        <div class="row">
                                            <div class="col-12 formGroup" [ngClass]="{'error': getBuyerFG().get('nip')?.invalid && getBuyerFG().get('nip')?.touched}">
                                                <label>{{'nip' | translate}}</label>
                                                <input formControlName="nip" appOnlyIntegers trim="blur"/>
                                                <i>{{'error.nip' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 formGroup">
                                                <label>{{'name' | translate}}</label>
                                                <input formControlName="name" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup">
                                                <label>{{'address.city' | translate}}</label>
                                                <input formControlName="city" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifInvoiceBuyerDataError('postalCode')}">
                                                <label>{{'address.postalCode' | translate}}</label>
                                                <input formControlName="postalCode" appPostalCodeMask trim="blur"/>
                                                <i class="error">{{'error.postalCode' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'address.street' | translate}}</label>
                                                <input formControlName="street" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.houseNo' | translate}}</label>
                                                <input formControlName="buildingNumber" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber" trim="blur"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>

                            <div class="col-12 mt-3">
                                <app-box-with-header header="{{'invoiceData' | translate}} - {{'recipient' | translate}}" [headerBolder]="true">
                                    <ng-container formGroupName="invoiceReceiverData">
                                        <div class="row">
                                            <div class="col-12 formGroup">
                                                <label>{{'name' | translate}}</label>
                                                <input formControlName="name" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup">
                                                <label>{{'address.city' | translate}}</label>
                                                <input formControlName="city" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifInvoiceRecipientError('postalCode')}">
                                                <label>{{'address.postalCode' | translate}}</label>
                                                <input formControlName="postalCode" appPostalCodeMask trim="blur"/>
                                                <i class="error">{{'error.postalCode' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'address.street' | translate}}</label>
                                                <input formControlName="street" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.houseNo' | translate}}</label>
                                                <input formControlName="buildingNumber" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber" trim="blur"/>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="row">
                                        <div class="col-12 formGroup" [ngClass]="{'error': form.get('email')?.invalid && form.get('email')?.touched}">
                                            <label>{{'emailToSendInvoice' | translate}}</label>
                                            <input formControlName="email" trim="blur"/>
                                            <i>{{'error.email' | translate}}</i>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 formGroup">
                                            <label>{{'customerNote' | translate}}</label>
                                            <textarea formControlName="customerNote" placeholder=""></textarea>
                                        </div>
                                    </div>
                                </app-box-with-header>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="filesToDownload.length > 0">
                        <div class="col-12 mt-3">
                            <app-box-with-header headerHTML="WAŻNE!<br> Proszę o POBRANIE FORMULARZA REZERWACYJNEGO dotyczącego rezerwacji miejsca w hotelu i wybranego pakietu, WYPEŁNIENIE, ZESKANOWANIE I ODESŁANIE na adres: <a href='mailto:abc@empiria.edu.pl'>abc@empiria.edu.pl</a>"
                                                 [headerBolder]="true" [headerBigger]="true" [headerRed]="true">
                                <div class="row">
                                    <div class="col-12">
                                        <label>Pliki można pobrać, klikając na ikonkę symbolizującą plik.</label>
                                    </div>

                                    <div class="col-12">
                                        <div class="fileWrapper__files fileWrapper__files--heightUnset mt-0">
                                            <ng-container *ngFor="let fileToDownload of filesToDownload; let fileToDownloadIdx = index;">
                                                <div class="attachFile" (click)="openFileToDownload(fileToDownloadIdx)">
                                                    <label class="attachFile__file attachFile__file--attached">
                                                        <img src="/assets/images/photo.svg" alt="Photo"/>
                                                    </label>
                                                    <label class="attachFile__fileName">Plik {{fileToDownloadIdx + 1}}</label>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <div class="custom-control custom-checkbox" [ngClass]="{'error': form.get('filesToDownloadAgreement')!.invalid && form.get('filesToDownloadAgreement')!.touched}">
                                            <input type="checkbox" class="custom-control-input" id="applicationCourseFormOpenFilesToDownloadAgreement" formControlName="filesToDownloadAgreement">
                                            <label class="custom-control-label" for="applicationCourseFormOpenFilesToDownloadAgreement">
                                                <i class="required">*</i>
                                                {{'filesToDownloadAgreement.declare' | translate}}
                                            </label>
                                            <i class="error">{{'error.agreement' | translate}}</i>
                                        </div>
                                    </div>
                                </div>
                            </app-box-with-header>
                        </div>
                    </ng-container>
                </div>
            </form>
        </div>
    </div>
</app-loader-wrapper>
