import {HandlerInterfaceType, HandlerServiceType} from "../types/dictionary.type";
import {BasicServiceInterface, FilteredListInterface} from "../interfaces/global.interface";
import {HttpParams} from '@angular/common/http';
import {FilterBoxHandler} from './filter-box-handler.class';

export abstract class ListHandler<S extends HandlerServiceType, I extends HandlerInterfaceType> extends FilterBoxHandler {
  public list: I[] = [];
  public count: number = 0;
  public downloadedList: boolean = false;

  protected constructor(public service: S,
                        public linkToDetail?: string) {
    super();
  }

  public downloadList(): Promise<void> {
    this.downloadedList = false;
    return new Promise<void>((resolve, reject) => {
      (<BasicServiceInterface<I>><unknown>this.service).downloadList().subscribe((list: I[]) => {
        this.list = <I[]>list;
        this.downloadedList = true;

        resolve();
      }, () => {
        reject();
      });
    });
  }

  public downloadFilteredList(params: HttpParams): Promise<void> {
    this.downloadedList = false;
    return new Promise<void>((resolve, reject) => {
      (<BasicServiceInterface<I>><unknown>this.service).downloadFilteredList(params).subscribe((list: FilteredListInterface<I>) => {
        this.list = <I[]>list.items;
        this.count = list.count;
        this.downloadedList = true;

        resolve();
      }, () => {
        reject();
      });
    });
  }

  public downloadPureFilteredList(params: HttpParams): Promise<void> {
    this.downloadedList = false;
    return new Promise<void>((resolve, reject) => {
      (<BasicServiceInterface<I>><unknown>this.service).downloadPureFilteredList!(params).subscribe((list: I[]) => {
        this.list = list;
        this.downloadedList = true;

        resolve();
      }, () => {
        reject();
      });
    });
  }

  public downloadFilteredListForExport(params: HttpParams): Promise<I[]> {
    return new Promise<I[]>((resolve, reject) => {
      (<BasicServiceInterface<I>><unknown>this.service).downloadFilteredList!(params.set('all', true)).subscribe((list: FilteredListInterface<I>) => {
        resolve(list.items);
      }, () => {
        reject();
      });
    });
  }

  public downloadFilteredListNoAction(params: HttpParams): Promise<I[]> {
    return new Promise<I[]>((resolve, reject) => {
      (<BasicServiceInterface<I>><unknown>this.service).downloadFilteredList(params).subscribe((list: FilteredListInterface<I>) => {
        resolve(list.items);
      }, () => {
        reject();
      });
    });
  }
}
