import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-box-with-header',
    templateUrl: './box-with-header.component.html',
    styleUrls: ['./box-with-header.component.scss']
})
export class BoxWithHeaderComponent implements OnInit {
    @Input() public header: string = '';
    @Input() public headerHTML: string = '';
    @Input() public headerRequired: boolean = false;
    @Input() public headerBigger: boolean = false;
    @Input() public headerBolder: boolean = false;
    @Input() public headerRed: boolean = false;
    @Input() public counter: number = 0;
    @Input() public ifCollapse: boolean = false;
    @Input() public collapsed: boolean = true;
    @Input() public contentStyleClass: string = '';

    constructor() {
    }

    ngOnInit(): void {
    }

    public handleCollapsed(): void {
        if (this.ifCollapse) this.collapsed = !this.collapsed;
    }
}
