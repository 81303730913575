<app-modal #modalLoaderModal [title]="title" [showCloseButton]="false">
  <div class="row">
    <div class="col-12" *ngIf="!!text">
      <p>{{text}}</p>
    </div>

    <div class="col-12 pb-4">
      <app-loader></app-loader>
    </div>
  </div>
</app-modal>
