import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WindowService {
    private windowSubject: BehaviorSubject<Window> = new BehaviorSubject<Window>(window);

    constructor() {
    }

    public changeWindow(): void {
        this.windowSubject.next(window);
    }

    public windowChanged(): Observable<Window> {
        return this.windowSubject.asObservable();
    }
}
