import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuTopViewActionService} from "./menu-top-view-action.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {MenuLeftStateService} from "../menu-left/menu-left-state.service";
import {LoggedUserService} from "../../../services/logged-user.service";
import {UserInterface} from "../../../interfaces/user.interface";
import {ToastService} from "../../../services/toast.service";
import {LogoutService} from "../../../services/logout.service";

@Component({
    selector: 'app-menu-top',
    templateUrl: './menu-top.component.html',
    styleUrls: ['./menu-top.component.scss']
})
export class MenuTopComponent implements OnInit, OnDestroy {
    public showViewAction!: boolean;
    public textViewAction!: string;
    public showMobileMenuFlag!: boolean;
    private subscriptions: Subscription[] = [];
    public loggedUser!: UserInterface;

    constructor(private menuTopViewActionService: MenuTopViewActionService,
                private menuLeftStateService: MenuLeftStateService,
                private loggedUserService: LoggedUserService,
                private toastService: ToastService,
                private logoutService: LogoutService) {
        this.showViewActionChanged();
        this.textViewActionChanged();
        this.menuMobileStateChanged();
        this.loggedUserDataChanged();
    }

    private menuMobileStateChanged(): void {
        this.subscriptions.push(this.menuLeftStateService.showMobileChanged().subscribe(() => {
            this.getShowMobile();
        }));
    }

    private showViewActionChanged(): void {
        this.subscriptions.push(this.menuTopViewActionService.showChanged().subscribe(() => {
            this.getShow();
        }));
    }

    private textViewActionChanged(): void {
        this.subscriptions.push(this.menuTopViewActionService.textChanged().subscribe(() => {
            this.getText();
        }));
    }

    private loggedUserDataChanged(): void {
        this.subscriptions.push(this.loggedUserService.dataChanged().subscribe(() => {
            this.getLoggedUser();
        }));
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    private init(): void {
        this.getShowMobile();
        this.getShow();
        this.getText();
        this.getLoggedUser();
    }

    public back(): void {
        this.menuTopViewActionService.back();
    }

    public showMobileMenu(): void {
        this.menuLeftStateService.showMobile = !this.menuLeftStateService.showMobile;
    }

    public logout(): void {
        this.logoutService.submit();
        this.toastService.translateText('success', 'success.logout');
    }

    private getShowMobile(): void {
        this.showMobileMenuFlag = this.menuLeftStateService.showMobile;
    }

    private getShow(): void {
        this.showViewAction = this.menuTopViewActionService.show;
    }

    private getText(): void {
        this.textViewAction = this.menuTopViewActionService.text;
    }

    private getLoggedUser(): void {
        this.loggedUser = this.loggedUserService.data;
    }
}
