import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuPositionInterface} from "../../../interfaces/menu.interface";
import {MenuPositionService} from "../../../services/menu-position.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../services/utils.service";
import {MenuLeftStateService} from "./menu-left-state.service";
import {AppLinksService} from "../../../services/app-links.service";
import {LoggedUserService} from '../../../services/logged-user.service';

@Component({
    selector: 'app-menu-left',
    templateUrl: './menu-left.component.html',
    styleUrls: ['./menu-left.component.scss']
})
export class MenuLeftComponent implements OnInit, OnDestroy {
    public menuPositions: MenuPositionInterface[] = [];
    public showMobileMenu!: boolean;
    private subscriptions: Subscription[] = [];
    public dashboardLink: string = AppLinksService.dashboard;
    public separatorId: number = MenuPositionService.separatorId;

    constructor(private menuPositionService: MenuPositionService,
                private router: Router,
                private menuLeftStateService: MenuLeftStateService,
                private loggedUserService: LoggedUserService) {
        this.loggedUserChanged();
        this.showMobileMenuChanged();
    }

    private loggedUserChanged(): void {
        this.getMenuPositions();
        this.subscriptions.push(this.loggedUserService.dataChanged().subscribe(() => {
            this.getMenuPositions();
        }));
    }

    private showMobileMenuChanged(): void {
        this.subscriptions.push(this.menuLeftStateService.showMobileChanged().subscribe(() => {
            this.showMobileMenu = this.menuLeftStateService.showMobile;
        }));
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    public menuActive(menuPositionIdx: number, menuSubPositionIdx?: number): boolean {
        if (UtilsService.ifIdx(menuSubPositionIdx!)) return this.router.url.includes(this.menuPositions[menuPositionIdx].subPositions![menuSubPositionIdx!].route);
        return this.router.url.includes(this.menuPositions[menuPositionIdx].route);
    }

    public closeMobileMenu(): void {
        this.menuLeftStateService.showMobile = false;
    }

    private getMenuPositions(): void {
        this.menuPositions = this.menuPositionService.preparedList;
    }
}
