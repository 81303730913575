import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {UploadedFileInterface} from '../interfaces/global.interface';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    constructor(public http: HttpClient) {
    }

    public uploadFile(event: Event): File | null {
        if (!!event && !!event.target && !!event.target!['files'] && event.target!['files'].length > 0) return event.target!['files'][0];
        return null;
    }

    public prepareFormDataWithFile(event: Event): FormData {
        const file: File = this.uploadFile(event)!;
        const formData: FormData = new FormData();
        formData.append('file', file);

        return formData;
    }

    public uploadFileToServer(data: FormData): Observable<UploadedFileInterface> {
        return this.http.post<UploadedFileInterface>(environment.apiPath + 'files', data);
    }

    public getFileFromServer(fileId: string): Observable<UploadedFileInterface> {
        return this.http.get<UploadedFileInterface>(environment.apiPath + 'files/' + fileId);
    }

    public static showFileInNewPage(id: string): void {
        window.open(`${environment.apiPath}files/${id}/preview`, '_blank');
    }
}
