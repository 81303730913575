<div class="menuLeft">
    <div class="menuLeft__logo">
        <img alt="Logo" src="assets/images/logo.png" [routerLink]="[dashboardLink]"/>
    </div>

    <div class="menuLeft__positions">
        <ng-container *ngFor="let menuPosition of menuPositions; let menuPositionIdx = index;">
            <ng-container *ngIf="menuPosition.id !== separatorId; else separatorLine;">
                <ng-container [ngTemplateOutlet]="menuPositionTemplate" [ngTemplateOutletContext]="{menuPosition: menuPosition, menuPositionIdx: menuPositionIdx}"></ng-container>

                <ng-container *ngIf="menuPosition.showSubPositions">
                    <ng-container *ngFor="let subPosition of menuPosition.subPositions; let subPositionIdx = index;">
                        <ng-container [ngTemplateOutlet]="menuPositionTemplate" [ngTemplateOutletContext]="{menuPosition: subPosition, menuPositionIdx: menuPositionIdx, subPositionIdx: subPositionIdx}"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-template #separatorLine>
                <div class="menuLeft__separatorLine"></div>
            </ng-template>
        </ng-container>
    </div>
</div>

<div class="menuMobileWrapper">
    <div class="menuMobile" [ngClass]="{'open': showMobileMenu}">
        <div class="menuLeft__positions">
            <ng-container *ngFor="let menuPosition of menuPositions; let menuPositionIdx = index;">
                <ng-container *ngIf="menuPosition.id !== separatorId; else separatorLine;">
                    <ng-container [ngTemplateOutlet]="menuPositionMobileTemplate" [ngTemplateOutletContext]="{menuPosition: menuPosition, menuPositionIdx: menuPositionIdx}"></ng-container>

                    <ng-container *ngIf="menuPosition.showSubPositions">
                        <ng-container *ngFor="let subPosition of menuPosition.subPositions; let subPositionIdx = index;">
                            <ng-container [ngTemplateOutlet]="menuPositionMobileTemplate" [ngTemplateOutletContext]="{menuPosition: subPosition, menuPositionIdx: menuPositionIdx, subPositionIdx: subPositionIdx}"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-template #separatorLine>
                    <div class="menuLeft__separatorLine"></div>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #menuPositionMobileTemplate let-menuPosition="menuPosition" let-menuPositionIdx="menuPositionIdx" let-subPositionIdx="subPositionIdx">
    <div class="menuLeft__positionWrapper">
        <div class="menuLeft__position" [ngClass]="{'active': menuActive(menuPositionIdx)}">
            <a [href]="!menuPosition.subPositions ? [menuPosition.route] : null" [routerLink]="!menuPosition.subPositions ? [menuPosition.route] : null"
               (click)="!!menuPosition.subPositions ? menuPosition.showSubPositions = !menuPosition.showSubPositions : closeMobileMenu()">
                <label>{{menuPosition.translation | translate}}</label>
            </a>
        </div>
        <div class="menuLeft__chevron" [ngClass]="{'menuLeft__chevron--open': menuPosition.showSubPositions}" *ngIf="!!menuPosition.subPositions">
            <img alt="Chevron" src="/assets/images/down-arrow.svg">
        </div>
    </div>
</ng-template>

<ng-template #menuPositionTemplate let-menuPosition="menuPosition" let-menuPositionIdx="menuPositionIdx" let-subPositionIdx="subPositionIdx">
    <div class="menuLeft__positionWrapper" [ngClass]="{'active': menuActive(menuPositionIdx, subPositionIdx)}">
        <a [href]="!menuPosition.subPositions ? [menuPosition.route] : null" [routerLink]="!menuPosition.subPositions ? [menuPosition.route] : null"
           (click)="!!menuPosition.subPositions ? menuPosition.showSubPositions = !menuPosition.showSubPositions : null">
            <div class="menuLeft__positionBorderLeft"></div>
            <div class="menuLeft__position" [ngClass]="{'menuLeft__position--uppercase': menuPosition.uppercase}">
                <img alt="{{menuPosition.name}}" [ngClass]="menuPosition.class"/>
                <label>{{menuPosition.translation | translate}}</label>
            </div>
            <div class="menuLeft__chevron" [ngClass]="{'menuLeft__chevron--open': menuPosition.showSubPositions}" *ngIf="!!menuPosition.subPositions">
                <img alt="Chevron" src="/assets/images/down-arrow.svg">
            </div>
        </a>
    </div>
</ng-template>
