import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Params} from '@angular/router';
import {map, Observable, Subscription} from 'rxjs';
import {BasicServiceInterface, FilteredListInterface} from '../../interfaces/global.interface';
import {environment} from '../../../environments/environment';
import {SubscriptionBonusPlaceUsageDTO, SubscriptionExport, SubscriptionInterface, SubscriptionUsage, SubscriptionUsageDTO} from '../../interfaces/subscription.interface';
import {ServiceDataHandler} from '../../classes/service-data-handler.class';
import * as moment from 'moment';
import {CustomerAddressDataInterface, CustomerContactDataInterface} from '../../interfaces/customer.interface';
import {TranslateService} from '../../services/translate.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService extends ServiceDataHandler<SubscriptionInterface> implements BasicServiceInterface<SubscriptionInterface> {

  constructor(private http: HttpClient,
              private translateService: TranslateService) {
    super();
  }

  public downloadList(): Observable<SubscriptionInterface[]> {
    return this.http.get<SubscriptionInterface[]>(environment.apiPath + 'subscriptions').pipe(
      map(list => SubscriptionsService.prepareList(list))
    );
  }

  public downloadFilteredList(params: Params): Observable<FilteredListInterface<SubscriptionInterface>> {
    return this.http.get<FilteredListInterface<SubscriptionInterface>>(environment.apiPath + 'subscriptions', {params}).pipe(
      map(list => {
        list.items = SubscriptionsService.prepareList(list.items);
        return list;
      })
    );
  }

  public downloadPureFilteredList(params: Params): Observable<SubscriptionInterface[]> {
    return this.http.get<SubscriptionInterface[]>(environment.apiPath + 'subscriptions', {params}).pipe(
      map(list => SubscriptionsService.prepareList(list))
    );
  }

  public downloadOne(id: string): Observable<SubscriptionInterface> {
    return this.http.get<SubscriptionInterface>(environment.apiPath + 'subscriptions/' + id).pipe(
      map(sub => SubscriptionsService.prepareOne(sub))
    );
  }

  public saveOne(data: SubscriptionInterface): Observable<SubscriptionInterface> {
    return this.http.post<SubscriptionInterface>(environment.apiPath + 'subscriptions', data);
  }

  public updateOne(data: SubscriptionInterface, dataId: string): Observable<SubscriptionInterface> {
    return this.http.put<SubscriptionInterface>(environment.apiPath + 'subscriptions/' + dataId, data);
  }

  public deleteOne(dataId: string): Observable<void> {
    return this.http.delete<void>(environment.apiPath + 'subscriptions/' + dataId);
  }

  public saveUsage(subscriptionId: string, data: SubscriptionUsageDTO): Observable<SubscriptionUsage> {
    return this.http.post<SubscriptionUsage>(environment.apiPath + `subscriptions/${subscriptionId}/subscription-usages`, data);
  }

  public deleteUsage(subscriptionId: string, usageId: string): Observable<void> {
    return this.http.delete<void>(environment.apiPath + `subscriptions/${subscriptionId}/subscription-usages/${usageId}`);
  }

  public downloadUsages(subscriptionId: string): Observable<SubscriptionUsage[]> {
    return this.http.get<SubscriptionUsage[]>(environment.apiPath + `subscriptions/${subscriptionId}/subscription-usages`);
  }

  public saveBonusUsage(subscriptionId: string, data: SubscriptionBonusPlaceUsageDTO): Observable<any> {
    return this.http.post<any>(environment.apiPath + `subscriptions/${subscriptionId}/bonus-place-usages`, data);
  }

  public deleteBonusUsage(subscriptionId: string, bonusUsageId: string): Observable<void> {
    return this.http.delete<void>(environment.apiPath + `subscriptions/${subscriptionId}/bonus-place-usages/${bonusUsageId}`);
  }

  public downloadBonusUsages(subscriptionId: string): Observable<SubscriptionUsage[]> {
    return this.http.get<SubscriptionUsage[]>(environment.apiPath + `subscriptions/${subscriptionId}/bonus-place-usages`);
  }

  public prepareSubscriptionsToExport(list: SubscriptionInterface[]): SubscriptionExport[] {
    return list.map((subscription: SubscriptionInterface) => {
      const addressData: CustomerAddressDataInterface = subscription.addressData || {};
      const contactData: CustomerContactDataInterface = subscription.contactData || {};

      return {
        name: subscription.name,
        status: this.translateService.getTranslated('enum.subscriptionStatus.' + subscription.subscriptionStatus.toUpperCase()),
        value: subscription.value.toFixed(2),
        currentValue: (subscription.currentValue || 0).toFixed(2),
        usedValue: subscription.valueUsed.toFixed(2),
        leftBonusPlaces: subscription.leftBonusPlaces || 0,
        placementName: addressData.name,
        placementCity: addressData.city,
        endDate: moment(subscription.endDate).format('DD.MM.YYYY'),
        invoiceNumber: subscription.invoiceNumber,
        invoiceValue: !!subscription.invoiceValue ? subscription.invoiceValue.toFixed(2) : '',
        contactData: `${contactData.firstName} ${contactData.lastName}, ${contactData.email}, ${contactData.workPhone}`,
        note: subscription.note,
        openCourses: subscription.openCourses,
        closedCourses: subscription.closedCourses,
      }
    });
  }

  public static prepareList(list: SubscriptionInterface[]): SubscriptionInterface[] {
    return list.map(record => SubscriptionsService.prepareOne(record));
  }

  public static prepareOne(sub: SubscriptionInterface): SubscriptionInterface {
    sub.currentValue = sub.value - sub.valueUsed;
    sub.leftBonusPlaces = sub.bonusPlaces - sub.bonusPlacesUsed;
    sub.count = 1;

    return sub;
  }
}
