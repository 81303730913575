<app-loader-wrapper *ngIf="!!form" [ifLoaded]="!!form">
    <div class="row">
        <div class="col-12 basicForm__form">
            <form [formGroup]="form">
                <div class="row basicForm__columns">
                    <div class="col-12 col-xl-5 basicForm__firstColumn">
                        <div class="row">
                            <div class="col-12">
                                <app-box-with-header [header]="'1. ' + ('subscriptionValue' | translate)" [headerRequired]="true" [headerBigger]="true">
                                    <div class="row">
                                        <div class="col-12 formGroup" [ngClass]="{'error': ifError('value')}">
                                            <input type="number" formControlName="value"/>
                                            <label class="formGroup--big">{{'subscriptionValueInfo' | translate}}</label>
                                        </div>

                                        <div class="col-12 formText">
                                            <label class="formText__label formText__label--extraBigInfo">Po złożeniu zamówienia na kwotę podaną powyżej, w punkcie „Wartość abonamentu” zostanie wystawiona faktura.</label>
                                        </div>
                                    </div>
                                </app-box-with-header>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <app-box-with-header [header]="'2. ' + ('bonusPlaces' | translate)" [headerBigger]="true">
                                    <div class="row">
                                        <div class="col-12 formGroup">
                                            <input type="text" [(ngModel)]="bonusPlaces" [ngModelOptions]="{standalone: true}" disabled/>
                                        </div>

                                        <div class="col-12">
                                            <ul class="basicUnorderedList basicUnorderedList--bigText">
                                                <li>W zależności od wartości wykupionego abonamentu przysługują MIEJSCA BEZPŁATNE, tzw. BONUSY. Szczegółowe informacje <a href="https://www.empiria.edu.pl/szkolenia-i-kursy/abonament-szkoleniowy" target="_blank">TUTAJ</a>.</li>
                                            </ul>
                                        </div>

                                        <div class="col-12">
                                            <table class="basicTable">
                                                <thead>
                                                <tr>
                                                    <th>Wartość abonamentu</th>
                                                    <th>BONUS (miejsca bezpłatne)</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>od 4001 do 5000 zł brutto</td>
                                                    <td>4 miejsca (do 800 zł)</td>
                                                </tr>
                                                <tr>
                                                    <td>od 5001 do 6000 zł brutto</td>
                                                    <td>5 miejsc (do 1000 zł)</td>
                                                </tr>
                                                <tr>
                                                    <td>od 6001 do 7000 zł brutto</td>
                                                    <td>6 miejsc (do 1200 zł)</td>
                                                </tr>
                                                <tr>
                                                    <td>od 7001 zł do 8000 zł brutto</td>
                                                    <td>8 miejsc (do 1600zł)</td>
                                                </tr>
                                                <tr>
                                                    <td>Powyżej 8000 zł brutto</td>
                                                    <td>10 miejsc</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </app-box-with-header>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <app-box-with-header [header]="'3. ' + (('coursesPlanned' | translate) + ' - ' + ('optionalFields' | translate).toLowerCase())" [headerBigger]="true">
                                    <div class="row">
                                        <div class="col-12 formText">
                                            <label class="formText__label formText__label--extraBigInfo">Inspiracje tematyki szkoleniowej można zaczerpnąć z INFORMATORA dostępnego na <a href="https://www.empiria.edu.pl" target="_blank">www.empiria.edu.pl</a>. Link do pobrania Informatora <a
                                                    href="https://www.empiria.edu.pl/dokumenty/rok-szkolny-2020-2021/informator/restauracja-edumyslna-informator-edukacyjny-2020-2021.pdf" target="_blank">TUTAJ</a> lub z kalendarium szkoleń otwartych</label>
                                        </div>

                                        <div class="col-12 mt-3">
                                            <app-box-with-header [header]="'coursesOpen' | translate">
                                                <div class="row">
                                                    <div class="col-12 formGroup">
                                                        <textarea formControlName="openCourses"></textarea>
                                                    </div>
                                                </div>
                                            </app-box-with-header>
                                        </div>

                                        <div class="col-12 mt-3">
                                            <app-box-with-header [header]="'coursesClosed' | translate">
                                                <div class="row">
                                                    <div class="col-12 formGroup">
                                                        <textarea formControlName="closedCourses"></textarea>
                                                    </div>
                                                </div>
                                            </app-box-with-header>
                                        </div>
                                    </div>
                                </app-box-with-header>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-5 basicForm__secondColumn">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <app-box-with-header [header]="'4. ' + ('invoiceTitle' | translate)" [headerRequired]="true" [headerBigger]="true">
                                            <div class="row">
                                                <div class="col-12 formGroup" [ngClass]="{'error': ifError('invoiceTitle')}">
                                                    <input formControlName="invoiceTitle"/>
                                                    <label class="formGroup--big">np. Abonament na realizację szkoleń lub tytuły zaplanowanych szkoleń etc.</label>
                                                </div>
                                            </div>
                                        </app-box-with-header>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <app-box-with-header [header]="'5. ' + ('addressData' | translate)" [headerBigger]="true">
                                            <ng-container formGroupName="addressData">
                                                <div class="row">
                                                    <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': ifAddressDataError('name')}">
                                                        <label>{{'institutionName' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="name" (blur)="downloadCustomerByData('name', 'name'); rewriteAddressToInvoiceReceiver('name')" trim="blur"/>
                                                    </div>

                                                    <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('type')}">
                                                        <label>{{'dictionary.establishmentType' | translate}} <i class="required">*</i></label>
                                                        <select formControlName="type">
                                                            <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                            <ng-container *ngFor="let establishmentType of statics.establishmentTypes; let establishmentTypeIdx = index;">
                                                                <option [ngValue]="establishmentType.id">{{establishmentType.name}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifAddressDataError('email')}">
                                                        <label>{{'emailEstablishment' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="email" trim="blur" (blur)="checkIfEmailEstablishment()"/>
                                                    </div>

                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifAddressDataError('phone')}">
                                                        <label>{{'phoneEstablishment' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="phone" trim="blur"/>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('state')}">
                                                        <label>{{'address.province' | translate}} <i class="required">*</i></label>
                                                        <select formControlName="state">
                                                            <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                            <ng-container *ngFor="let state of statics.states">
                                                                <option [ngValue]="state.id">{{state.name}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>

                                                    <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('district')}">
                                                        <label>{{'address.district' | translate}}</label>
                                                        <input formControlName="district" trim="blur"/>
                                                    </div>

                                                    <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('commune')}">
                                                        <label>{{'address.community' | translate}}</label>
                                                        <input formControlName="commune" trim="blur"/>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': ifAddressDataError('city')}">
                                                        <label>{{'address.city' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="city" trim="blur" (blur)="rewriteAddressToInvoiceReceiver('city')"/>
                                                    </div>

                                                    <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('postalCode')}">
                                                        <label>{{'address.postalCode' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="postalCode" appPostalCodeMask trim="blur" (blur)="rewriteAddressToInvoiceReceiver('postalCode')"/>
                                                        <i class="error">{{'error.postalCode' | translate}}</i>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifAddressDataError('street')}">
                                                        <label>{{'address.street' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="street" trim="blur" (blur)="rewriteAddressToInvoiceReceiver('street')"/>
                                                    </div>

                                                    <div class="col-6 col-md-3 formGroup" [ngClass]="{'error': ifAddressDataError('buildingNumber')}">
                                                        <label>{{'address.houseNo' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="buildingNumber" trim="blur" (blur)="rewriteAddressToInvoiceReceiver('buildingNumber')"/>
                                                    </div>

                                                    <div class="col-6 col-md-3 formGroup">
                                                        <label>{{'address.flatNo' | translate}}</label>
                                                        <input formControlName="localNumber" trim="blur" (blur)="rewriteAddressToInvoiceReceiver('localNumber')"/>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </app-box-with-header>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <app-box-with-header [header]="'6. ' + ('contactDataResponsibleSubscription' | translate)" [headerBigger]="true">
                                            <ng-container formGroupName="contactData">
                                                <div class="row">
                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('firstName')}">
                                                        <label>{{'firstName' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="firstName" trim="blur"/>
                                                    </div>

                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('lastName')}">
                                                        <label>{{'lastName' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="lastName" trim="blur"/>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('function')}">
                                                        <label>{{'function' | translate}} <i class="required">*</i></label>
                                                        <select formControlName="function">
                                                            <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                            <ng-container *ngFor="let func of statics.functions">
                                                                <option [ngValue]="func.id">{{func.name}}</option>
                                                            </ng-container>
                                                        </select>
                                                    </div>

                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('email')}">
                                                        <label>{{'email' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="email" trim="blur"/>
                                                        <i>{{'error.email' | translate}}</i>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': (getContactDataFG().get('workPhone')!.touched || getContactDataFG().get('privatePhone')!.touched) && getContactDataFG().hasError('atLeastOnePhone')}">
                                                        <label>{{'phoneBusiness' | translate}} <i class="required">*</i></label>
                                                        <input formControlName="workPhone" appOnlyIntegers/>
                                                    </div>

                                                    <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': (getContactDataFG().get('workPhone')!.touched || getContactDataFG().get('privatePhone')!.touched) && getContactDataFG().hasError('atLeastOnePhone')}">
                                                        <label>{{'phonePrivate' | translate}}</label>
                                                        <input formControlName="privatePhone" appOnlyIntegers/>
                                                    </div>

                                                    <div class="col-12" *ngIf="(getContactDataFG().get('workPhone')!.touched || getContactDataFG().get('privatePhone')!.touched) && getContactDataFG().hasError('atLeastOnePhone')">
                                                        <i class="error">{{'error.atLeastOnePhone' | translate}}</i>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </app-box-with-header>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-12">
                                        <app-box-with-header [header]="'7. ' + ('invoiceData' | translate)" [headerBigger]="true">
                                            <div class="row">
                                                <div class="col-12 mt-3">
                                                    <app-box-with-header [header]="'buyer' | translate" [headerBolder]="true">
                                                        <ng-container formGroupName="invoiceBuyerData">
                                                            <div class="row">
                                                                <div class="col-12 formGroup" [ngClass]="{'error': ifBuyerDataError('nip')}">
                                                                    <label>{{'nip' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="nip" appOnlyIntegers trim="blur"/>
                                                                    <i>{{'error.nip' | translate}}</i>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-12 formGroup" [ngClass]="{'error': ifBuyerDataError('name')}">
                                                                    <label>{{'name' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="name" trim="blur"/>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': ifBuyerDataError('city')}">
                                                                    <label>{{'address.city' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="city" trim="blur"/>
                                                                </div>

                                                                <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifBuyerDataError('postalCode')}">
                                                                    <label>{{'address.postalCode' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="postalCode" appPostalCodeMask trim="blur"/>
                                                                    <i class="error">{{'error.postalCode' | translate}}</i>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifBuyerDataError('street')}">
                                                                    <label>{{'address.street' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="street" trim="blur"/>
                                                                </div>

                                                                <div class="col-6 col-md-3 formGroup" [ngClass]="{'error': ifBuyerDataError('buildingNumber')}">
                                                                    <label>{{'address.houseNo' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="buildingNumber" trim="blur"/>
                                                                </div>

                                                                <div class="col-6 col-md-3 formGroup" [ngClass]="{'error': ifBuyerDataError('localNumber')}">
                                                                    <label>{{'address.flatNo' | translate}}</label>
                                                                    <input formControlName="localNumber" trim="blur"/>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </app-box-with-header>
                                                </div>

                                                <div class="col-12 mt-3">
                                                    <app-box-with-header [header]="'recipient' | translate" [headerBolder]="true">
                                                        <ng-container formGroupName="invoiceReceiverData">
                                                            <div class="row">
                                                                <div class="col-12 formGroup" [ngClass]="{'error': ifRecipientDataError('name')}">
                                                                    <label>{{'name' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="name" trim="blur"/>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': ifRecipientDataError('city')}">
                                                                    <label>{{'address.city' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="city" trim="blur"/>
                                                                </div>

                                                                <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifRecipientDataError('postalCode')}">
                                                                    <label>{{'address.postalCode' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="postalCode" appPostalCodeMask trim="blur"/>
                                                                    <i class="error">{{'error.postalCode' | translate}}</i>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifRecipientDataError('street')}">
                                                                    <label>{{'address.street' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="street" trim="blur"/>
                                                                </div>

                                                                <div class="col-6 col-md-3 formGroup" [ngClass]="{'error': ifRecipientDataError('buildingNumber')}">
                                                                    <label>{{'address.houseNo' | translate}} <i class="required">*</i></label>
                                                                    <input formControlName="buildingNumber" trim="blur"/>
                                                                </div>

                                                                <div class="col-6 col-md-3 formGroup" [ngClass]="{'error': ifRecipientDataError('localNumber')}">
                                                                    <label>{{'address.flatNo' | translate}}</label>
                                                                    <input formControlName="localNumber" trim="blur"/>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </app-box-with-header>
                                                </div>
                                            </div>
                                        </app-box-with-header>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-loader-wrapper>
