<app-loader-wrapper *ngIf="!!form" [ifLoaded]="!!form">
    <div class="row">
        <div class="col-12 basicForm__form">
            <form [formGroup]="form">
                <div class="row basicForm__columns">
                    <div class="col-12 col-xl-5 basicForm__firstColumn">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div [ngClass]="{'error': ifError('courseArea')}" class="col-12 formGroup formGroup--green">
                                        <label class="formGroup--bold">{{'dictionary.area' | translate}} <i class="required">*</i></label>
                                        <select formControlName="courseArea" (change)="clearCourseType()">
                                            <option [ngValue]="null" disabled>{{'selectChoose.area' | translate}}</option>
                                            <ng-container *ngFor="let area of statics.areas; let areaIdx = index;">
                                                <option [ngValue]="area.id">{{area.name}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div [ngClass]="{'error': ifError('form')}" class="col-12 formGroup mb-0 formGroup--green">
                                        <label class="formGroup--bold">{{'courseForm' | translate}} <i class="required">*</i></label>
                                        <select formControlName="form">
                                            <option [ngValue]="null" disabled>{{'selectChoose.form' | translate}}</option>
                                            <ng-container *ngFor="let formType of statics.formTypes | enumToArray;">
                                                <option [ngValue]="statics.formTypes[formType]">{{('enum.formTypes.' + formType) | translate}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>

                                <div class="row mt-1">
                                    <div [ngClass]="{'error': ifError('courseType')}" class="col-12 formGroup mb-0 formGroup--green">
                                        <label class="formGroup--bold">{{'courseName' | translate}} <i class="required">*</i></label>
                                        <ng-select formControlName="courseType" [placeholder]="'selectChoose.course' | translate">
                                            <ng-container *ngFor="let course of statics.courses; let courseIdx = index;">
                                                <ng-container *ngIf="course.courseAreaId === form.get('courseArea')?.value">
                                                    <ng-option [value]="course.id">{{course.name}}</ng-option>
                                                </ng-container>
                                            </ng-container>
                                        </ng-select>
                                    </div>

                                    <div class="col-12 formText mt-0 mb-2">
                                        <label class="formText__label formText__label--extraBigInfo">{{'noYourCloseCourse' | translate}}</label>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="applicationCourseFormCloseSubscription" formControlName="subscription">
                                            <label class="custom-control-label" for="applicationCourseFormCloseSubscription">{{'subscriptionCheckbox' | translate}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="applicationCourseFormCloseBonus" formControlName="bonus">
                                            <label class="custom-control-label" for="applicationCourseFormCloseBonus">{{'bonusCheckbox' | translate}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div [ngClass]="{'error': ifError('predictedParticipantCount')}" class="col-12 col-md-4 formGroup mb-0 formGroup--green">
                                        <label class="formGroup--bold">{{'expectedPeopleAmount' | translate}} <i class="required">*</i></label>
                                        <input type="number" formControlName="predictedParticipantCount"/>
                                    </div>

                                    <div class="col-12 col-md-8 formGroup mb-0 formGroup--green">
                                        <label>{{'expectedDateStartRange' | translate}}</label>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup mb-0" [ngClass]="{'error': ifError('predictedTimeOfCourseFrom')}">
                                                <input type="date" formControlName="predictedTimeOfCourseFrom"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup mb-0" [ngClass]="{'error': ifError('predictedTimeOfCourseTo')}">
                                                <input type="date" formControlName="predictedTimeOfCourseTo"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 formText mt-0 mb-2">
                                        <label class="formText__label formText__label--extraBigInfo">{{'extraInfoInformation' | translate}}</label>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 formGroup">
                                        <label>{{'note' | translate}}</label>
                                        <textarea formControlName="note" trim="blur"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-3">
                                <app-box-with-header [header]="'addressData' | translate" [headerBolder]="true">
                                    <ng-container formGroupName="addressData">
<!--                                        <div class="row">-->
<!--                                            <div class="col-12 col-md-6 formGroup">-->
<!--                                                <label>{{'institutionHash' | translate}}</label>-->
<!--                                                <input formControlName="hash" (blur)="downloadCustomerByData('placementHash', 'hash')" trim="blur"/>-->
<!--                                            </div>-->
<!--                                        </div>-->

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': ifAddressDataError('name')}">
                                                <label>{{'institutionName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="name" (blur)="downloadCustomerByData('name', 'name')" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('type')}">
                                                <label>{{'dictionary.establishmentType' | translate}} <i class="required">*</i></label>
                                                <select formControlName="type">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let establishmentType of statics.establishmentTypes; let establishmentTypeIdx = index;">
                                                        <option [ngValue]="establishmentType.id">{{establishmentType.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifAddressDataError('email')}">
                                                <label>{{'emailEstablishment' | translate}} <i class="required">*</i></label>
                                                <input formControlName="email" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifAddressDataError('phone')}">
                                                <label>{{'phoneEstablishment' | translate}} <i class="required">*</i></label>
                                                <input formControlName="phone" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('state')}">
                                                <label>{{'address.province' | translate}} <i class="required">*</i></label>
                                                <select formControlName="state">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let state of statics.states">
                                                        <option [ngValue]="state.id">{{state.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('district')}">
                                                <label>{{'address.district' | translate}}</label>
                                                <input formControlName="district" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('commune')}">
                                                <label>{{'address.community' | translate}}</label>
                                                <input formControlName="commune" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup" [ngClass]="{'error': ifAddressDataError('city')}">
                                                <label>{{'address.city' | translate}} <i class="required">*</i></label>
                                                <input formControlName="city" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': ifAddressDataError('postalCode')}">
                                                <label>{{'address.postalCode' | translate}} <i class="required">*</i></label>
                                                <input formControlName="postalCode" appPostalCodeMask trim="blur"/>
                                                <i class="error">{{'error.postalCode' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifAddressDataError('street')}">
                                                <label>{{'address.street' | translate}} <i class="required">*</i></label>
                                                <input formControlName="street" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup" [ngClass]="{'error': ifAddressDataError('buildingNumber')}">
                                                <label>{{'address.houseNo' | translate}} <i class="required">*</i></label>
                                                <input formControlName="buildingNumber" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber" trim="blur"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>

                            <div class="col-12 mt-3">
                                <app-box-with-header [header]="'contactDataResponsibleCourse' | translate" [headerBolder]="true">
                                    <ng-container formGroupName="contactData">
                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('firstName')}">
                                                <label>{{'firstName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="firstName" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('lastName')}">
                                                <label>{{'lastName' | translate}} <i class="required">*</i></label>
                                                <input formControlName="lastName" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('function')}">
                                                <label>{{'function' | translate}} <i class="required">*</i></label>
                                                <select formControlName="function">
                                                    <option [ngValue]="null" disabled>{{'selectChoose.global' | translate}}</option>
                                                    <ng-container *ngFor="let func of statics.functions; let funcIdx = index;">
                                                        <option [ngValue]="func.id">{{func.name}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('email')}">
                                                <label>{{'email' | translate}} <i class="required">*</i></label>
                                                <input formControlName="email" trim="blur"/>
                                                <i>{{'error.email' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('workPhone')}">
                                                <label>{{'phoneBusiness' | translate}} <i class="required">*</i></label>
                                                <input formControlName="workPhone" appOnlyIntegers trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-6 formGroup" [ngClass]="{'error': ifContactDataError('privatePhone')}">
                                                <label>{{'phonePrivate' | translate}}</label>
                                                <input formControlName="privatePhone" appOnlyIntegers trim="blur"/>
                                            </div>

                                            <div class="col-12" *ngIf="(getContactDataFG().get('workPhone')!.touched || getContactDataFG().get('privatePhone')!.touched) && getContactDataFG().hasError('atLeastOnePhone')">
                                                <i class="error">{{'error.atLeastOnePhone' | translate}}</i>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-xl-5 basicForm__secondColumn">
                        <div class="row">
                            <div class="col-12">
                                <app-box-with-header header="{{'invoiceData' | translate}} - {{'buyer' | translate}}" [headerBolder]="true">
                                    <ng-container formGroupName="invoiceBuyerData">
                                        <div class="row">
                                            <div class="col-12 formGroup" [ngClass]="{'error': getBuyerFG().get('nip')?.invalid && getBuyerFG().get('nip')?.touched}">
                                                <label>{{'nip' | translate}}</label>
                                                <input formControlName="nip" appOnlyIntegers trim="blur"/>
                                                <i>{{'error.nip' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 formGroup">
                                                <label>{{'name' | translate}}</label>
                                                <input formControlName="name" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup">
                                                <label>{{'address.city' | translate}}</label>
                                                <input formControlName="city" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': getBuyerFG().get('postalCode')?.invalid && getBuyerFG().get('postalCode')?.touched}">
                                                <label>{{'address.postalCode' | translate}}</label>
                                                <input formControlName="postalCode" appPostalCodeMask trim="blur"/>
                                                <i class="error">{{'error.postalCode' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'address.street' | translate}}</label>
                                                <input formControlName="street" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.houseNo' | translate}}</label>
                                                <input formControlName="buildingNumber" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber" trim="blur"/>
                                            </div>
                                        </div>
                                    </ng-container>
                                </app-box-with-header>
                            </div>

                            <div class="col-12 mt-3">
                                <app-box-with-header header="{{'invoiceData' | translate}} - {{'recipient' | translate}}" [headerBolder]="true">
                                    <ng-container formGroupName="invoiceReceiverData">
                                        <div class="row">
                                            <div class="col-12 formGroup">
                                                <label>{{'name' | translate}}</label>
                                                <input formControlName="name" trim="blur"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-8 formGroup">
                                                <label>{{'address.city' | translate}}</label>
                                                <input formControlName="city" trim="blur"/>
                                            </div>

                                            <div class="col-12 col-md-4 formGroup" [ngClass]="{'error': getRecipientFG().get('postalCode')?.invalid && getRecipientFG().get('postalCode')?.touched}">
                                                <label>{{'address.postalCode' | translate}}</label>
                                                <input formControlName="postalCode" appPostalCodeMask trim="blur"/>
                                                <i class="error">{{'error.postalCode' | translate}}</i>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 col-md-6 formGroup">
                                                <label>{{'address.street' | translate}}</label>
                                                <input formControlName="street" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.houseNo' | translate}}</label>
                                                <input formControlName="buildingNumber" trim="blur"/>
                                            </div>

                                            <div class="col-6 col-md-3 formGroup">
                                                <label>{{'address.flatNo' | translate}}</label>
                                                <input formControlName="localNumber" trim="blur"/>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="row">
                                        <div class="col-12 formGroup" [ngClass]="{'error': form.get('email')?.invalid && form.get('email')?.touched}">
                                            <label>{{'emailToSendInvoice' | translate}}</label>
                                            <input formControlName="email" trim="blur"/>
                                            <i>{{'error.email' | translate}}</i>
                                        </div>
                                    </div>
                                </app-box-with-header>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-loader-wrapper>
