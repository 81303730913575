export enum FormTypesEnum {
    STATIONARY = 0,
    DEPARTURE = 1,
    ONLINE = 2,
    HYBRID = 3,
    CONSULTATION = 4
}

export const AllFormTypes = [
    FormTypesEnum.STATIONARY,
    FormTypesEnum.DEPARTURE,
    FormTypesEnum.ONLINE,
    FormTypesEnum.HYBRID,
    FormTypesEnum.CONSULTATION
]
