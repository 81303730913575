import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ModalComponent} from '../modal/modal.component';
import {UtilsService} from '../../services/utils.service';
import {ChooseCustomerModalService} from './choose-customer-modal.service';
import {CustomerInterface} from '../../interfaces/customer.interface';
import {CustomersService} from '../../pages/customers/customers.service';
import {FilteredListInterface} from '../../interfaces/global.interface';
import {HttpParams} from '@angular/common/http';
import {map, Subscription, tap} from 'rxjs';
import {CustomerTypesEnum} from '../../enums/customer-types.enum';

@Component({
    selector: 'app-choose-customer-modal',
    templateUrl: './choose-customer-modal.component.html',
    styleUrls: ['./choose-customer-modal.component.scss']
})
export class ChooseCustomerModalComponent implements OnDestroy {
    @ViewChild('chooseCustomerModal', {static: true}) public chooseCustomerModal!: ModalComponent;
    private subscriptions: Subscription[] = [];
    public search: {name: string, surname: string} = {name: '', surname: ''};
    public list: CustomerInterface[] = [];
    private searchingTimeout: any;
    public CustomerTypesEnum: typeof CustomerTypesEnum = CustomerTypesEnum;
    public chosen!: CustomerInterface;
    public initCustomer!: CustomerInterface;

    constructor(private chooseCustomerModalService: ChooseCustomerModalService,
                private customersService: CustomersService) {
        this.showModalChanged();
    }

    private showModalChanged(): void {
        this.subscriptions.push(this.chooseCustomerModalService.showModalChanged().subscribe((customer: CustomerInterface) => {
            this.initCustomer = customer;
            this.chosen = null!;
            this.list = [];
            this.search = {name: '', surname: ''};
            this.chooseCustomerModal.show();
        }));
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    public filter(): void {
        if (this.search.name.length >= 3 || this.search.surname.length >= 3) {
            clearTimeout(this.searchingTimeout);
            this.searchingTimeout = setTimeout(() => {
                this.customersService.downloadFilteredList(new HttpParams().set('all', true).set('name', this.search.name).set('surname', this.search.surname))
                    .pipe(
                        map((customers: FilteredListInterface<CustomerInterface>) => customers.items),
                        map((customers: CustomerInterface[]) => customers.filter((customer: CustomerInterface) => customer.customerType === this.initCustomer.customerType)),
                        map((customers: CustomerInterface[]) => customers.filter((customer: CustomerInterface) => customer.id !== this.initCustomer.id)),
                        tap(() => this.chosen = null!)
                    )
                    .subscribe((customers: CustomerInterface[]) => this.list = customers);
            }, 500);
        }
    }

    public chooseItem(item: CustomerInterface): void {
        this.chosen = this.chosen?.id === item.id ? null! : item;
    }

    public close(): void {
        this.chooseCustomerModalService.hideModal(this.chosen);
        this.chooseCustomerModal.hide();
    }
}
