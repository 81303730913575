export enum SubscriptionPaymentSchedulesEnum {
    ADVANCED = 'advanced',
    FULL = 'full',
    OTHER = 'other',
}

export const AllCourseStatuses = [
    SubscriptionPaymentSchedulesEnum.ADVANCED,
    SubscriptionPaymentSchedulesEnum.FULL,
    SubscriptionPaymentSchedulesEnum.OTHER
]
