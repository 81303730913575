<div class="paginator">
    <ul class="pagination">
        <li class="page-item">
            <a (click)="changePage(0)" class="arrow left-left" [ngClass]="{'disabled': currentPage === 0 || totalRecords === 0}"><<</a>
        </li>
        <li class="page-item">
            <a (click)="changePage(currentPage - 1)" class="arrow left" [ngClass]="{'disabled': currentPage === 0 || totalRecords === 0}"><</a>
        </li>
        <li class="page-item" [ngClass]="currentPage === page ? 'active' : ''" *ngFor="let page of pagesAmount()">
            <a (click)="changePage(page)">{{page + 1}}</a>
        </li>
        <li class="page-item">
            <a (click)="changePage(currentPage + 1)" class="arrow right" [ngClass]="{'disabled': currentPage === maxPage - 1 || totalRecords === 0}">></a>
        </li>
        <li class="page-item">
            <a (click)="changePage(maxPage - 1)" class="arrow right-right" [ngClass]="{'disabled': currentPage === maxPage - 1 || totalRecords === 0}">>></a>
        </li>
    </ul>
</div>
