import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictionaryAddresseeInterface} from "../../../interfaces/dictionary.interface";
import {environment} from "../../../../environments/environment";
import {BasicServiceInterface, FilteredListInterface} from "../../../interfaces/global.interface";
import {Params} from '@angular/router';
import {ServiceDataHandler} from '../../../classes/service-data-handler.class';

@Injectable({
    providedIn: 'root'
})
export class DictionaryAddresseesService extends ServiceDataHandler<DictionaryAddresseeInterface> implements BasicServiceInterface<DictionaryAddresseeInterface> {

    constructor(private http: HttpClient) {
        super();
    }

    public downloadList(): Observable<DictionaryAddresseeInterface[]> {
        return this.http.get<DictionaryAddresseeInterface[]>(environment.apiPath + 'addressees');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<DictionaryAddresseeInterface>> {
        return this.http.get<FilteredListInterface<DictionaryAddresseeInterface>>(environment.apiPath + 'addressees', {params});
    }

    public downloadListNotAuthorized(): Observable<DictionaryAddresseeInterface[]> {
        return this.http.get<DictionaryAddresseeInterface[]>(environment.apiPath + 'course-registrations/dictionary/addressees');
    }

    public downloadOne(id: string): Observable<DictionaryAddresseeInterface> {
        return this.http.get<DictionaryAddresseeInterface>(environment.apiPath + 'addressees/' + id);
    }

    public saveOne(data: DictionaryAddresseeInterface): Observable<DictionaryAddresseeInterface> {
        return this.http.post<DictionaryAddresseeInterface>(environment.apiPath + 'addressees', data);
    }

    public updateOne(data: DictionaryAddresseeInterface, dataId: string): Observable<DictionaryAddresseeInterface> {
        return this.http.put<DictionaryAddresseeInterface>(environment.apiPath + 'addressees/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'addressees/' + dataId);
    }
}
