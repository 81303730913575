import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {AuthService} from "./services/auth.service";
import {HttpRequestMethodType} from "./types/global.type";
import {catchError} from "rxjs/operators";
import {ToastService} from "./services/toast.service";
import {LogoutService} from './services/logout.service';
import {ModalLoaderService} from './parts/modal-loader/modal-loader.service';

interface MethodInterface {
    method: HttpRequestMethodType,
    link: string
}

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
    private static noNeedAuthorizationLinks: MethodInterface[] = [
        {method: 'GET', link: '/assets/'},
        {method: 'GET', link: '/course-registrations/dictionary'},
        {method: 'POST', link: '/course-registrations/closed'}
    ];
    private static noNeedError401: MethodInterface[] = [
        {method: 'POST', link: 'sessions'}
    ];
    private static noNeedError404: MethodInterface[] = [
        {method: 'GET', link: '/course-registrations/active-course'},
        {method: 'POST', link: '/course-registrations/validate-customer'},
        {method: 'GET', link: '/unsubscribe'}
    ];
    private static noNeedError500: MethodInterface[] = [
        {method: 'GET', link: 'course-registrations/dictionary/customers/single'}
    ];

    constructor(private authService: AuthService,
                private toastService: ToastService,
                private logoutService: LogoutService,
                private modalLoaderService: ModalLoaderService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers: HttpHeaders = new HttpHeaders();
        if (HttpAuthInterceptor.checkIfLinkContainsInArray(HttpAuthInterceptor.noNeedAuthorizationLinks, req)) headers = headers.set('Authorization', this.authService.getToken());

        return next.handle(HttpAuthInterceptor.addParamsToRequest(req.clone(), headers))
            .pipe(
                catchError((error: any) => {
                    this.modalLoaderService.hideModal();

                    switch (error.status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(error, req);
                        case 403:
                            return this.handle403Error(error);
                        case 404:
                            return this.handle404Error(error, req);
                        case 412:
                            return this.handle412Error(error);
                        case 500:
                            return this.handle500Error(error, req);
                    }
                    return throwError(error);
                })
            );
    }

    private static addParamsToRequest(request: HttpRequest<any>, headers: HttpHeaders): HttpRequest<any> {
        return request.clone({headers});
    }

    private static checkIfLinkContainsInArray(methods: MethodInterface[], req: HttpRequest<any>): boolean {
        return !methods.find((link: MethodInterface) => req.method === link.method && req.url.includes(link.link));
    }

    private handle400Error(error: any): Observable<any> {
        this.toastService.translateText('error', 'error.badRequest');
        return throwError(error);
    }

    private handle401Error(error: any, req: HttpRequest<any>): Observable<any> {
        if (HttpAuthInterceptor.checkIfLinkContainsInArray(HttpAuthInterceptor.noNeedError401, req)) this.toastService.translateText('warning', 'error.unauthorized');
        this.logoutService.submit();
        return throwError(error);
    }

    private handle403Error(error: any): Observable<any> {
        this.toastService.translateText('error', 'error.forbiddenResource');
        return throwError(error);
    }

    private handle404Error(error: any, req: HttpRequest<any>): Observable<any> {
        if (HttpAuthInterceptor.checkIfLinkContainsInArray(HttpAuthInterceptor.noNeedError404, req)) this.toastService.translateText('error', 'error.notFound');
        return throwError(error);
    }

    private handle412Error(error: any): Observable<any> {
        this.toastService.translateText('error', 'error.preconditionFailed');
        return throwError(error);
    }

    private handle500Error(error: any, req: HttpRequest<any>): Observable<any> {
        if (HttpAuthInterceptor.checkIfLinkContainsInArray(HttpAuthInterceptor.noNeedError500, req)) this.toastService.translateText('error', 'error.globalError');
        return throwError(error);
    }
}
