import { Injectable } from '@angular/core';
import {ModalHandler} from '../../classes/modal-handler.class';
import {CustomerInterface} from '../../interfaces/customer.interface';

@Injectable({
  providedIn: 'root'
})
export class ChooseCustomerModalService extends ModalHandler<CustomerInterface, CustomerInterface> {
  constructor() {
    super();
  }
}
