import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DictionaryFunctionInterface} from '../../../interfaces/dictionary.interface';
import {environment} from '../../../../environments/environment';
import {Params} from '@angular/router';
import {AnyInterface, BasicServiceInterface, FilteredListInterface} from '../../../interfaces/global.interface';
import {ServiceDataHandler} from '../../../classes/service-data-handler.class';

@Injectable({
    providedIn: 'root'
})
export class DictionaryFunctionsService extends ServiceDataHandler<DictionaryFunctionInterface> implements BasicServiceInterface<DictionaryFunctionInterface> {

    constructor(private http: HttpClient) {
        super();
    }

    public downloadList(): Observable<DictionaryFunctionInterface[]> {
        return this.http.get<DictionaryFunctionInterface[]>(environment.apiPath + 'customer-functions');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<DictionaryFunctionInterface>> {
        return this.http.get<FilteredListInterface<DictionaryFunctionInterface>>(environment.apiPath + 'customer-functions', {params});
    }

    public downloadListNotAuthorized(): Observable<DictionaryFunctionInterface[]> {
        return this.http.get<DictionaryFunctionInterface[]>(environment.apiPath + 'course-registrations/dictionary/customer-functions');
    }

    public downloadOne(id: string): Observable<DictionaryFunctionInterface> {
        return this.http.get<DictionaryFunctionInterface>(environment.apiPath + 'customer-functions/' + id);
    }

    public saveOne(data: DictionaryFunctionInterface): Observable<DictionaryFunctionInterface> {
        return this.http.post<DictionaryFunctionInterface>(environment.apiPath + 'customer-functions', data);
    }

    public updateOne(data: DictionaryFunctionInterface, dataId: string): Observable<DictionaryFunctionInterface> {
        return this.http.put<DictionaryFunctionInterface>(environment.apiPath + 'customer-functions/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'customer-functions/' + dataId);
    }

    public updatePositions(data: AnyInterface<number>): Observable<void> {
        return this.http.post<void>(environment.apiPath + 'customer-functions/sort', data);
    }
}
