import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./auth.service";
import {AppLinksService} from "./app-links.service";
import {LoggedUserService} from "./logged-user.service";

@Injectable({
    providedIn: 'root'
})
export class LogoutService {

    constructor(private router: Router,
                private authService: AuthService,
                private loggedUserService: LoggedUserService) {
    }

    public submitWithoutRouter(): void {
        this.authService.clearToken();
        this.loggedUserService.data = null as any;
    }

    public submit(): void {
        this.submitWithoutRouter();
        this.router.navigate([AppLinksService.authLogin]);
    }
}
