import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApplicationFormCloseCourseRegistrationInterface, ApplicationFormOpenCourseRegistrationInterface} from '../../../interfaces/application-form.interface';
import {environment} from '../../../../environments/environment';
import {Params} from '@angular/router';
import {FilteredListInterface} from '../../../interfaces/global.interface';
import {CourseInterface} from '../../../interfaces/course.interface';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CoursesRegistrationOpenService {

    constructor(private http: HttpClient) {
    }

    public downloadList(): Observable<ApplicationFormOpenCourseRegistrationInterface[]> {
        return this.http.get<ApplicationFormOpenCourseRegistrationInterface[]>(environment.apiPath + 'course-registrations/open');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<ApplicationFormOpenCourseRegistrationInterface>> {
        return this.http.get<FilteredListInterface<ApplicationFormOpenCourseRegistrationInterface>>(environment.apiPath + 'course-registrations/open', {params});
    }

    public downloadPureFilteredList(params: HttpParams): Observable<ApplicationFormCloseCourseRegistrationInterface[]> {
        return this.http.get<ApplicationFormCloseCourseRegistrationInterface[]>(environment.apiPath + 'course-registrations/open', {params});
    }

    public seen(id: string): Observable<CourseInterface> {
        return this.http.patch<CourseInterface>(environment.apiPath + `course-registrations/open/${id}/seen`, {});
    }
}
