import {Pipe, PipeTransform} from '@angular/core';
import {FormTypesEnum} from '../enums/form-types.enum';

@Pipe({
    name: 'enumNameById'
})
export class EnumNameByIdPipe implements PipeTransform {

    transform(value: number, enumType: 'formTypes'): string {
        let enums: any;
        if (enumType === 'formTypes') enums = FormTypesEnum;

        return (!!enums && (!!value || value === 0)) ? enums[value] : '';
    }
}
