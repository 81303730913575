import {Pipe, PipeTransform} from '@angular/core';
import {DictionaryCourseTemplateInterface} from '../interfaces/dictionary.interface';
import {DictionaryCourseTemplatesService} from '../pages/dictionary/dictionary-course-templates/dictionary-course-templates.service';

@Pipe({
    name: 'courseTemplatesById'
})
export class CourseTemplatesByIdPipe implements PipeTransform {

    constructor(private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService) {
    }

    transform(id: string): DictionaryCourseTemplateInterface {
        return this.dictionaryCourseTemplatesService.data.find((courseTemplate: DictionaryCourseTemplateInterface) => courseTemplate.id === id) || <DictionaryCourseTemplateInterface>{};
    }
}
