import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ApplicationCourseFormCloseService} from "./application-course-form-close.service";
import {UtilsService} from "../../../services/utils.service";
import {DictionaryAddresseeInterface, DictionaryAreaInterface, DictionaryCourseTemplateInterface, DictionaryEstablishmentTypeInterface, DictionaryFunctionInterface, DictionarySubjectInterface} from "../../../interfaces/dictionary.interface";
import {CountryStateInterface} from "../../../interfaces/global.interface";
import {DictionaryEstablishmentTypesService} from "../../../pages/dictionary/dictionary-establishment-types/dictionary-establishment-types.service";
import {CountryStatesService} from "../../../services/country-states.service";
import {DictionaryAddresseesService} from "../../../pages/dictionary/dictionary-addressees/dictionary-addressees.service";
import {CountriesService} from "../../../services/countries.service";
import {DictionaryCourseTemplatesService} from "../../../pages/dictionary/dictionary-course-templates/dictionary-course-templates.service";
import {CourseTypesEnum} from "../../../enums/course-types.enum";
import {CustomerAddressDataInterface, CustomerContactDataInterface, CustomerInterface, CustomerInvoiceBuyerDataInterface, CustomerInvoiceReceiverDataInterface} from '../../../interfaces/customer.interface';
import {CustomersFormService} from '../../../pages/customers/customers-form.service';
import {CustomersService} from '../../../pages/customers/customers.service';
import {HttpParams} from '@angular/common/http';
import {DictionaryAreasService} from '../../../pages/dictionary/dictionary-areas/dictionary-areas.service';
import {ActivatedRoute, Params} from '@angular/router';
import {FormTypesEnum} from '../../../enums/form-types.enum';
import {DictionaryFunctionsService} from '../../../pages/dictionary/dictionary-functions/dictionary-functions.service';
import {ApplicationCourseFormType} from '../../../types/global.type';
import * as _ from 'underscore';
import {EmailControlValidate} from '../../../form-validators/email.validator';

@Component({
    selector: 'app-application-course-form-close',
    templateUrl: './application-course-form-close.component.html',
    styleUrls: ['./application-course-form-close.component.scss']
})
export class ApplicationCourseFormCloseComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public formType: ApplicationCourseFormType = 'customer';
    @Input() public customer!: CustomerInterface;
    public form!: FormGroup;
    private subscriptions: Subscription[] = [];
    public statics: ApplicationCourseFormCloseStatic;

    constructor(private applicationCourseFormCloseService: ApplicationCourseFormCloseService,
                private formBuilder: FormBuilder,
                private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
                private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
                private dictionaryAddresseesService: DictionaryAddresseesService,
                private customersFormService: CustomersFormService,
                private customersService: CustomersService,
                private dictionaryAreasService: DictionaryAreasService,
                private dictionaryFunctionsService: DictionaryFunctionsService,
                private activatedRoute: ActivatedRoute) {
        this.statics = new ApplicationCourseFormCloseStatic(formBuilder, dictionaryCourseTemplatesService, dictionaryEstablishmentTypesService, dictionaryAddresseesService, customersFormService, dictionaryAreasService, dictionaryFunctionsService, activatedRoute);
        this.createForm();
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (UtilsService.ifApplicationFormTypeAdministration(this.formType) && !!this.customer && !!this.customer.placementHash) {
            this.getAddressDataFG().get('hash')?.patchValue(this.customer.placementHash);
            this.downloadCustomerByData('placementHash', 'hash');
        }
    }

    private createForm(): void {
        this.form = this.statics.createForm();
        this.formChanged();
        this.statics.getStates(CountriesService.valPL);
    }

    private formChanged(): void {
        this.setForm();
        this.form.valueChanges.subscribe(() => {
            this.setForm();
        });
    }

    private setForm(): void {
        this.applicationCourseFormCloseService.form = this.form;
    }

    public getContactDataFG(): FormGroup {
        return this.form.get('contactData') as FormGroup;
    }

    public getAddressDataFG(): FormGroup {
        return this.form.get('addressData') as FormGroup;
    }

    public getBuyerFG(): FormGroup {
        return this.form.get('invoiceBuyerData') as FormGroup;
    }

    public getRecipientFG(): FormGroup {
        return this.form.get('invoiceReceiverData') as FormGroup;
    }

    public ifError(name: string): boolean {
        return UtilsService.ifFGError(<FormGroup>this.form.get(name));
    }

    public ifContactDataError(name: string): boolean {
        return UtilsService.ifFGError(<FormGroup>this.getContactDataFG().get(name));
    }

    public ifAddressDataError(name: string): boolean {
        return UtilsService.ifFGError(<FormGroup>this.getAddressDataFG().get(name));
    }

    public clearCourseType(): void {
        this.form.get('courseType')?.setValue(null);
    }

    public downloadCustomerByData(propertyName: string, formPropertyName: string): void {
        if (this.formType === 'administration') {
            const value: string = this.getAddressDataFG().get(formPropertyName)?.value;
            if (!!value) this.toDownloadCustomerByData(propertyName, value);
        }
    }

    private toDownloadCustomerByData(propertyName: string, value: string): void {
        const params: HttpParams = new HttpParams().set('propertyName', propertyName).set('value', value);
        this.customersService.downloadByData(params).subscribe((customer: CustomerInterface) => {
            if (!!customer && !!customer.id) {
                // this.form.patchValue({
                    // customerId: customer.id
                // });
                this.getAddressDataFG().patchValue({
                    // hash: customer.placementHash,
                    buildingNumber: customer.addressData?.buildingNumber,
                    city: customer.addressData?.city,
                    district: customer.addressData?.district,
                    commune: customer.addressData?.commune,
                    localNumber: customer.addressData?.localNumber,
                    name: customer.addressData?.name,
                    email: customer.email,
                    phone: customer.phoneNumber,
                    postalCode: customer.addressData?.postalCode,
                    state: customer.addressData?.state,
                    street: customer.addressData?.street,
                    type: customer.addressData?.type
                });
                this.getContactDataFG().patchValue({
                    email: customer.contactData?.email,
                    firstName: customer.contactData?.firstName,
                    function: customer.contactData?.function,
                    lastName: customer.contactData?.lastName,
                    privatePhone: customer.contactData?.privatePhone,
                    workPhone: customer.contactData?.workPhone
                });
                this.getBuyerFG().patchValue({
                    buildingNumber: customer.invoiceBuyerData?.buildingNumber,
                    city: customer.invoiceBuyerData?.city,
                    localNumber: customer.invoiceBuyerData?.localNumber,
                    name: customer.invoiceBuyerData?.name,
                    nip: customer.invoiceBuyerData?.nip,
                    postalCode: customer.invoiceBuyerData?.postalCode,
                    street: customer.invoiceBuyerData?.street,
                });
                this.getRecipientFG().patchValue({
                    buildingNumber: customer.invoiceReceiverData?.buildingNumber,
                    city: customer.invoiceReceiverData?.city,
                    localNumber: customer.invoiceReceiverData?.localNumber,
                    name: customer.invoiceReceiverData?.name,
                    postalCode: customer.invoiceReceiverData?.postalCode,
                    street: customer.invoiceReceiverData?.street,
                });
            }
        });
    }
}

class ApplicationCourseFormCloseStatic {
    public states: CountryStateInterface[] = [];
    public courses: DictionaryCourseTemplateInterface[] = [];
    public addressees: DictionaryAddresseeInterface[] = [];
    public functions: DictionaryFunctionInterface[] = [];
    public subjects: DictionarySubjectInterface[] = [];
    public areas: DictionaryAreaInterface[] = [];
    public establishmentTypes: DictionaryEstablishmentTypeInterface[] = [];
    public formTypes = FormTypesEnum;

    constructor(private formBuilder: FormBuilder,
                private dictionaryCourseTemplatesService: DictionaryCourseTemplatesService,
                private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
                private dictionaryAddresseesService: DictionaryAddresseesService,
                private customersFormService: CustomersFormService,
                private dictionaryAreasService: DictionaryAreasService,
                private dictionaryFunctionsService: DictionaryFunctionsService,
                private activatedRoute: ActivatedRoute) {
        this.getData();
    }

    private getData(): void {
        this.courses = _.sortBy(UtilsService.copyWithoutReference<DictionaryCourseTemplateInterface[]>(this.dictionaryCourseTemplatesService.data).filter((course: DictionaryCourseTemplateInterface) => course.type === CourseTypesEnum.CLOSED), (course: DictionaryCourseTemplateInterface) => course.name.toLowerCase());
        this.addressees = this.dictionaryAddresseesService.data;
        this.establishmentTypes = this.dictionaryEstablishmentTypesService.data;
        this.areas = this.dictionaryAreasService.data;
        this.functions = this.dictionaryFunctionsService.data;
    }

    public getStates(country: number): void {
        this.states = CountryStatesService.getStatesByCountry(country);
    }

    public createForm(): FormGroup {
        const params: Params = this.activatedRoute.snapshot.queryParams;
        return this.formBuilder.group({
            courseArea: new FormControl(!!params['courseArea'] ? params['courseArea'] : null, Validators.required),
            form: new FormControl(null, Validators.required),
            courseType: new FormControl(!!params['courseType'] ? params['courseType'] : null, Validators.required),
            subscription: new FormControl(false),
            bonus: new FormControl(false),
            predictedParticipantCount: new FormControl(null, Validators.required),
            predictedTimeOfCourseFrom: new FormControl(null),
            predictedTimeOfCourseTo: new FormControl(null),
            note: new FormControl(''),
            customerId: new FormControl(null),
            email: new FormControl(null, EmailControlValidate),
            addressData: this.customersFormService.getCustomerAddressDataFG(<CustomerAddressDataInterface>{}, null),
            contactData: this.customersFormService.getCustomerContactDataFG(<CustomerContactDataInterface>{}),
            invoiceBuyerData: this.customersFormService.getCustomerInvoiceBuyerData(<CustomerInvoiceBuyerDataInterface>{}),
            invoiceReceiverData: this.customersFormService.getCustomerInvoiceReceiverData(<CustomerInvoiceReceiverDataInterface>{})
        });
    }
}
