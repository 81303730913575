import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-list-with-loader',
    templateUrl: './list-with-loader.component.html',
    styleUrls: ['./list-with-loader.component.scss']
})
export class ListWithLoaderComponent {
    @Input() public downloadedList: boolean = false;
    @Input() public listCounter: number | undefined = 0;
    @Input() public maxContentHeight: string = 'unset';
}
