import {Injectable} from '@angular/core';
import {CustomerAddressDataInterface, CustomerContactDataInterface, CustomerInvoiceBuyerDataInterface, CustomerInvoiceReceiverDataInterface} from '../../interfaces/customer.interface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmailControlValidate} from '../../form-validators/email.validator';
import {AtLeastOneValidator} from '../../form-validators/at-least-one.validator';
import {NipControlValidate} from '../../form-validators/nip.validator';
import {PostalCodeControlValidate} from '../../form-validators/postal-code.validator';
import {ApplicationFormOpenParticipantDataInterface} from "../../interfaces/application-form.interface";

@Injectable({
    providedIn: 'root'
})
export class CustomersFormService {

    constructor(private formBuilder: FormBuilder) {
    }

    public getCustomerAddressDataFG(data: CustomerAddressDataInterface, email: string | null): FormGroup {
        return this.formBuilder.group({
            hash: new FormControl(null),
            type: new FormControl(data.type, Validators.required),
            name: new FormControl(data.name, Validators.required),
            email: new FormControl(email || null, [Validators.required, EmailControlValidate]),
            phone: new FormControl(data.phone || null, Validators.required),
            state: new FormControl(data.state, Validators.required),
            district: new FormControl(data.district),
            commune: new FormControl(data.commune),
            city: new FormControl(data.city, Validators.required),
            postalCode: new FormControl(data.postalCode, [Validators.required, PostalCodeControlValidate]),
            street: new FormControl(data.street, Validators.required),
            buildingNumber: new FormControl(data.buildingNumber, Validators.required),
            localNumber: new FormControl(data.localNumber)
        });
    }

    public getCustomerContactDataFG(data: CustomerContactDataInterface): FormGroup {
        return this.formBuilder.group({
            firstName: new FormControl(data.firstName, Validators.required),
            lastName: new FormControl(data.lastName, Validators.required),
            function: new FormControl(data.function, Validators.required),
            email: new FormControl(data.email, [Validators.required, EmailControlValidate]),
            workPhone: new FormControl(data.workPhone || data['phone']),
            privatePhone: new FormControl(data.privatePhone)
        }, {
            validators: [AtLeastOneValidator(Validators.required, ['workPhone', 'privatePhone'], 'Phone')]
        });
    }

    public getCustomerInvoiceBuyerData(data: CustomerInvoiceBuyerDataInterface): FormGroup {
        return this.formBuilder.group({
            name: new FormControl(data.name),
            nip: new FormControl(data.nip, NipControlValidate),
            city: new FormControl(data.city),
            postalCode: new FormControl(data.postalCode, PostalCodeControlValidate),
            street: new FormControl(data.street),
            buildingNumber: new FormControl(data.buildingNumber),
            localNumber: new FormControl(data.localNumber)
        });
    }

    public getCustomerInvoiceReceiverData(data: CustomerInvoiceReceiverDataInterface): FormGroup {
        return this.formBuilder.group({
            name: new FormControl(data.name),
            city: new FormControl(data.city),
            postalCode: new FormControl(data.postalCode, PostalCodeControlValidate),
            street: new FormControl(data.street),
            buildingNumber: new FormControl(data.buildingNumber),
            localNumber: new FormControl(data.localNumber)
        });
    }
}
