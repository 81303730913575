<ng-container *ngIf="exportAll$ | async"></ng-container>

<div class="row basicList">
    <div class="col-12 basicList__filter listFilter">
        <form class="w-100" [formGroup]="filterForm" (submit)="download()" *ngIf="!!filterForm">
            <button type="button" class="basicButton basicButton__greenLight basicButton__icon basicButton__icon--filter" (click)="handleFilterBox()"></button>
            <button type="button" class="basicButton basicButton__greenDark float-right" (click)="exportList()">{{'button.export' | translate}}</button>

            <ng-container *ngIf="filterBoxFlag">
                <div class="filterBox">
                    <div class="row">
                        <div class="col-12 filterBox__actions">
                            <img src="/assets/images/close.svg" alt="Close" (click)="handleFilterBox()">
                        </div>

                        <div class="col-12 filterBox__filters">
                            <div class="row">
                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'subscriptionName' | translate}}</label>
                                    <input formControlName="name"/>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'institutionName' | translate}}</label>
                                    <input formControlName="placementName"/>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'address.city' | translate}}</label>
                                    <input formControlName="placementCity"/>
                                </div>

                                <div class="col-12 col-md-6 col-xl-3 formGroup">
                                    <label>{{'status' | translate}}</label>
                                    <select formControlName="subscriptionStatus">
                                        <option [ngValue]="null">{{'selectChoose.status' | translate}}</option>
                                        <ng-container *ngFor="let subscriptionStatus of subscriptionStatuses | enumToArray:false">
                                            <option [ngValue]="subscriptionStatuses[subscriptionStatus]">{{('enum.subscriptionStatus.' + subscriptionStatus) | translate}}</option>
                                        </ng-container>
                                    </select>
                                </div>

                                <div class="col-12 col-md-12 col-xl-6 formGroup">
                                    <label>{{'createdAt' | translate}}</label>
                                    <div class="formGroup__range">
                                        <span>od</span>
                                        <input type="date" formControlName="createdAtFrom"/>
                                        <span>do</span>
                                        <input type="date" formControlName="createdAtTo"/>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12 col-xl-6 formGroup">
                                    <label>{{'dateEnd' | translate}}</label>
                                    <div class="formGroup__range">
                                        <span>od</span>
                                        <input type="date" formControlName="endDateFrom"/>
                                        <span>do</span>
                                        <input type="date" formControlName="endDateTo"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 buttonActions">
                            <button type="button" class="basicButton basicButton__greenLight" (click)="resetFilter()">{{'button.reset' | translate}}</button>
                            <button type="submit" class="basicButton basicButton__greenDark">{{'button.filter' | translate}}</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>

    <div class="col-12">
        <app-list-with-loader [downloadedList]="downloadedList" [listCounter]="list.length">
            <div class="row">
                <div class="col-12">
                    <table class="spacingTable">
                        <thead>
                        <tr>
                            <th *ngIf="ifAccepted()">{{'subscriptionName' | translate}}</th>
                            <th>{{'institutionName' | translate}}</th>
                            <th>{{'institutionCity' | translate}}</th>
                            <th>{{'quota' | translate}}</th>
                            <th>{{'usedQuota' | translate}}</th>
                            <th>{{'leftQuota' | translate}}</th>
                            <th>{{'leftBonuses' | translate}}</th>
                            <th class="cursorPointer" (click)="sortBy.columnClicked('createdAt'); download();">{{'createdAt' | translate}} {{sortBy.getArrow('createdAt')}}</th>
                            <th *ngIf="ifAccepted()">{{'dateEnd' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let subscriptionRegistration of list">
                            <tr class="cursorPointer" [ngClass]="{'newOne': true}" [routerLink]="[linkToDetail, subscriptionRegistration.id]">
                                <td *ngIf="ifAccepted()">{{subscriptionRegistration.name}}</td>
                                <td>{{subscriptionRegistration.addressData?.name}}</td>
                                <td>{{subscriptionRegistration.addressData?.city}}</td>
                                <td>{{subscriptionRegistration.value.toFixed(2)}}</td>
                                <td>{{subscriptionRegistration.valueUsed.toFixed(2)}}</td>
                                <td>{{subscriptionRegistration.currentValue?.toFixed(2)}}</td>
                                <td>{{subscriptionRegistration.leftBonusPlaces}}</td>
                                <td>{{subscriptionRegistration.createdAt | dateFormat:'DD.MM.YYYY HH:mm:ss'}}</td>
                                <td *ngIf="ifAccepted()">{{subscriptionRegistration.endDate | dateFormat}}</td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>

                <div class="col-12">
                    <app-paginator [currentPage]="filterForm.get('page')?.value" [rows]="20" [totalRecords]="count" (changePageEvent)="changePage($event); download();"></app-paginator>
                </div>
            </div>
        </app-list-with-loader>
    </div>
</div>
