import { Injectable } from '@angular/core';
import {ModalHandler} from '../../classes/modal-handler.class';

export interface ModalLoaderData {
  title: string,
  text: string,
}

@Injectable({
  providedIn: 'root'
})
export class ModalLoaderService extends ModalHandler<ModalLoaderData, void> {

  constructor() {
    super();
  }
}
