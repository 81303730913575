import {Pipe, PipeTransform} from '@angular/core';
import {DictionaryAddresseeInterface} from '../interfaces/dictionary.interface';
import {DictionaryAddresseesService} from '../pages/dictionary/dictionary-addressees/dictionary-addressees.service';

@Pipe({
    name: 'addresseeById'
})
export class AddresseeByIdPipe implements PipeTransform {

    constructor(private dictionaryAddresseesService: DictionaryAddresseesService) {
    }

    transform(id: string | undefined): DictionaryAddresseeInterface {
        return this.dictionaryAddresseesService.data.find((addressee: DictionaryAddresseeInterface) => addressee.id === id) || <DictionaryAddresseeInterface>{};
    }
}
