import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from '../../../../../parts/modal/modal.component';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../../../services/utils.service';
import {DictionaryLocationsDetailAddNewModalService} from './dictionary-locations-detail-add-new-modal.service';
import {DictionaryLocationInterface} from '../../../../../interfaces/dictionary.interface';
import {FormGroup} from '@angular/forms';
import {ToastService} from '../../../../../services/toast.service';
import {DictionaryLocationsService} from '../../dictionary-locations.service';

@Component({
    selector: 'app-dictionary-locations-detail-add-new-modal',
    templateUrl: './dictionary-locations-detail-add-new-modal.component.html',
    styleUrls: ['./dictionary-locations-detail-add-new-modal.component.scss']
})
export class DictionaryLocationsDetailAddNewModalComponent implements OnInit, OnDestroy {
    @ViewChild('dictionaryLocationsAddNewModal', {static: true}) public dictionaryLocationsAddNewModal!: ModalComponent;
    public location!: DictionaryLocationInterface;
    private subscriptions: Subscription[] = [];
    public form!: FormGroup;

    constructor(private dictionaryLocationsDetailAddNewModalService: DictionaryLocationsDetailAddNewModalService,
                private toastService: ToastService,
                private dictionaryLocationsService: DictionaryLocationsService) {
        this.showModalChanged();
    }

    private showModalChanged(): void {
        this.subscriptions.push(this.dictionaryLocationsDetailAddNewModalService.showModalChanged().subscribe((location: DictionaryLocationInterface) => {
            this.location = location;
            this.dictionaryLocationsAddNewModal.show();
        }));
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    public formChanged(form: FormGroup): void {
        this.form = form;
    }

    public submit(): void {
        if (!!this.form && !!this.form?.valid) !!this.form.get('id') ? this.update() : this.save();
        else this.toastService.fillAllFormData();
    }

    private save(): void {
        this.dictionaryLocationsService.saveOne(DictionaryLocationsService.prepareDTO(this.form.value)).subscribe((location: DictionaryLocationInterface) => {
            this.toastService.success.save();
            this.close(location);
        });
    }

    private update(): void {
        this.dictionaryLocationsService.updateOne(DictionaryLocationsService.prepareDTO(this.form.value), this.form.get('id')?.value).subscribe((location: DictionaryLocationInterface) => {
            this.toastService.success.update();
            this.close(location);
        });
    }

    private close(data: DictionaryLocationInterface): void {
        this.dictionaryLocationsDetailAddNewModalService.hideModal(data);
        this.dictionaryLocationsAddNewModal.hide();
    }
}
