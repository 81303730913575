import {ModuleWithProviders, NgModule, Type} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutofocusDirective} from "./directives/autofocus.directive";
import {SafePipe} from "./pipes/safe.pipe";
import {TranslatePipe} from "./pipes/translate.pipe";
import {TranslateService} from "./services/translate.service";
import {MenuLeftComponent} from './parts/menu/menu-left/menu-left.component';
import {MenuTopComponent} from './parts/menu/menu-top/menu-top.component';
import {MenuLeftStateService} from "./parts/menu/menu-left/menu-left-state.service";
import {MenuTopViewActionService} from "./parts/menu/menu-top/menu-top-view-action.service";
import {RouterModule} from "@angular/router";
import {BoxWithHeaderComponent} from './parts/box-with-header/box-with-header.component';
import {DictionaryLocationsService} from "./pages/dictionary/dictionary-locations/dictionary-locations.service";
import {ListWithLoaderComponent} from './parts/list-with-loader/list-with-loader.component';
import {LoaderComponent} from './parts/loader/loader.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoaderWrapperComponent} from './parts/loader-wrapper/loader-wrapper.component';
import {AppLinksService} from "./services/app-links.service";
import {DictionaryAddresseesService} from "./pages/dictionary/dictionary-addressees/dictionary-addressees.service";
import {DictionaryAreasService} from "./pages/dictionary/dictionary-areas/dictionary-areas.service";
import {DictionarySubjectsService} from "./pages/dictionary/dictionary-subjects/dictionary-subjects.service";
import {ActionDropboxComponent} from './parts/action-dropbox/action-dropbox.component';
import {ViewStateComponent} from './parts/view-state/view-state.component';
import {ApplicationCourseFormOpenComponent} from './parts/application-course-forms/application-course-form-open/application-course-form-open.component';
import {ApplicationCourseFormCloseComponent} from './parts/application-course-forms/application-course-form-close/application-course-form-close.component';
import {ApplicationCourseFormCloseService} from "./parts/application-course-forms/application-course-form-close/application-course-form-close.service";
import {ApplicationCourseFormOpenService} from "./parts/application-course-forms/application-course-form-open/application-course-form-open.service";
import {DictionaryEstablishmentTypesService} from "./pages/dictionary/dictionary-establishment-types/dictionary-establishment-types.service";
import {EnumToArrayPipe} from './pipes/enum-to-array.pipe';
import {CountriesService} from "./services/countries.service";
import {CountryStatesService} from "./services/country-states.service";
import {PostalCodeMaskDirective} from './directives/postal-code-mask.directive';
import {OnlyIntegersDirective} from './directives/only-integers.directive';
import {BoxWithBackgroundComponent} from './parts/box-with-background/box-with-background.component';
import {NotificationService} from "./pages/notification/notification.service";
import {CoursesRegistrationClosedService} from './pages/courses/courses-registration-closed/courses-registration-closed.service';
import {AddresseeByIdPipe} from './pipes/addressee-by-id.pipe';
import {CourseTemplatesByIdPipe} from './pipes/course-templates-by-id.pipe';
import {EstablishmentTypesByIdPipe} from './pipes/establishment-types-by-id.pipe';
import {SubjectByIdPipe} from './pipes/subject-by-id.pipe';
import {ProvinceByIdPipe} from './pipes/province-by-id.pipe';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {CourseCalendarComponent} from './parts/course-calendar/course-calendar.component';
import {FileUploadService} from './services/file-upload.service';
import {ModalComponent} from './parts/modal/modal.component';
import {NgxEditorModule} from 'ngx-editor';
import {PaginatorComponent} from './parts/paginator/paginator.component';
import {PaginatorService} from './parts/paginator/paginator.service';
import {InputTrimModule} from 'ng2-trim-directive';
import {DictionaryLocationsDetailAddNewFormComponent} from './pages/dictionary/dictionary-locations/dictionary-locations-detail/dictionary-locations-detail-add-new-form/dictionary-locations-detail-add-new-form.component';
import {DictionaryLocationsDetailAddNewModalComponent} from './pages/dictionary/dictionary-locations/dictionary-locations-detail/dictionary-locations-detail-add-new-modal/dictionary-locations-detail-add-new-modal.component';
import {DictionaryLocationsDetailAddNewModalService} from './pages/dictionary/dictionary-locations/dictionary-locations-detail/dictionary-locations-detail-add-new-modal/dictionary-locations-detail-add-new-modal.service';
import {EnumNameByIdPipe} from './pipes/enum-name-by-id.pipe';
import {AngularMaterialModule} from './angular-material.module';
import {FunctionByIdPipe} from './pipes/function-by-id.pipe';
import {CoursesListGlobalComponent} from './parts/courses-list/courses-list.component';
import {SortByPipe} from './pipes/sort-by.pipe';
import {NgSelectModule} from '@ng-select/ng-select';
import {CoursesRegistrationClosedListComponent} from './parts/courses-registration-closed-list/courses-registration-closed-list.component';
import {CoursesRegistrationOpenListComponent} from './parts/courses-registration-open-list/courses-registration-open-list.component';
import {CsvService} from './services/csv.service';
import {ExcelService} from './services/excel.service';
import {ClipboardModule} from 'ngx-clipboard';
import {ApplicationCourseFormSubscriptionComponent} from './parts/application-course-forms/application-course-form-subscription/application-course-form-subscription.component';
import {ApplicationCourseFormSubscriptionService} from './parts/application-course-forms/application-course-form-subscription/application-course-form-subscription.service';
import {SubscriptionsRegistrationListModelComponent} from './parts/subscriptions-registration-list-model/subscriptions-registration-list-model.component';
import {NgChartsModule} from 'ng2-charts';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {CoursesDetailEditSourceRegistrationOpenComponent} from './pages/courses/courses-detail/courses-detail-edit-source-registration-open/courses-detail-edit-source-registration-open.component';
import {FilterChosenComponent} from './parts/filter-chosen/filter-chosen.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {CoursesListComponent} from './pages/courses/courses-list/courses-list.component';
import {ChooseCustomerModalComponent} from './parts/choose-customer-modal/choose-customer-modal.component';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {FileDownloadService} from './services/file-download.service';
import {ModalLoaderComponent} from './parts/modal-loader/modal-loader.component';
import {YesNoLabelPipe} from './pipes/yes-no-label.pipe';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

const MODULES: Type<any>[] = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  FullCalendarModule,
  NgxEditorModule,
  InputTrimModule,
  AngularMaterialModule,
  NgSelectModule,
  ClipboardModule,
  NgChartsModule,
  NgxSpinnerModule,
  FroalaEditorModule,
  FroalaViewModule
];

const DIRECTIVES: Type<any>[] = [
  AutofocusDirective,
  PostalCodeMaskDirective,
  OnlyIntegersDirective
];

const PIPES: Type<any>[] = [
  YesNoLabelPipe,
  SafePipe,
  TranslatePipe,
  EnumToArrayPipe,
  AddresseeByIdPipe,
  CourseTemplatesByIdPipe,
  EstablishmentTypesByIdPipe,
  SubjectByIdPipe,
  ProvinceByIdPipe,
  FunctionByIdPipe,
  EnumNameByIdPipe,
  FunctionByIdPipe,
  SortByPipe,
  DateFormatPipe
];

const COMPONENTS: Type<any>[] = [
  ModalLoaderComponent,
  ChooseCustomerModalComponent,
  CoursesListComponent,
  MenuLeftComponent,
  MenuTopComponent,
  BoxWithHeaderComponent,
  ListWithLoaderComponent,
  LoaderComponent,
  LoaderWrapperComponent,
  ActionDropboxComponent,
  ViewStateComponent,
  ApplicationCourseFormOpenComponent,
  ApplicationCourseFormCloseComponent,
  BoxWithBackgroundComponent,
  CourseCalendarComponent,
  ModalComponent,
  PaginatorComponent,
  DictionaryLocationsDetailAddNewFormComponent,
  DictionaryLocationsDetailAddNewModalComponent,
  CoursesListGlobalComponent,
  CoursesRegistrationClosedListComponent,
  CoursesRegistrationOpenListComponent,
  ApplicationCourseFormSubscriptionComponent,
  SubscriptionsRegistrationListModelComponent,
  CoursesDetailEditSourceRegistrationOpenComponent,
  FilterChosenComponent
];

const SERVICES: Type<any>[] = [
  TranslateService,
  MenuLeftStateService,
  MenuTopViewActionService,
  DictionaryLocationsService,
  AppLinksService,
  DictionaryAddresseesService,
  DictionaryAreasService,
  DictionarySubjectsService,
  DictionaryEstablishmentTypesService,
  DictionaryAreasService,
  ApplicationCourseFormOpenService,
  ApplicationCourseFormCloseService,
  CountriesService,
  CountryStatesService,
  NotificationService,
  CoursesRegistrationClosedService,
  FileUploadService,
  FileDownloadService,
  PaginatorService,
  DictionaryLocationsDetailAddNewModalService,
  CsvService,
  ExcelService,
  ApplicationCourseFormSubscriptionService
];


@NgModule({
  declarations: [
    DIRECTIVES,
    PIPES,
    COMPONENTS
  ],
  imports: [
    MODULES
  ],
  exports: [
    MODULES,
    DIRECTIVES,
    PIPES,
    COMPONENTS
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SERVICES
      ]
    }
  }
}
