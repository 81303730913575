import {Pipe, PipeTransform} from '@angular/core';
import {DictionaryEstablishmentTypeInterface} from '../interfaces/dictionary.interface';
import {DictionaryEstablishmentTypesService} from '../pages/dictionary/dictionary-establishment-types/dictionary-establishment-types.service';

@Pipe({
    name: 'establishmentTypesById'
})
export class EstablishmentTypesByIdPipe implements PipeTransform {

    constructor(private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService) {
    }

    transform(id: string): DictionaryEstablishmentTypeInterface {
        return this.dictionaryEstablishmentTypesService.data.find((establishmentType: DictionaryEstablishmentTypeInterface) => establishmentType.id === id) || <DictionaryEstablishmentTypeInterface>{};
    }
}
