import {Pipe, PipeTransform} from '@angular/core';
import {CountryStatesService} from '../services/country-states.service';
import {CountriesService} from '../services/countries.service';
import {CountryStateInterface} from '../interfaces/global.interface';

@Pipe({
    name: 'provinceById'
})
export class ProvinceByIdPipe implements PipeTransform {

    constructor() {
    }

    transform(id: number | undefined): CountryStateInterface {
        return CountryStatesService.getStatesByCountry(CountriesService.valPL).find((state: CountryStateInterface) => state.id === id) || <CountryStateInterface>{};
    }
}
