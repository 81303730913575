import {Injectable} from '@angular/core';
import {ModalHandler} from '../../../../../classes/modal-handler.class';
import {DictionaryLocationInterface} from '../../../../../interfaces/dictionary.interface';

@Injectable({
    providedIn: 'root'
})
export class DictionaryLocationsDetailAddNewModalService extends ModalHandler<DictionaryLocationInterface, DictionaryLocationInterface> {
    constructor() {
        super();
    }
}
