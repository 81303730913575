import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictionaryAreaInterface} from "../../../interfaces/dictionary.interface";
import {environment} from "../../../../environments/environment";
import {BasicServiceInterface, FilteredListInterface} from "../../../interfaces/global.interface";
import {Params} from '@angular/router';
import {ServiceDataHandler} from '../../../classes/service-data-handler.class';

@Injectable({
    providedIn: 'root'
})
export class DictionaryAreasService extends ServiceDataHandler<DictionaryAreaInterface> implements BasicServiceInterface<DictionaryAreaInterface> {

    constructor(private http: HttpClient) {
        super();
    }

    public downloadList(): Observable<DictionaryAreaInterface[]> {
        return this.http.get<DictionaryAreaInterface[]>(environment.apiPath + 'course-areas');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<DictionaryAreaInterface>> {
        return this.http.get<FilteredListInterface<DictionaryAreaInterface>>(environment.apiPath + 'course-areas', {params});
    }

    public downloadListNotAuthorized(): Observable<DictionaryAreaInterface[]> {
        return this.http.get<DictionaryAreaInterface[]>(environment.apiPath + 'course-registrations/dictionary/areas');
    }

    public downloadOne(id: string): Observable<DictionaryAreaInterface> {
        return this.http.get<DictionaryAreaInterface>(environment.apiPath + 'course-areas/' + id);
    }

    public saveOne(data: DictionaryAreaInterface): Observable<DictionaryAreaInterface> {
        return this.http.post<DictionaryAreaInterface>(environment.apiPath + 'course-areas', data);
    }

    public updateOne(data: DictionaryAreaInterface, dataId: string): Observable<DictionaryAreaInterface> {
        return this.http.put<DictionaryAreaInterface>(environment.apiPath + 'course-areas/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'course-areas/' + dataId);
    }
}
