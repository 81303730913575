import {Pipe, PipeTransform} from '@angular/core';
import {OrderType} from '../types/global.type';

@Pipe({
    name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

    transform<T>(value: T[], order: OrderType = 'ASC', column: string): T[] {
        if (value.length <= 1) return value;
        if (!column || column === '') {
            if (order === 'ASC') return value.sort();
            else return value.sort().reverse();
        }

        if (order === 'ASC') return value.sort((word1: T, word2: T) => word1[column]?.localeCompare(word2[column] || null));
        else return value.sort((word1: T, word2: T) => word1[column]?.localeCompare(word2[column] || null)).reverse();
    }
}
