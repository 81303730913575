import {Component, ElementRef, HostListener, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-action-dropbox',
    templateUrl: './action-dropbox.component.html',
    styleUrls: ['./action-dropbox.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ActionDropboxComponent implements OnInit {
    @Input() public actionDropBoxStyles: any;
    @Input() public idParent!: string;
    public flag: boolean = false;

    @HostListener('document:click', ['$event']) clickOut(event) {
        const parentEl: HTMLElement = <HTMLElement>document.getElementById(this.idParent);
        if (!!parentEl && parentEl.contains(this.elementRef.nativeElement) && parentEl.contains(event.target)) this.flag = !this.flag;
        else this.flag = false;
    }

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit(): void {
    }

}
