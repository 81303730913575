<div class="modal fade show" tabindex="-1" role="dialog" [ngStyle]="style">
    <div class="modal-dialog" role="document" [style.max-width]="widthOfModal">
        <div class="modal-content" [style.width]="widthOfModal">
            <div class="modal-header" *ngIf="showModalHeader">
                <h3 class="modal-title" [ngClass]="{'maxWidth100': !showCloseButton}" *ngIf="!!title">{{title}}</h3>
                <img (click)="hide(true)" *ngIf="showCloseButton" alt="Close" src="/assets/images/close.svg" />
            </div>

            <div class="container-fluid">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
