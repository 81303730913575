import {Pipe, PipeTransform} from '@angular/core';
import {DictionarySubjectsService} from '../pages/dictionary/dictionary-subjects/dictionary-subjects.service';
import {DictionarySubjectInterface} from '../interfaces/dictionary.interface';

@Pipe({
    name: 'subjectById'
})
export class SubjectByIdPipe implements PipeTransform {

    constructor(private dictionarySubjectsService: DictionarySubjectsService) {
    }

    transform(id: string): DictionarySubjectInterface {
        return this.dictionarySubjectsService.data.find((subject: DictionarySubjectInterface) => subject.id === id) || <DictionarySubjectInterface>{};
    }
}
