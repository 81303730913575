import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteNotFoundComponent} from "./pages/route-not-found/route-not-found.component";
import {NeedAuthGuard} from "./auths/need-auth.guard";
import {LogoutUserGuard} from "./auths/logout-user.guard";

const routes: Routes = [
    {
        path: 'application-form', canActivate: [LogoutUserGuard], canActivateChild: [LogoutUserGuard],
        loadChildren: () => import('./pages/application-course-form-not-auth/application-course-form-not-auth.module').then(module => module.ApplicationCourseFormNotAuthModule)
    },
    {
        path: 'unsubscribe-mailer', canActivate: [LogoutUserGuard], canActivateChild: [LogoutUserGuard],
        loadChildren: () => import('./pages/unsubscribe-mailer/unsubscribe-mailer.module').then(module => module.UnsubscribeMailerModule)
    },
    {
        path: 'auth', canActivate: [LogoutUserGuard], canActivateChild: [LogoutUserGuard],
        loadChildren: () => import('./pages/auth/auth.module').then(module => module.AuthModule)
    },
    {
        path: 'dashboard', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(module => module.DashboardModule)
    },
    {
        path: 'courses', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/courses/courses.module').then(module => module.CoursesModule)
    },
    {
        path: 'dictionary', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/dictionary/dictionary.module').then(module => module.DictionaryModule)
    },
    {
        path: 'users', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/users/users.module').then(module => module.UsersModule)
    },
    {
        path: 'trainers', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/trainers/trainers.module').then(module => module.TrainersModule)
    },
    {
        path: 'customers', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/customers/customers.module').then(module => module.CustomersModule)
    },
    {
        path: 'notification', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/notification/notification.module').then(module => module.NotificationModule)
    },
    {
        path: 'subscription', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/subscriptions/subscriptions.module').then(module => module.SubscriptionsModule)
    },
    {
        path: 'mailer', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/mailer/mailer.module').then(module => module.MailerModule)
    },
    {
        path: 'statistics', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        loadChildren: () => import('./pages/statistics/statistics.module').then(module => module.StatisticsModule)
    },
    {
        path: '', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        component: RouteNotFoundComponent
    },
    {
        path: '**', canActivate: [NeedAuthGuard], canActivateChild: [NeedAuthGuard],
        component: RouteNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
