import {NgModule, Type} from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';

const MODULES: Type<any>[] = [
    DragDropModule
];


@NgModule({
    imports: [
        MODULES
    ],
    exports: [
        MODULES
    ]
})
export class AngularMaterialModule {
}
