import {Component, OnDestroy} from '@angular/core';
import {WindowService} from "./services/window.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {UtilsService} from "./services/utils.service";
import {MenuLeftStateService} from "./parts/menu/menu-left/menu-left-state.service";
import {LinkAvailableService} from "./services/link-available.service";
import {MenuTopViewActionService} from "./parts/menu/menu-top/menu-top-view-action.service";
import {NgSelectConfig} from '@ng-select/ng-select';
import * as moment from 'moment';
import 'moment/locale/pl';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
    private subscriptions: Subscription[] = [];
    public menusStates: MenusStates;
    public ifAuthLinks: boolean = false;

    constructor(private windowService: WindowService,
                private router: Router,
                private menuLeftStateService: MenuLeftStateService,
                private linkAvailableService: LinkAvailableService,
                private menuTopViewActionService: MenuTopViewActionService,
                private config: NgSelectConfig) {
        this.menusStates = new MenusStates(menuLeftStateService, linkAvailableService);
        this.routerChanged();
        this.customizeNgSelect();
        moment().locale('pl');
    }

    private routerChanged(): void {
        this.subscriptions.push(this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) this.menuTopViewActionService.show = false;
            if (event instanceof NavigationEnd) {
                this.menusStates.routerChanged(event);
                this.ifAuthLinks = event.url.includes('/auth');
            }
        }));
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    public onResize(event: Event): void {
        this.windowService.changeWindow();
    }

    private customizeNgSelect(): void {
        this.config.notFoundText = 'Nie znaleziono elementów';
        this.config.appendTo = 'body';
    }
}

class MenusStates {
    public show: boolean = false;

    constructor(private menuLeftStateService: MenuLeftStateService,
                private linkAvailableService: LinkAvailableService) {
        this.showChanged();
    }

    private showChanged(): void {
        this.menuLeftStateService.showChanged().subscribe(() => {
            this.show = this.menuLeftStateService.show;
        });
    }

    public routerChanged(event: NavigationEnd): void {
        this.menuLeftStateService.show = this.linkAvailableService.checkLinkIfAvailable(event.urlAfterRedirects);
    }
}
