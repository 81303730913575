import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppLinksService {
    public static readonly applicationFormNotAuthOpen: string = '/application-form/open';
    public static readonly applicationFormNotAuthClose: string = '/application-form/close';
    public static readonly applicationFormNotAuthSubscription: string = '/application-form/subscription';

    public static readonly authLogin: string = '/auth/login';
    public static readonly authPasswordReset: string = '/auth/password-reset';
    public static readonly authPasswordChange: string = '/auth/password-change';

    public static readonly dashboard: string = '/dashboard';

    public static readonly coursesList: string = '/courses/list';
    // public static readonly coursesOpenList: string = '/courses/list/open';
    // public static readonly coursesClosedList: string = '/courses/list/closed';
    public static readonly coursesDetail: string = '/courses/detail';

    public static readonly coursesRegistrationOpenList: string = '/courses/registration-open/list';
    public static readonly coursesRegistrationClosedList: string = '/courses/registration-closed/list';
    public static readonly coursesRegistrationClosedDetail: string = '/courses/registration-closed/detail';

    public static readonly dictionaryLocationList: string = '/dictionary/locations/list';
    public static readonly dictionaryLocationDetail: string = '/dictionary/locations/detail';

    public static readonly dictionaryAddresseeList: string = '/dictionary/addressees/list';
    public static readonly dictionaryAddresseeDetail: string = '/dictionary/addressees/detail';

    public static readonly dictionaryCourseTemplatesList: string = '/dictionary/course-templates/list';
    public static readonly dictionaryCourseTemplatesDetail: string = '/dictionary/course-templates/detail';

    public static readonly dictionaryAreaList: string = '/dictionary/areas/list';
    public static readonly dictionaryAreaDetail: string = '/dictionary/areas/detail';

    public static readonly dictionarySubjectList: string = '/dictionary/subjects/list';
    public static readonly dictionarySubjectDetail: string = '/dictionary/subjects/detail';

    public static readonly dictionaryEstablishmentTypeList: string = '/dictionary/establishment-types/list';
    public static readonly dictionaryEstablishmentTypeDetail: string = '/dictionary/establishment-types/detail';

    public static readonly dictionaryFunctionsList: string = '/dictionary/functions/list';
    public static readonly dictionaryFunctionsDetail: string = '/dictionary/functions/detail';

    public static readonly usersList: string = '/users/list';
    public static readonly usersDetail: string = '/users/detail';

    public static readonly trainersList: string = '/trainers/list';
    public static readonly trainersDetail: string = '/trainers/detail';

    public static readonly customersList: string = '/customers/list';
    public static readonly customersIndividualList: string = '/customers/list/individual';
    public static readonly customersEstablishmentList: string = '/customers/list/establishment';
    public static readonly customersDetail: string = '/customers/detail';

    public static readonly subscriptionList: string = '/subscription/list';
    public static readonly subscriptionRegistrationList: string = '/subscription/registration/list';
    public static readonly subscriptionRegistrationDetail: string = '/subscription/registration/detail';

    public static readonly mailerList: string = '/mailer/list';
    public static readonly mailerDetail: string = '/mailer/detail';

    public static readonly customerExternalList: string = '/mailer/external-customer-list';
    public static readonly customerExternalDetail: string = '/mailer/external-customer-detail';

    public static readonly unsubscribeMailer: string = '/unsubscribe-mailer';

    public static readonly statisticsStatistics: string = '/statistics/statistics';
    public static readonly statisticsReporting: string = '/statistics/reporting';

    public static readonly openCloseCoursesRegulation: string = 'https://www.empiria.edu.pl/kontakt/regulamin-szkole%C5%84-otwartych-i-zamkni%C4%99tych';
    public static readonly privacyAndRODO: string = 'https://www.empiria.edu.pl/kontakt/polityka-prywatno%C5%9Bci-i-rodo';
}
