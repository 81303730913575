<div class="row">
    <div class="col-12 d-flex justify-content-end">
        <button class="basicButton basicButton__greenDark mr-3" (click)="exportEmit.emit()">{{'button.export' | translate}}</button>
        <input [(ngModel)]="calendarDate" type="date" (ngModelChange)="calendarDateChanged()"/>
    </div>
</div>

<div class="calendarWrapper">
    <full-calendar #calendarComponent [options]="calendarOptions"></full-calendar>

    <ngx-spinner
            [fullScreen]="false"
            bdColor="rgba(51,51,51,0.8)"
            size="medium"
            color="#fff"
            type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">{{'loadingData' | translate}}...</p>
    </ngx-spinner>
</div>
