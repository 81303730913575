import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MenuLeftStateService {
    private _show!: boolean;
    private show$: Subject<void> = new Subject<void>();

    private _showMobile!: boolean;
    private showMobile$: Subject<void> = new Subject<void>();

    constructor() {
    }

    public set show(_show: boolean) {
        this._show = _show;
        this.show$.next();
    }

    public get show(): boolean {
        return this._show;
    }

    public showChanged(): Observable<void> {
        return this.show$.asObservable();
    }

    public set showMobile(_showMobile: boolean) {
        this._showMobile = _showMobile;
        this.showMobile$.next();
    }

    public get showMobile(): boolean {
        return this._showMobile;
    }

    public showMobileChanged(): Observable<void> {
        return this.showMobile$.asObservable();
    }
}
