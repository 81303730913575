import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import {CourseDocumentInterface} from '../interfaces/course.interface';
import * as FileSaver from 'file-saver';
import {Observable} from 'rxjs';
import {UtilsService} from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  public downloadFile(file: ArrayBuffer | Blob, fileName: string): void {
    FileSaver.saveAs(file, fileName);
  }

  public downloadFilesInZip(files: ArrayBuffer[], documents: CourseDocumentInterface[], zipName: string, extensions: string = ''): void {
    let zip = new JSZip();
    for (let i in files) zip.file(documents[i].name + extensions, files[i]);
    zip.generateAsync({ type: 'blob' }).then((content: Blob) => this.downloadFile(content, `${zipName}.zip`));
  }
}
