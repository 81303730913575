import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FooterStaticService {
  public static generalFooter: string =
    '<p>Pozostajemy w kontakcie</p>\n' +
    '<p><img alt="" src="https://www.empiria.edu.pl/images/design/header/empiria-logo.png" style="height:64px; width:250px" /></p>\n' +
    '<p><strong>Salon Edukacyjny EMPIRIA Sp. z o.o.</strong></p>\n' +
    '<p><strong>Akredytowany Ośrodek Doskonalenia Nauczycieli</strong></p>\n' +
    '<p>ul. W. Broniewskiego 12</p>\n' +
    '<p>71-460 Szczecin</p>\n' +
    '<p>Sekretariat - 91 434 00 01</p>\n' +
    '<p>Koordynator szkoleń otwartych – tel.: 736 849 535</p>\n' +
    '<p>Koordynator szkoleń zamkniętych - tel.: 579 641 522</p>\n' +
    '<p>Księgowość/faktury tel.: 736 855 624</p>\n' +
    '<p>NIP 8513079407</p>\n' +
    '<p><a href="https://www.empiria.edu.pl">www.empiria.edu.pl</a></p>\n' +
    '<p><span style="font-size:8px">Administratorem Państwa danych osobowych jest Salon Edukacyjny EMPIRIA Sp. z o.o. ul. W. Broniewskiego 12  w  Szczecinie.</span></p>\n' +
    '<p><span style="font-size:8px">Więcej informacji w przedmiocie przetwarzania oraz ochrony danych osobowych zamieszczamy pod adresem&nbsp;<a href="https://www.empiria.edu.pl">www.empiria.edu.pl</a></span></p>';

  public static openCourseFooter: string =
    '<p><strong>Gabriela Helińska</strong></p>\n' +
    '<p>Prezes Zarządu</p>\n' +
    '<p>Dyrektor ODN</p>\n' +
    '<p><img alt="" src="https://www.empiria.edu.pl/images/design/header/empiria-logo.png" style="height:64px; width:250px" /></p>\n' +
    '<p><strong>Salon Edukacyjny EMPIRIA Sp. z o.o.</strong></p>\n' +
    '<p><strong>Akredytowany Ośrodek Doskonalenia Nauczycieli</strong></p>\n' +
    '<p>ul. W. Broniewskiego 12</p>\n' +
    '<p>71-460 Szczecin</p>\n' +
    '<p>tel./fax. (91) 434 00 01; +48 693 543 256</p>\n' +
    '<p><a href="mailto:gabrielahelinska@empiria.edu.pl">gabrielahelinska@empiria.edu.pl</a></p>\n' +
    '<p><a href="https://www.empiria.edu.pl">www.empiria.edu.pl</a></p>\n' +
    '<br>' +
    '<p><span style="font-size:8px">Administratorem Państwa danych osobowych jest Salon Edukacyjny EMPIRIA Sp. z o.o. ul. W. Broniewskiego 12  w  Szczecinie.</span></p>\n' +
    '<p><span style="font-size:8px">Więcej informacji w przedmiocie przetwarzania oraz ochrony danych osobowych zamieszczamy pod adresem&nbsp;<a href="https://www.empiria.edu.pl">www.empiria.edu.pl</a></span></p>';

  public static closedCourseFooter: string =
    '<p><strong>Bożena Paprota</strong></p>\n' +
    '<p>Ekspertka ds. organizacji szkoleń zamkniętych</p>\n' +
    '<p><img alt="" src="https://www.empiria.edu.pl/images/design/header/empiria-logo.png" style="height:64px; width:250px" /></p>\n' +
    '<p><strong>Salon Edukacyjny EMPIRIA Sp. z o.o.</strong></p>\n' +
    '<p><strong>Akredytowany Ośrodek Doskonalenia Nauczycieli</strong></p>\n' +
    '<p>ul. W. Broniewskiego 12</p>\n' +
    '<p>71-460 Szczecin</p>\n' +
    '<p>tel./fax. (91) 434 00 01; +48 579 641 522</p>\n' +
    '<p><a href="mailto:bozenapaprota@empiria.edu.pl">bozenapaprota@empiria.edu.pl</a></p>\n' +
    '<p><a href="https://www.empiria.edu.pl">www.empiria.edu.pl</a></p>\n' +
    '<br>' +
    '<p><span style="font-size:8px">Administratorem Państwa danych osobowych jest Salon Edukacyjny EMPIRIA Sp. z o.o. ul. W. Broniewskiego 12 w Szczecinie.</span></p>\n' +
    '<p><span style="font-size:8px">Więcej informacji w przedmiocie przetwarzania oraz ochrony danych osobowych zamieszczamy pod adresem&nbsp;<a href="https://www.empiria.edu.pl">www.empiria.edu.pl</a></span></p>';

  public static emailFooter: string =
    '<p><img alt="" src="https://www.empiria.edu.pl/images/design/header/empiria-logo.png" style="height:64px; width:250px" /></p>\n' +
    '<p><strong>Salon Edukacyjny EMPIRIA Sp. z o.o.</strong></p>\n' +
    '<p><strong>Akredytowany Ośrodek Doskonalenia Nauczycieli</strong></p>\n' +
    '<p>ul. W. Broniewskiego 12</p>\n' +
    '<p>71-460 Szczecin</p>\n' +
    '<p>tel./fax. (91) 434 00 01; +48 579 641 522</p>\n' +
    '<p>NIP 851 30 79 407</p>\n' +
    '<p><a href="https://www.empiria.edu.pl">www.empiria.edu.pl</a></p>\n' +
    '<br>' +
    '<p><span style="font-size:8px">Administratorem Państwa danych osobowych jest Salon Edukacyjny EMPIRIA Sp. z o.o. ul. W. Broniewskiego 12 w Szczecinie.</span></p>\n' +
    '<p><span style="font-size:8px">Więcej informacji w przedmiocie przetwarzania oraz ochrony danych osobowych zamieszczamy pod adresem&nbsp;<a href="https://www.empiria.edu.pl">www.empiria.edu.pl</a></span></p>';
}
