import {Injectable} from '@angular/core';
import {BasicServiceInterface, FilteredListInterface} from "../../interfaces/global.interface";
import {CustomerInterface} from "../../interfaces/customer.interface";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {Params} from '@angular/router';
import {FormGroup} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class CustomersService implements BasicServiceInterface<CustomerInterface> {

    constructor(private http: HttpClient) {
    }

    public downloadList(): Observable<CustomerInterface[]> {
        return this.http.get<CustomerInterface[]>(environment.apiPath + 'customers');
    }

    public downloadFilteredList(params: Params): Observable<FilteredListInterface<CustomerInterface>> {
        return this.http.get<FilteredListInterface<CustomerInterface>>(environment.apiPath + 'customers', {params: params});
    }

    public downloadPureFilteredList(params: HttpParams): Observable<CustomerInterface[]> {
        return this.http.get<CustomerInterface[]>(environment.apiPath + 'customers', {params});
    }

    public downloadCountOfList(): Observable<CustomerInterface[]> {
        return this.http.get<CustomerInterface[]>(environment.apiPath + 'customers/count');
    }

    public downloadOne(id: string): Observable<CustomerInterface> {
        return this.http.get<CustomerInterface>(environment.apiPath + 'customers/' + id);
    }

    public saveOne(data: CustomerInterface): Observable<CustomerInterface> {
        return this.http.post<CustomerInterface>(environment.apiPath + 'customers', data);
    }

    public updateOne(data: CustomerInterface, dataId: string): Observable<CustomerInterface> {
        return this.http.put<CustomerInterface>(environment.apiPath + 'customers/' + dataId, data);
    }

    public deleteOne(dataId: string): Observable<void> {
        return this.http.delete<void>(environment.apiPath + 'customers/' + dataId);
    }

    public downloadByData(params: HttpParams): Observable<CustomerInterface> {
        return this.http.get<CustomerInterface>(environment.apiPath + 'course-registrations/dictionary/customers/single', {params: params});
    }

    public toDownloadCustomerByData(propertyName: string, value: string, workPlaceFG: FormGroup, buyerFG: FormGroup, recipientFG: FormGroup, participantDataFG?: FormGroup): Promise<CustomerInterface> {
        return new Promise<CustomerInterface>((resolve, reject) => {
            const params: HttpParams = new HttpParams().set('propertyName', propertyName).set('value', value);
            this.downloadByData(params).subscribe((customer: CustomerInterface) => {
                if (!!customer && !!customer.id) {
                    workPlaceFG.patchValue({
                        // hash: customer.placementHash,
                        buildingNumber: customer.addressData?.buildingNumber,
                        city: customer.addressData?.city,
                        commune: customer.addressData?.commune,
                        district: customer.addressData?.district,
                        localNumber: customer.addressData?.localNumber,
                        name: customer.addressData?.name,
                        postalCode: customer.addressData?.postalCode,
                        state: customer.addressData?.state,
                        street: customer.addressData?.street,
                        type: customer.addressData?.type,
                        email: customer.email,
                        phone: customer.phoneNumber
                    });
                    buyerFG.patchValue({
                        buildingNumber: customer.invoiceBuyerData?.buildingNumber,
                        city: customer.invoiceBuyerData?.city,
                        localNumber: customer.invoiceBuyerData?.localNumber,
                        name: customer.invoiceBuyerData?.name,
                        nip: customer.invoiceBuyerData?.nip,
                        postalCode: customer.invoiceBuyerData?.postalCode,
                        street: customer.invoiceBuyerData?.street,
                    });
                    recipientFG.patchValue({
                        buildingNumber: customer.invoiceReceiverData?.buildingNumber,
                        city: customer.invoiceReceiverData?.city,
                        localNumber: customer.invoiceReceiverData?.localNumber,
                        name: customer.invoiceReceiverData?.name,
                        postalCode: customer.invoiceReceiverData?.postalCode,
                        street: customer.invoiceReceiverData?.street,
                    });
                    if (!!participantDataFG) {
                        participantDataFG.patchValue({
                            firstName: customer.contactData.firstName,
                            lastName: customer.contactData.lastName,
                            function: customer.contactData.function,
                            email: customer.contactData.email,
                            phone: customer.contactData.workPhone
                        });
                    }

                    resolve(customer);
                }
                reject(null);
            });
        });
    }
}
