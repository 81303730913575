import {Injectable} from '@angular/core';
import {MenuPositionInterface} from "../interfaces/menu.interface";
import {AppLinksService} from "./app-links.service";
import {Permissions} from '../enums/permissions.enum';
import {UtilsService} from './utils.service';
import {LoggedUserService} from './logged-user.service';

@Injectable({
    providedIn: 'root'
})
export class MenuPositionService {
    public static dashboardId: number = 1;
    public static coursesId: number = 2;
    public static coursesOpenId: number = 21;
    public static coursesRegistrationOpenId: number = 22;
    public static coursesCloseId: number = 23;
    public static coursesRegistrationClosedId: number = 24;
    public static usersId: number = 4;
    public static trainersId: number = 5;
    public static customersId: number = 6;
    public static customersIndividualId: number = 61;
    public static customersEstablishmentId: number = 62;

    public static subscriptionRegistrationList: number = 71;
    public static subscriptionList: number = 72;

    public static dictionaryId: number = 10;
    public static dictionaryAddresseesId: number = 11;
    public static dictionaryAreasId: number = 12;
    public static dictionarySubjectId: number = 13;
    public static dictionaryEstablishmentTypeId: number = 14;
    public static dictionaryFunctionsId: number = 15;
    public static dictionaryCoursesId: number = 16;

    public static mailerList: number = 81;
    public static mailerDetail: number = 82;
    public static mailerExternalCustomerList: number = 83;

    public static statisticsStatisticsId: number = 91;
    public static statisticsReportingId: number = 92;

    public static separatorId: number = 0;

    private static readonly list: MenuPositionInterface[] = [
        {id: MenuPositionService.dashboardId, name: 'Dashboard', translation: 'dashboard', class: 'dashboard', uppercase: true, route: AppLinksService.dashboard, privileges: []},
        {id: MenuPositionService.separatorId, name: '', translation: '', class: '', route: '', privileges: []},

        {id: MenuPositionService.coursesOpenId, name: 'Courses', translation: 'courses', class: 'courses', uppercase: true, route: AppLinksService.coursesList, privileges: [Permissions.READ_ACTIVE_COURSES]},
        {id: MenuPositionService.coursesRegistrationOpenId, name: 'Courses registration open', translation: 'courseRegistrationOpen', class: 'courseRegistrationClosed', uppercase: true, route: AppLinksService.coursesRegistrationOpenList, privileges: [Permissions.READ_REGISTRATIONS]},
        {id: MenuPositionService.coursesRegistrationClosedId, name: 'Courses registration closed', translation: 'courseRegistrationClosed', class: 'courseRegistrationClosed', uppercase: true, route: AppLinksService.coursesRegistrationClosedList, privileges: [Permissions.READ_REGISTRATIONS]},
        {id: MenuPositionService.separatorId, name: '', translation: '', class: '', route: '', privileges: []},

        {id: MenuPositionService.customersIndividualId, name: 'Customers individual', translation: 'customersIndividual', class: 'customers', uppercase: true, route: AppLinksService.customersIndividualList, privileges: [Permissions.READ_CUSTOMERS]},
        {id: MenuPositionService.customersEstablishmentId, name: 'Customers establishment', translation: 'customersEstablishment', class: 'customers', uppercase: true, route: AppLinksService.customersEstablishmentList, privileges: [Permissions.READ_CUSTOMERS]},
        {id: MenuPositionService.trainersId, name: 'Trainers', translation: 'trainers', class: 'trainers', route: AppLinksService.trainersList, privileges: [Permissions.READ_TRAINERS]},
        {id: MenuPositionService.usersId, name: 'Users', translation: 'users', class: 'users', route: AppLinksService.usersList, privileges: [Permissions.READ_USERS]},
        {id: MenuPositionService.separatorId, name: '', translation: '', class: '', route: '', privileges: []},

        {id: MenuPositionService.subscriptionList, name: 'Subscriptions', translation: 'subscriptions', class: 'courses', uppercase: true, route: AppLinksService.subscriptionList, privileges: [Permissions.READ_ACTIVE_COURSES]},
        {id: MenuPositionService.subscriptionRegistrationList, name: 'Subscriptions registrations', translation: 'courseRegistrationSubscription', class: 'courseRegistrationClosed', route: AppLinksService.subscriptionRegistrationList, privileges: [Permissions.READ_REGISTRATIONS]},
        {id: MenuPositionService.separatorId, name: '', translation: '', class: '', route: '', privileges: []},

        {id: MenuPositionService.mailerList, name: 'Mailer list', translation: 'mailerList', class: 'mailerList', route: AppLinksService.mailerList, privileges: []},
        {id: MenuPositionService.mailerDetail, name: 'Mailer detail', translation: 'addNew.mailer', class: 'mailerDetail', route: AppLinksService.mailerDetail, privileges: []},
        {id: MenuPositionService.mailerExternalCustomerList, name: 'Mailer external customer list', translation: 'externalCustomers', class: 'mailerDetail', route: AppLinksService.customerExternalList, privileges: []},
        {id: MenuPositionService.separatorId, name: '', translation: '', class: '', route: '', privileges: []},

        {id: MenuPositionService.statisticsStatisticsId, name: 'Statistics statistics', translation: 'statistics.main', class: 'mailerDetail', route: AppLinksService.statisticsStatistics, privileges: []},
        {id: MenuPositionService.statisticsReportingId, name: 'Statistics reporting', translation: 'statistics.reporting', class: 'mailerDetail', route: AppLinksService.statisticsReporting, privileges: []},
        {id: MenuPositionService.separatorId, name: '', translation: '', class: '', route: '', privileges: []},

        {
            id: MenuPositionService.dictionaryId, name: 'Dictionaries', translation: 'dictionary.plural', class: 'dictionaryAreas', route: '', privileges: [], showSubPositions: false, subPositions: [
                {id: MenuPositionService.dictionaryCoursesId, name: 'Dictionary Courses', translation: 'dictionary.courseTemplates', class: 'dictionaryCourses', route: AppLinksService.dictionaryCourseTemplatesList, privileges: [Permissions.READ_COURSES]},
                {id: MenuPositionService.dictionaryAddresseesId, name: 'Dictionary Addressees', translation: 'dictionary.addressees', class: 'dictionaryAddressees', route: AppLinksService.dictionaryAddresseeList, privileges: [Permissions.READ_ADDRESSEES]},
                {id: MenuPositionService.dictionaryAreasId, name: 'Dictionary Areas', translation: 'dictionary.areas', class: 'dictionaryAreas', route: AppLinksService.dictionaryAreaList, privileges: [Permissions.READ_COURSE_AREAS]},
                {id: MenuPositionService.dictionarySubjectId, name: 'Dictionary Subjects', translation: 'dictionary.subjects', class: 'dictionarySubjects', route: AppLinksService.dictionarySubjectList, privileges: [Permissions.READ_SUBJECTS]},
                {id: MenuPositionService.dictionaryEstablishmentTypeId, name: 'Dictionary Establishment Types', translation: 'dictionary.establishmentTypes', class: 'dictionaryEstablishmentTypes', route: AppLinksService.dictionaryEstablishmentTypeList, privileges: [Permissions.READ_ESTABLISHMENTS]},
                {id: MenuPositionService.dictionaryFunctionsId, name: 'Dictionary Functions', translation: 'dictionary.functions', class: 'dictionaryFunctions', route: AppLinksService.dictionaryFunctionsList, privileges: [Permissions.READ_FUNCTIONS]}
            ]
        }
    ];

    constructor(private loggedUserService: LoggedUserService) {
    }

    public get list(): MenuPositionInterface[] {
        return UtilsService.copyWithoutReference<MenuPositionInterface[]>(MenuPositionService.list);
    }

    public get preparedList(): MenuPositionInterface[] {
        let list: MenuPositionInterface[] = this.list;
        for (let i = list.length - 1; i >= 0; i--) if (!this.loggedUserService.ifUserHasPrivileges(list[i].privileges)) list.splice(i, 1);
        return list;
    }
}
