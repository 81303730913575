import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {AuthLoginInterface, AuthLoginRPInterface, AuthPasswordChangeInterface} from "../interfaces/auth.interface";
import jwt_decode from "jwt-decode";
import {LoggedUserService} from "./logged-user.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public static readonly tokenName: string = 'adminToken';
    public static readonly tokenRefreshName: string = 'adminRefreshToken';

    constructor(private http: HttpClient,
                private loggedUserService: LoggedUserService) {
    }

    public login(data: AuthLoginInterface): Observable<AuthLoginRPInterface> {
        return this.http.post<AuthLoginRPInterface>(environment.apiPath + 'sessions', data);
    }

    public passwordReset(data: AuthLoginInterface): Observable<void> {
        return this.http.post<void>(environment.apiPath + 'users/forgot_password', data);
    }

    public passwordChange(data: AuthPasswordChangeInterface): Observable<void> {
        return this.http.post<void>(environment.apiPath + 'users/reset_password', data);
    }

    public setLoggedUserDataFromToken(): void {
        if (this.ifToken()) {
            try {
                this.loggedUserService.data = jwt_decode(this.getToken());
            }
            catch (e) {
                this.clearToken();
            }
        }
    }

    public setToken(token: string): void {
        localStorage.setItem(AuthService.tokenName, token);
        this.setLoggedUserDataFromToken();
    }

    public getToken(): string {
        return this.ifToken() ? <string>localStorage.getItem(AuthService.tokenName) : '';
    }

    public clearToken(): void {
        localStorage.removeItem(AuthService.tokenName)
    }

    public ifToken(): boolean {
        return AuthService.ifTokenExist(<string>localStorage.getItem(AuthService.tokenName));
    }

    private static ifTokenExist(token: string): boolean {
        return !!token && token !== 'null' && token !== 'undefined';
    }
}
