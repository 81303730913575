import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../services/utils.service';
import {PaginatorService} from './paginator.service';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy {
    @Input() public totalRecords: number = 0;
    @Input() public rows: number = 0;
    @Input() public currentPage: number = 0;
    @Output() public changePageEvent: EventEmitter<number> = new EventEmitter<number>();
    public maxPage: number = 0;
    private subscriptions: Subscription[] = [];

    constructor(private paginatorService: PaginatorService) {
        this.resetEvent();
    }

    private resetEvent(): void {
        this.subscriptions.push(this.paginatorService.resetChanged().subscribe(() => {
            this.currentPage = this.paginatorService.currentPage;
            this.maxPage = this.paginatorService.maxPage;
        }));
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnDestroy() {
        UtilsService.unsubscribeAll(this.subscriptions);
    }

    private init(): void {
        this.countMaxPage();
        this.assignValueToService();
    }

    private countMaxPage(): void {
        this.maxPage = Math.ceil(this.totalRecords / this.rows);
    }

    private assignValueToService(): void {
        this.paginatorService.currentPage = this.currentPage;
        this.paginatorService.maxPage = this.maxPage;
    }

    public pagesAmount() {
        this.countMaxPage();
        let pages: number[] = [];

        if (this.maxPage <= 5) pages.push(...Array(this.maxPage).keys());
        else {
            let min: number = 0 - this.currentPage;
            let max: number = (this.maxPage - 1) - this.currentPage;

            if (min < -1) for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) pages.push(i);
            else if (min >= -1) pages.push(...Array(5).keys());

            if (max <= 2) {
                pages = [];
                let maxRange: number = 4 - max;
                for (let i = this.currentPage - maxRange; i <= this.maxPage - 1; i++) pages.push(i);
            }
        }

        return pages;
    };

    public changePage(page: number): void {
        if (page !== this.currentPage) {
            this.currentPage = +page;
            this.assignValueToService();
            this.changePageEvent.emit(page);
        }
    }
}
