<ng-container *ngIf="ifLoaded; else loading;">
    <ng-content></ng-content>
</ng-container>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <app-loader></app-loader>
        </div>
    </div>
</ng-template>
