import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {AnyInterface} from "../interfaces/global.interface";

const postalCodeRegexp: RegExp = /^[_0-9]{2}-[_0-9]{3}$/;

export function PostalCodeValidate(postalCodeProp: string) {
    return (group: FormGroup): AnyInterface<boolean> | undefined => {
        if (!postalCodeValidateLogic(group.controls[postalCodeProp].value)) {
            return {
                postalCodeNotValidate: true
            };
        }
    };
}

export function PostalCodeControlValidate(postalCode: AbstractControl) {
    if (!!postalCode.value && !postalCodeValidateLogic(postalCode.value)) return {notValidate: true};
    return null;
}

function postalCodeValidateLogic(postalCode: string): boolean {
    return postalCodeRegexp.test(String(postalCode).toLowerCase());
}
