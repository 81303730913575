export enum Permissions {
    WRITE_LOCALIZATIONS = 'write_localizations',
    READ_LOCALIZATIONS = 'read_localizations',
    WRITE_ADDRESSEES = 'write_addressees',
    READ_ADDRESSEES = 'read_addressees',
    WRITE_COURSES = 'write_courses',
    READ_COURSES = 'read_courses',
    WRITE_USERS = 'write_users',
    READ_USERS = 'read_users',
    WRITE_COURSE_AREAS = 'write_course_areas',
    READ_COURSE_AREAS = 'read_course_areas',
    WRITE_ACTIVE_COURSES = 'write_active_courses',
    READ_ACTIVE_COURSES = 'read_active_courses',
    WRITE_ESTABLISHMENTS = 'write_establishments',
    READ_ESTABLISHMENTS = 'read_establishments',
    WRITE_FUNCTIONS = 'write_customer_functions',
    READ_FUNCTIONS = 'read_customer_functions',
    WRITE_TRAINERS = 'write_trainers',
    READ_TRAINERS = 'read_trainers',
    WRITE_CUSTOMERS = 'write_customers',
    READ_CUSTOMERS = 'read_customers',
    WRITE_SUBJECTS = 'write_subjects',
    READ_SUBJECTS = 'read_subjects',
    WRITE_REGISTRATIONS = 'write_registrations',
    READ_REGISTRATIONS = 'read_registrations',

    SUPER_USER = '*'
}

export const AllPermissions = [
    Permissions.WRITE_LOCALIZATIONS,
    Permissions.READ_LOCALIZATIONS,
    Permissions.WRITE_ADDRESSEES,
    Permissions.READ_ADDRESSEES,
    Permissions.WRITE_COURSES,
    Permissions.READ_COURSES,
    Permissions.WRITE_USERS,
    Permissions.READ_USERS,
    Permissions.WRITE_COURSE_AREAS,
    Permissions.READ_COURSE_AREAS,
    Permissions.WRITE_ACTIVE_COURSES,
    Permissions.READ_ACTIVE_COURSES,
    Permissions.WRITE_ESTABLISHMENTS,
    Permissions.READ_ESTABLISHMENTS,
    Permissions.WRITE_TRAINERS,
    Permissions.READ_TRAINERS,
    Permissions.WRITE_CUSTOMERS,
    Permissions.READ_CUSTOMERS,
    Permissions.WRITE_SUBJECTS,
    Permissions.READ_SUBJECTS,
    Permissions.WRITE_REGISTRATIONS,
    Permissions.READ_REGISTRATIONS,
    Permissions.WRITE_FUNCTIONS,
    Permissions.READ_FUNCTIONS
];

export const SuperUserPrivilege = Permissions.SUPER_USER;
