import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {LogoutService} from "../services/logout.service";
import {LoggedUserService} from '../services/logged-user.service';
import {ToastService} from '../services/toast.service';
import {AppLinksService} from '../services/app-links.service';

@Injectable({
    providedIn: 'root'
})
export class NeedAuthGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService,
                private logoutService: LogoutService,
                private loggedUserService: LoggedUserService,
                private toastService: ToastService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.canActiveLogic(route)) return true;
        else return this.cannotActiveLogic();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.canActiveLogic(childRoute)) return true;
        else return this.cannotActiveLogic();
    }

    private canActiveLogic(route: ActivatedRouteSnapshot): boolean {
        if (!this.authService.ifToken()) return false;
        if (!!route && !!route.data && route.data['needPrivileges']) {
            if (!this.loggedUserService.ifUserHasPrivileges(route.data['needPrivileges'])) {
                this.toastService.translateText('info', 'info.noPrivileges');
                this.router.navigate([AppLinksService.dashboard]);
            }
            return true;
        }
        return true;
    }

    private cannotActiveLogic(): boolean {
        this.logoutService.submit();
        return false;
    }
}
