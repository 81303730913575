import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MenuTopViewActionService {
    private _show: boolean = false;
    private show$: Subject<void> = new Subject<void>();
    private _text: string = '';
    private text$: Subject<void> = new Subject<void>();
    private back$: Subject<void> = new Subject<void>();

    constructor() {
    }

    public set show(_show: boolean) {
        this._show = _show;
        this.show$.next();
    }

    public get show(): boolean {
        return this._show;
    }

    public showChanged(): Observable<void> {
        return this.show$.asObservable();
    }

    public set text(_text: string) {
        this._text = _text;
        this.text$.next();
    }

    public get text(): string {
        return this._text;
    }

    public textChanged(): Observable<void> {
        return this.text$.asObservable();
    }

    public back(): void {
        this.back$.next();
    }

    public backClicked(): Observable<void> {
        return this.back$.asObservable();
    }
}
