import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-filter-chosen',
    templateUrl: './filter-chosen.component.html',
    styleUrls: ['./filter-chosen.component.scss']
})
export class FilterChosenComponent {
    @Input() public label: string = '';
    @Input() public value: string | null = '';
    @Output() public remove: EventEmitter<void> = new EventEmitter<void>();
}
