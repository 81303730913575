import {ExcelService} from '../services/excel.service';
import {ApplicationFormOpenCourseRegistrationInterface, CourseDetailEditSourceRegistrationOpenCourseData} from '../interfaces/application-form.interface';
import {Workbook, Worksheet} from 'exceljs';
import {TableXlsxStatics} from './table-xlsx-statics.class';
import {TableXLSXRowData} from '../interfaces/global.interface';
import {DictionaryFunctionsService} from '../pages/dictionary/dictionary-functions/dictionary-functions.service';
import {DictionaryEstablishmentTypeInterface, DictionaryFunctionInterface, DictionarySubjectInterface} from '../interfaces/dictionary.interface';
import {DictionarySubjectsService} from '../pages/dictionary/dictionary-subjects/dictionary-subjects.service';
import {DictionaryEstablishmentTypesService} from '../pages/dictionary/dictionary-establishment-types/dictionary-establishment-types.service';
import {CountryStatesService} from '../services/country-states.service';
import * as moment from 'moment';

export class CourseRegistrationXlsx {
    public dataCourse: TableXLSXRowData[] = [];
    public dataParticipant: TableXLSXRowData[] = [];
    public dataWorkPlace: TableXLSXRowData[] = [];
    public dataInvoiceBuyer: TableXLSXRowData[] = [];
    public dataInvoiceRecipient: TableXLSXRowData[] = [];
    public dataLast: TableXLSXRowData[] = [];

    constructor(private excelService: ExcelService,
                private dictionaryFunctionsService: DictionaryFunctionsService,
                private dictionarySubjectsService: DictionarySubjectsService,
                private dictionaryEstablishmentTypesService: DictionaryEstablishmentTypesService,
                private participantSubHeaderName: string,
                private workPlaceSubHeaderName: string) {
    }

    public call(registration: ApplicationFormOpenCourseRegistrationInterface, courseValue: CourseDetailEditSourceRegistrationOpenCourseData): void {
        this.prepareData(registration, courseValue);
        const workbook: Workbook = new Workbook();
        const worksheet: Worksheet = workbook.addWorksheet('Zgłoszenie klienta');
        TableXlsxStatics.prepareRowsColumns(worksheet, 5 + this.dataCourse.length + this.dataParticipant.length +  this.dataWorkPlace.length +  this.dataInvoiceBuyer.length +  this.dataInvoiceRecipient.length + this.dataLast.length);
        TableXlsxStatics.prepareHeader(worksheet, 'ZGŁOSZENIE KLIENTA');
        this.prepareCoursePart(worksheet);
        this.prepareParticipantPart(worksheet);
        this.prepareWorkPlacePart(worksheet);
        this.prepareInvoiceBuyerPart(worksheet);
        this.prepareInvoiceRecipientPart(worksheet);
        this.prepareDataLastPart(worksheet);

        this.excelService.saveWorkbook(workbook, 'Zgłoszenie klienta');
    }

    private prepareData(registration: ApplicationFormOpenCourseRegistrationInterface, courseValue: CourseDetailEditSourceRegistrationOpenCourseData): void {
        this.prepareDataCourse(courseValue);
        this.prepareDataParticipant(registration);
        this.prepareDataWorkPlace(registration);
        this.prepareDataInvoiceBuyer(registration);
        this.prepareDataInvoiceRecipient(registration);
        this.prepareDataLast(registration, courseValue);
    }

    private prepareDataLast(registration: ApplicationFormOpenCourseRegistrationInterface, courseValue: CourseDetailEditSourceRegistrationOpenCourseData): void {
        this.dataLast = [
            {label: 'Data zgłoszenia', value: moment(registration.createdAt).format('DD.MM.YYYY HH:mm:ss')},
            {label: 'Cena za 1os.', value: courseValue.price},
            {label: 'Cena za 10os.', value: courseValue.group10Price},
            {label: `Cena za grupę ${courseValue.maxCustomersWithoutPayment}os.`, value: courseValue.groupPrice},
            {label: `Dopłata za 1os.`, value: courseValue.additionalPrice}
        ];
    }

    private prepareDataLastPart(worksheet: Worksheet): void {
        TableXlsxStatics.prepareSubHeader(worksheet, 8 + this.dataCourse.length + this.dataParticipant.length + this.dataWorkPlace.length + this.dataInvoiceBuyer.length + this.dataInvoiceRecipient.length, '');
        TableXlsxStatics.prepareData(worksheet, this.dataLast, 9 + this.dataCourse.length + this.dataParticipant.length + this.dataWorkPlace.length + this.dataInvoiceBuyer.length + this.dataInvoiceRecipient.length);
    }

    private prepareDataInvoiceRecipient(registration: ApplicationFormOpenCourseRegistrationInterface): void {
        this.dataInvoiceRecipient = [
            {label: 'Nazwa', value: registration.invoiceReceiverData.name},
            {label: 'Miejscowość', value: registration.invoiceReceiverData.city},
            {label: 'Kod pocztowy', value: registration.invoiceReceiverData.postalCode!},
            {label: 'Ulica', value: registration.invoiceReceiverData.street},
            {label: 'Nr domu', value: registration.invoiceReceiverData.buildingNumber},
            {label: 'Nr lokalu', value: registration.invoiceReceiverData.localNumber}
        ];
    }

    private prepareInvoiceRecipientPart(worksheet: Worksheet): void {
        TableXlsxStatics.prepareSubHeader(worksheet, 7 + this.dataCourse.length + this.dataParticipant.length + this.dataWorkPlace.length + this.dataInvoiceBuyer.length, 'DANE DO FAKTURY - ODBIORCA');
        TableXlsxStatics.prepareData(worksheet, this.dataInvoiceRecipient, 8 + this.dataCourse.length + this.dataParticipant.length + this.dataWorkPlace.length + this.dataInvoiceBuyer.length);
    }

    private prepareDataInvoiceBuyer(registration: ApplicationFormOpenCourseRegistrationInterface): void {
        this.dataInvoiceBuyer = [
            {label: 'Nip', value: registration.invoiceBuyerData.nip},
            {label: 'Nazwa', value: registration.invoiceBuyerData.name},
            {label: 'Miejscowość', value: registration.invoiceBuyerData.city},
            {label: 'Kod pocztowy', value: registration.invoiceBuyerData.postalCode!},
            {label: 'Ulica', value: registration.invoiceBuyerData.street},
            {label: 'Nr domu', value: registration.invoiceBuyerData.buildingNumber},
            {label: 'Nr lokalu', value: registration.invoiceBuyerData.localNumber}
        ];
    }

    private prepareInvoiceBuyerPart(worksheet: Worksheet): void {
        TableXlsxStatics.prepareSubHeader(worksheet, 6 + this.dataCourse.length + this.dataParticipant.length + this.dataWorkPlace.length, 'DANE DO FAKTURY - NABYWCA');
        TableXlsxStatics.prepareData(worksheet, this.dataInvoiceBuyer, 7 + this.dataCourse.length + this.dataParticipant.length + this.dataWorkPlace.length);
    }

    private prepareDataWorkPlace(registration: ApplicationFormOpenCourseRegistrationInterface): void {
        this.dataWorkPlace = [
            {label: 'Nazwa placówki', value: registration.workPlace.name},
            {label: 'Typ placówki', value: this.dictionaryEstablishmentTypesService.data.find((type: DictionaryEstablishmentTypeInterface) => type.id === registration.workPlace.type)?.name!},
            {label: 'Województwo', value: CountryStatesService.getStateById(registration.workPlace.state)?.name},
            {label: 'Powiat', value: registration.workPlace.district},
            {label: 'Gmina', value: registration.workPlace.commune},
            {label: 'Miejscowość', value: registration.workPlace.city},
            {label: 'Kod pocztowy', value: registration.workPlace.postalCode!},
            {label: 'Ulica', value: registration.workPlace.street},
            {label: 'Nr domu', value: registration.workPlace.buildingNumber},
            {label: 'Nr lokalu', value: registration.workPlace.localNumber}
        ];
    }

    private prepareWorkPlacePart(worksheet: Worksheet): void {
        TableXlsxStatics.prepareSubHeader(worksheet, 5 + this.dataCourse.length + this.dataParticipant.length, this.workPlaceSubHeaderName);
        TableXlsxStatics.prepareData(worksheet, this.dataWorkPlace, 6 + this.dataCourse.length + this.dataParticipant.length);
    }

    private prepareDataParticipant(registration: ApplicationFormOpenCourseRegistrationInterface): void {
        this.dataParticipant = [
            {label: 'Imię', value: registration.participantData.firstName},
            {label: 'Nazwisko', value: registration.participantData.lastName},
            {label: 'Funkcja', value: this.dictionaryFunctionsService.data.find((func: DictionaryFunctionInterface) => func.id === registration.participantData.function)?.name!},
            {label: 'Nauczany przedmiot', value: this.dictionarySubjectsService.data.find((subject: DictionarySubjectInterface) => subject.id === registration.participantData.topic)?.name!},
            {label: 'E-mail', value: registration.participantData.email},
            {label: 'Telefon', value: registration.participantData.phone!}
        ];
    }

    private prepareParticipantPart(worksheet: Worksheet): void {
        TableXlsxStatics.prepareSubHeader(worksheet, 4 + this.dataCourse.length, this.participantSubHeaderName);
        TableXlsxStatics.prepareData(worksheet, this.dataParticipant, 5 + this.dataCourse.length);
    }

    private prepareDataCourse(courseValue: CourseDetailEditSourceRegistrationOpenCourseData): void {
        this.dataCourse = [
            {label: 'Nazwa', value: courseValue.name},
            {label: 'Data trwania', value: courseValue.dates},
            {label: 'Typ', value: courseValue.type},
            {label: 'Obszar', value: courseValue.area},
            {label: 'Forma', value: courseValue.form}
        ];
    }

    private prepareCoursePart(worksheet: Worksheet): void {
        TableXlsxStatics.prepareSubHeader(worksheet, 3, 'DANE SZKOLENIA');
        TableXlsxStatics.prepareData(worksheet, this.dataCourse, 4);
    }
}
