import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {filter} from 'rxjs/operators';

const ESCAPE_KEYCODE = 27;

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
    @Input() public title!: string;
    @Input() public showModalHeader: boolean = true;
    @Input() public showCloseButton: boolean = true;
    @Input() public widthOfModal!: string;
    @Output() public closeModal: EventEmitter<void> = new EventEmitter<void>();

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === ESCAPE_KEYCODE) this.hide();
    }

    public style: any;
    private background?: HTMLElement;
    private routerSubscription: Subscription = Subscription.EMPTY;

    constructor(@Inject(DOCUMENT) private document: Document,
                private router: Router) {
        this.detectRouteChange();
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    private detectRouteChange(): void {
        this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
            this.hideBackground();
        });
    }

    public show() {
        this.document.body.classList.add('modal-open');
        this.style = {'display': 'block'};
        this.showBackground();
    }

    public hide(emit: boolean = false) {
        this.document.body.classList.remove('modal-open');
        this.style = {'display': 'none'};
        this.hideBackground();
        if (emit) this.closeModal.emit();
    }

    public showBackground() {
        this.background = this.document.createElement('div');
        this.background.classList.add('modalBG');
        this.document.body.appendChild(this.background);
    }

    public hideBackground() {
        if (this.background) {
            this.background.remove();
            this.background = undefined;
        }
        document.querySelectorAll('.modalBG').forEach(e => e.remove());
    }
}
