import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CsvService {

    constructor() {
    }

    public downloadFile<T>(data: T[], filename: string, headerList: string[]) {
        let csvData = this.convertToCSV(data, headerList);
        let blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
        let dwldLink = document.createElement('a');
        let url = URL.createObjectURL(blob);
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) dwldLink.setAttribute('target', '_blank');
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename + '.xls');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    public convertToCSV<T>(array: T[], headerList: string[]): string {
        array = typeof array != 'object' ? JSON.parse(array) : array;
        let str: string = '';
        let row: string = 'S.No,' + headerList.join(',');
        str += row + '\r\n';

        return CsvService.convertToCSVPushData<T>(str, array, headerList);
    }

    private static convertToCSVPushData<T>(str: string, array: T[], headerList: string[]): string {
        for (let i = 0; i < array.length; i++) {
            let data: string[] = [String(i + 1)];
            for (let index in headerList) data.push(array[i][headerList[index]]);
            str += data.join(',') + '\r\n';
        }

        return str;
    }
}
