import {Injectable} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ApplicationFormCloseInterface} from "../../../interfaces/application-form.interface";

@Injectable({
    providedIn: 'root'
})
export class ApplicationCourseFormCloseService {
    private _form!: FormGroup;
    private form$: Subject<void> = new Subject<void>();

    constructor(private http: HttpClient) {
    }

    public submit(data: ApplicationFormCloseInterface): Observable<any> {
        return this.http.post(environment.apiPath + 'course-registrations/closed', data);
    }

    public set form(_form: FormGroup) {
        this._form = _form;
        this.form$.next();
    }

    public get form(): FormGroup {
        return this._form;
    }

    public formChanged(): Observable<void> {
        return this.form$.asObservable();
    }
}
