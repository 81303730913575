import {FormGroup} from '@angular/forms';
import {DictionaryAreaInterface, DictionaryCourseTemplateInterface} from '../interfaces/dictionary.interface';
import {TrainerInterface} from '../interfaces/trainer.interface';
import {CountryStateInterface} from '../interfaces/global.interface';
import * as _ from 'underscore';

export abstract class FilterBoxHandler {
  public filterForm!: FormGroup;
  public filterBoxFlag: boolean = false;

  public createFilterForm?(): void;

  protected constructor() {
  }

  public handleFilterBox(): void {
    this.filterBoxFlag = !this.filterBoxFlag;
  }

  public changePage(page: number): void {
    this.filterForm.patchValue({
      page: page
    });
  }

  public getFilterBoolShow(prop: string): boolean {
    return this.filterForm.get(prop)?.value !== null;
  }

  public getFilterName(prop: string): string {
    return this.filterForm.get(prop)?.value;
  }

  public getFilterBoolName(prop: string): string | null {
    const value: boolean = this.filterForm.get(prop)?.value;
    return _.isBoolean(value) ? (value ? 'Tak' : 'Nie') : null;
  }

  public getFilterDictName(dict: (DictionaryCourseTemplateInterface | DictionaryAreaInterface | TrainerInterface | CountryStateInterface)[], prop: string, propToFind: string = 'id'): string {
    return dict?.find((row: any) => row[propToFind] === this.filterForm.get(prop)?.value)?.name!;
  }

  public getFilterDictNames(dict: (DictionaryCourseTemplateInterface | DictionaryAreaInterface | TrainerInterface | CountryStateInterface)[], prop: string, propToFind: string = 'id'): string {
    return dict?.filter((row: any) => !!this.filterForm.get(prop)?.value?.find(id => id === row[propToFind]))?.map(row => row.name).join(', ');
  }

  public getFilterEnumName(enums: any, prop: string): string {
    return prop === 'status' ? enums[this.filterForm.get(prop)?.value?.toUpperCase()] : enums[this.filterForm.get(prop)?.value];
  }

  public resetOneValue(prop: string): void {
    this.filterForm.get(prop)?.setValue(null);
  }
}
