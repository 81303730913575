import {ListHandler} from './list-handler.class';
import {HandlerInterfaceType, HandlerServiceType} from '../types/dictionary.type';
import {OrderType} from '../types/global.type';
import {SortBy} from './sort-by.class';

export abstract class HandleLastFilters<S extends HandlerServiceType, I extends HandlerInterfaceType, F> extends ListHandler<S, I> {
  public sortBy: SortBy;

  protected constructor(public service: S,
                        public filterLS: string,
                        public sortLS: string,
                        public sortColumn: string,
                        public linkToDetail?: string) {
    super(service, linkToDetail);
    this.sortBy = new SortBy(this.getSortOrder(), this.getSortColumn());
  }

  private get sortOrderLS(): string {
    return `${this.sortLS}Order`;
  }

  private get sortColumnLS(): string {
    return `${this.sortLS}Column`;
  }

  public getSortOrder(): OrderType {
    return !!localStorage.getItem(this.sortOrderLS) ? <OrderType>localStorage.getItem(this.sortOrderLS) : 'DESC';
  }

  public getSortColumn(): string {
    return !!localStorage.getItem(this.sortColumnLS) ? <OrderType>localStorage.getItem(this.sortColumnLS) : this.sortColumn;
  }

  public getFilter(): F {
    return !!localStorage.getItem(this.filterLS) ? JSON.parse(localStorage.getItem(this.filterLS)!) : <S>{}
  }

  public setFilters(): void {
    localStorage.setItem(this.filterLS, JSON.stringify(this.filterForm.value));
    localStorage.setItem(this.sortOrderLS, this.sortBy.order);
    localStorage.setItem(this.sortColumnLS, this.sortBy.column);
  }

  public resetPagination(): void {
    this.filterForm.get('page')?.setValue(0);
  }
}
