<div class="row">
    <div class="col-12">
        <ng-container *ngIf="downloadedList; else loadingList;">
            <ng-container *ngIf="!!listCounter && listCounter > 0; else noData">
                <div class="overflow-auto" [style]="{'max-height': maxContentHeight}">
                    <ng-content></ng-content>
                </div>
            </ng-container>

            <ng-template #noData>
                <h2>{{'noData' | translate}}...</h2>
            </ng-template>
        </ng-container>

        <ng-template #loadingList>
            <app-loader></app-loader>
        </ng-template>
    </div>
</div>
