<form [formGroup]="form" *ngIf="!!form">
    <div class="row">
        <div class="col-12">
            <app-box-with-header [header]="'general' | translate">
                <div class="row">
                    <div class="col-12 formGroup">
                        <label>{{'name' | translate}}</label>
                        <input formControlName="name"/>
                    </div>
                </div>
            </app-box-with-header>
        </div>

        <div class="col-12 mt-3">
            <app-box-with-header [header]="'address.address' | translate">
                <div class="row">
                    <div class="col-12 formGroup">
                        <label>{{'address.province' | translate}}</label>
                        <select formControlName="state">
                            <ng-container *ngFor="let state of states">
                                <option [value]="state.id">{{state.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-8 formGroup">
                        <label>{{'address.city' | translate}}</label>
                        <input formControlName="city"/>
                    </div>

                    <div class="col-12 col-md-4 formGroup">
                        <label>{{'address.postalCode' | translate}}</label>
                        <input formControlName="postalCode" appPostalCodeMask/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-6 formGroup">
                        <label>{{'address.street' | translate}}</label>
                        <input formControlName="street"/>
                    </div>

                    <div class="col-6 col-md-3 formGroup">
                        <label>{{'address.houseNo' | translate}}</label>
                        <input formControlName="buildingNumber"/>
                    </div>

                    <div class="col-6 col-md-3 formGroup">
                        <label>{{'address.flatNo' | translate}}</label>
                        <input formControlName="localNumber"/>
                    </div>
                </div>
            </app-box-with-header>
        </div>
    </div>
</form>
