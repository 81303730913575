import {Observable, Subject} from 'rxjs';
import {EveryModelInterface} from '../interfaces/global.interface';
import {UtilsService} from '../services/utils.service';
import * as _ from 'underscore';
import {DictionaryCourseTemplateInterface} from '../interfaces/dictionary.interface';
import {CourseTypesEnum} from '../enums/course-types.enum';

export class ServiceDataHandler<T extends EveryModelInterface> {
    private _data!: T[];
    private data$: Subject<void> = new Subject<void>();

    constructor() {
    }

    public set data(_data: T[]) {
        this._data = _data;
        this.data$.next();
    }

    public get data(): T[] {
        if (!!this._data && !!this._data[0] && this._data[0].hasOwnProperty('name')) return _.sortBy(UtilsService.copyWithoutReference<T[]>(this._data), (data: T) => data['name'].toLowerCase());
        return this._data;
    }

    public dataChanged(): Observable<void> {
        return this.data$.asObservable();
    }

    public addedNewObj(obj: T): void {
        const objIdx: number = this._data.findIndex((item: T) => obj.id === item.id);
        if (UtilsService.ifIdx(objIdx)) this._data[objIdx] = obj;
        else this._data.push(obj);
    }

    public getRecordById(id: string): T {
        return this.data.find((record: T) => record.id === id)!;
    }
}
