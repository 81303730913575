import {Directive, ElementRef, Renderer2} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
    selector: '[appPostalCodeMask]',
    host: {
        '(keyup)': 'onInputChange($event)',
    }
})
export class PostalCodeMaskDirective {

    constructor(private el: ElementRef,
                private control: NgControl,
                private renderer2: Renderer2) {
        this.renderer2.setAttribute(this.el.nativeElement, 'maxlength', '6');
    }

    public onInputChange(event): void {
        let input: string = this.el.nativeElement.value;
        if (event.code !== 'Backspace') {
            if (input.length < 3) this.control.control!.setValue(input.replace(/^(\d{2}).*/, '$1-'));
            else this.control.control!.setValue(input.replace(/^(\d{2})(\d{3})(\d)(\d).*/, '$1-$2'));
            if (input.length >= 5 && !input.includes('-')) this.control.control!.setValue(input.slice(0, 2) + '-' + input.slice(2, 5));

        }
        else this.control.control!.setValue(input);
    }
}
